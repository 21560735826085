export const ORDER_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const SELLER_ORDER_STATUS = {
  CANCELED: 'CANCELED',
  IN_REVIEW: 'IN_REVIEW',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  PENDING_PAYOUT: 'PENDING_PAYOUT',
  PAID_OUT: 'PAID_OUT',
};

export const ITEM_ORDER_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  CANCELED: 'CANCELED',
  TO_PRODUCE: 'TO_PRODUCE',
  TO_SHIP: 'TO_SHIP',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  TO_REIMBURSE: 'TO_REIMBURSE',
  REIMBURSED: 'REIMBURSED',
};

const ITEM_ORDER_STATUS_STEPS = {
  IN_REVIEW: 'IN_REVIEW',
  TO_SHIP: 'TO_SHIP',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
};

const ITEM_ORDER_STATUS_REVIEW_STEPS = {
  TO_PRODUCE: 'TO_PRODUCE',
};

const ITEM_ORDER_STATUS_FINAL_STEPS = {
  TO_REIMBURSE: 'TO_REIMBURSE',
  REIMBURSED: 'REIMBURSED',
  CANCELED: 'CANCELED',
};

export const getOrderItemStepFromStatus = (status: string) => {
  const statuses = Object.values(ITEM_ORDER_STATUS_STEPS);

  if (Object.values(ITEM_ORDER_STATUS_REVIEW_STEPS).includes(status)) {
    status = ITEM_ORDER_STATUS_STEPS.IN_REVIEW;
  }

  if (Object.values(ITEM_ORDER_STATUS_FINAL_STEPS).includes(status)) {
    return -1;
  }

  const index = statuses.findIndex((s) => s === status);

  if (index >= 0) {
    return index;
  }

  return 0;
};
