import { useEffect, useMemo, useReducer, useState } from 'react';

import { getItemPhotos } from 'api/items.api';
import { ItemPhoto } from 'api/items.types';
import { useTranslation } from 'i18n/hooks';
import { Label, SpacedContainer, Spinner, Switch } from 'revibe-ui';

import { SparklesIcon } from '@heroicons/react/24/outline';

import { useBoolean } from 'shared/hooks';

import { ItemFormField } from '../ItemForm/ItemFormField';
import { ItemPhotosDeleter } from './ItemPhotosDeleter';
import { ItemPhotosOrder } from './ItemPhotosOrder';
import { ItemPhotosUploader } from './ItemPhotosUploader';
import { ItemPhotosViewer } from './ItemPhotosViewer';

type Props = {
  itemID: string;
  existingPhotos: ItemPhoto[];
  validationErrors: string[];
  onUpdate: () => void;
};

type PhotoManagerAction = {
  type: 'stale' | 'ordering' | 'deleting' | 'uploading';
};

type PhotoManagerState = {
  isOrdering: boolean;
  isDeleting: boolean;
  isUploading: boolean;
};

const initialState: PhotoManagerState = {
  isDeleting: false,
  isOrdering: false,
  isUploading: false,
};

function reducer(_: PhotoManagerState, action: PhotoManagerAction) {
  switch (action.type) {
    case 'stale':
      return {
        isDeleting: false,
        isOrdering: false,
        isUploading: false,
      };
    case 'deleting':
      return {
        isDeleting: true,
        isOrdering: false,
        isUploading: false,
      };
    case 'ordering':
      return {
        isDeleting: false,
        isOrdering: true,
        isUploading: false,
      };
    case 'uploading':
      return {
        isDeleting: false,
        isOrdering: false,
        isUploading: true,
      };
    default:
      throw new Error();
  }
}

export const ItemPhotosManager = ({
  itemID,
  existingPhotos,
  validationErrors,
}: Props) => {
  const [{ isDeleting, isOrdering, isUploading }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const { tu } = useTranslation('photos');
  const hasRetouchedPhotos = useMemo(
    () => existingPhotos.findIndex((photo) => photo.is_retouched) !== -1,
    [existingPhotos]
  );
  const [photos, setPhotos] = useState<ItemPhoto[]>([]);
  const [isFetchingPhotos, startFetchingPhotos, stopFetchingPhotos] =
    useBoolean();
  const fetchPhotos = async () => {
    startFetchingPhotos();
    const { data } = await getItemPhotos(itemID);
    if (data) {
      setPhotos(data);
    }
    stopFetchingPhotos();
  };

  useEffect(() => {
    fetchPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpacedContainer>
      {isFetchingPhotos ? (
        <Spinner />
      ) : (
        <ItemFormField label={tu('items.section-heading')}>
          <SpacedContainer>
            <div>
              {!isDeleting && !isOrdering && (
                <ItemPhotosViewer photos={photos} />
              )}
              {isOrdering && (
                <ItemPhotosOrder
                  onUpdate={fetchPhotos}
                  itemID={itemID}
                  photos={photos.filter((photo) => !photo.is_retouched)}
                  lockedPhotos={photos.filter((photo) => photo.is_retouched)}
                />
              )}
              {isDeleting && (
                <ItemPhotosDeleter
                  onUpdate={fetchPhotos}
                  itemID={itemID}
                  photos={photos}
                />
              )}
              {hasRetouchedPhotos && (
                <span className="text-purple inline-flex w-fit items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-sm font-medium">
                  <SparklesIcon className="text-purple -ml-0.5 mr-1.5 h-4 w-4" />
                  {tu('retouched-by-revibe')}{' '}
                </span>
              )}
            </div>
            {photos.length < 5 && (
              <ItemPhotosUploader
                isDisabled={!isUploading}
                existingPhotos={photos}
                onUpdate={fetchPhotos}
                validationErrors={validationErrors}
                itemID={itemID}
              />
            )}
            <div className="flex items-center space-x-2">
              <Switch
                id="order-photos"
                checked={isOrdering}
                onCheckedChange={() =>
                  isOrdering
                    ? dispatch({ type: 'stale' })
                    : dispatch({ type: 'ordering' })
                }
              />
              <Label
                htmlFor="order-photos"
                className="text-sm font-medium text-gray-900"
              >
                {tu('order-photos')}
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="upload-more-photos"
                checked={isUploading}
                onCheckedChange={() =>
                  isUploading
                    ? dispatch({ type: 'stale' })
                    : dispatch({ type: 'uploading' })
                }
              />
              <Label
                htmlFor="upload-more-photos"
                className="text-sm font-medium text-gray-900"
              >
                {tu('upload-more-photos')}
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <Switch
                id="delete-photos"
                checked={isDeleting}
                onCheckedChange={() =>
                  isDeleting
                    ? dispatch({ type: 'stale' })
                    : dispatch({ type: 'deleting' })
                }
              />
              <Label
                htmlFor="delete-photos"
                className="text-sm font-medium text-gray-900"
              >
                {tu('delete-photos')}
              </Label>
            </div>
          </SpacedContainer>
        </ItemFormField>
      )}
    </SpacedContainer>
  );
};
