import { resetPassword } from 'api/auth.api';
import { PasswordRequirements } from 'auth/components/PasswordRequirements';
import { useTranslation } from 'i18n/hooks';
import { useForm } from 'react-hook-form';
import { Button, FormControl, Input, SpacedContainer } from 'revibe-ui';
import { AUTH_ROUTES } from 'routes';

import {
  useBoolean,
  useHistory,
  useToast,
  useQueryStringParameters,
} from 'shared/hooks';

import { Page } from 'ui/components/Layout/Page';

import { validatePassword } from '../utils';

export const PasswordResetPage = () => {
  const { tu } = useTranslation('auth.password-reset');
  const { errorToast } = useToast();
  const [isSuccess, setIsSuccess] = useBoolean();
  const query = useQueryStringParameters();
  const hash = query.get('reset-id') || '';
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{
    password: string;
    password_confirmation: string;
  }>();
  const handlePasswordReset = handleSubmit(
    async ({ password, password_confirmation }) => {
      const passwordErrors = validatePassword(password, password_confirmation);
      if (passwordErrors.length > 0) {
        passwordErrors.forEach((error) => errorToast(error));
        return;
      }
      const { error } = await resetPassword(password, hash);
      if (error) {
        errorToast();
      } else {
        setIsSuccess();
      }
    }
  );

  return (
    <Page title="Password reset">
      <div className="flex h-screen min-h-full items-center justify-center">
        <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <div className="w-full">
              <img
                className="mx-auto h-12 w-auto"
                src="https://res.cloudinary.com/revibe/image/upload/v1649685438/business%20app/logo_zsvqi5.svg"
                alt="logo revibe"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {isSuccess ? tu('success-heading') : tu('question')}
              </h2>
            </div>
            {isSuccess ? (
              <SpacedContainer centered fullWidth>
                <p className="mt-2 text-center text-sm text-gray-600">
                  {tu('success-text')}
                </p>
                <Button onClick={() => history.push(AUTH_ROUTES.LOGIN)}>
                  {tu('login')}
                </Button>
              </SpacedContainer>
            ) : (
              <form
                className="mt-8 w-full space-y-6"
                onSubmit={handlePasswordReset}
              >
                <SpacedContainer centered fullWidth>
                  <FormControl
                    className="w-full"
                    label={tu('reset-password-label')}
                    sublabel={<PasswordRequirements />}
                  >
                    <Input
                      type="password"
                      {...register('password', { required: true })}
                    />
                  </FormControl>
                  <FormControl
                    label={tu('reset-password-confirmation-label')}
                    className="w-full"
                  >
                    <Input
                      type="password"
                      {...register('password_confirmation', { required: true })}
                    />
                  </FormControl>
                  <Button
                    className="w-full"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {tu('reset-cta')}
                  </Button>
                </SpacedContainer>
              </form>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
