import { Button, SpacedContainer } from 'revibe-ui';
import { useHistory } from 'shared/hooks';
import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import { SELLER_ROUTES } from 'routes';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

const steps = [
  { id: 'Step 1', name: 'Upload CSV File', href: '#', status: 'complete' },
  // { id: 'Step 2', name: 'Column Mapping', href: '#', status: 'upcoming' },
  { id: 'Step 2', name: 'Import', href: '#', status: 'complete' },
  { id: 'Step 3', name: 'Done!', href: '#', status: 'current' },
];

export const CSVSuccessPage = () => {
  const history = useHistory();

  return (
    <LoggedInPage
      title="CSV Imported"
      breadcrumbs={[
        {
          label: 'CSV Upload',
          to: SELLER_ROUTES.CATALOG_CSV_IMPORT,
        },
        {
          label: 'Process CSV',
        },
        {
          label: 'Success',
        },
      ]}
    >
      <SpacedContainer className="mx-auto w-full max-w-3xl" spacing="large">
        <nav aria-label="Progress">
          <ol
            role="list"
            className="space-y-4 md:flex md:space-x-8 md:space-y-0"
          >
            {steps.map((step) => (
              <li key={step.name} className="md:flex-1">
                {step.status === 'complete' ? (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-purple-600 py-2 pl-4 hover:border-purple-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-purple-600 group-hover:text-purple-800">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : step.status === 'current' ? (
                  <a
                    href={step.href}
                    className="flex flex-col border-l-4 border-purple-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    aria-current="step"
                  >
                    <span className="text-sm font-medium text-purple-600">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
        <div className="boorder-gray-200 overflow-hidden rounded-md border">
          <div className="border-b border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Success
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Your CSV file has been successfully imported.
            </p>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <CheckCircleIcon className="h-5 w-5" />
          </div>
          <div className="flex justify-end border-t border-gray-200  bg-gray-50 px-4 py-5 sm:px-6">
            <Button
              onClick={() => {
                return history.push(
                  `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.PENDING_APPROVAL}`
                );
              }}
            >
              See imported products
            </Button>
          </div>
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
