import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { Link, ProfilePhotoPlaceholder } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { useSeller } from 'shared/hooks';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import {
  AddIcon,
  CollectionsIcon,
  FiltersIcon,
  ItemsIcon,
  OrdersIcon,
} from 'ui/icons';

export const HomePage = () => {
  const { tu } = useTranslation('home');
  const { seller } = useSeller();

  const items = [
    {
      title: tu('actions.catalog-all-heading'),
      description: tu('actions.catalog-all-text'),
      icon: ItemsIcon,
      to: SELLER_ROUTES.ITEMS,
    },
    {
      title: tu('actions.catalog-new-heading'),
      description: tu('actions.catalog-new-text'),
      icon: AddIcon,
      to: SELLER_ROUTES.ITEMS_NEW,
    },
    {
      title: tu('actions.collection-heading'),
      description: tu('actions.collection-text'),
      icon: CollectionsIcon,
      to: SELLER_ROUTES.COLLECTIONS,
    },
    {
      title: tu('actions.orders-heading'),
      description: tu('actions.orders-text'),
      icon: OrdersIcon,
      to: SELLER_ROUTES.ORDERS_CURRENT,
    },
    {
      title: tu('actions.settings-heading'),
      description: tu('actions.settings-text'),
      icon: FiltersIcon,
      to: SELLER_ROUTES.SETTINGS,
    },
  ];

  return (
    <LoggedInPage title="Home">
      <div>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                {seller.logo ? (
                  <img
                    className="mx-auto h-20 w-20 rounded-full shadow-lg"
                    src={seller.logo || ''}
                    alt="seller"
                  />
                ) : (
                  <div className="mx-auto h-20 w-20 rounded-full shadow-lg">
                    <ProfilePhotoPlaceholder size={20} />
                  </div>
                )}
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">
                  {tu('welcome-heading')},
                </p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                  {seller.name}
                </p>
              </div>
            </div>
          </div>
        </div>

        <ul className="mt-6 grid grid-cols-1 gap-6 border-t border-b border-gray-200 py-6 sm:grid-cols-2">
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-1 focus-within:ring-indigo-500 hover:bg-gray-50">
                <div
                  className={cn(
                    'bg-purple-100',
                    'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                  )}
                >
                  <item.icon
                    className="stroke-purple h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">
                    <Link hasDecoration={false} to={item.to}>
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.title}
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </LoggedInPage>
  );
};
