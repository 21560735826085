import { useTranslation } from 'i18n/hooks';
import { InfoBanner, SpacedContainer } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { ModuleHeading } from 'shared/components/ModuleHeading';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

import { ItemForm } from '../components/ItemForm/ItemForm';

export const NewItemPage = () => {
  const { tu } = useTranslation('catalog');

  return (
    <LoggedInPage
      title={tu('page-new-heading')}
      breadcrumbs={[
        {
          label: tu('items'),
          to: SELLER_ROUTES.ITEMS,
        },
        {
          label: tu('page-new-heading'),
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{tu('page-new-heading')}</ModuleHeading>
        <InfoBanner>{tu('i18n-disclaimer')}</InfoBanner>

        <ItemForm />
      </SpacedContainer>
    </LoggedInPage>
  );
};
