import { requestPasswordReset } from 'api/auth.api';
import { useTranslation } from 'i18n/hooks';
import { useForm } from 'react-hook-form';
import { Button, Input, Drawer, SpacedContainer } from 'revibe-ui';

import { CheckIcon } from '@heroicons/react/20/solid';

import { useBoolean, useToast } from 'shared/hooks';

export const PasswordReset = () => {
  const { tu, i18n } = useTranslation('auth.password-reset');
  const [isFormDrawerOpen, openFormDrawer, closeFormDrawer] = useBoolean();
  const [isSuccessDrawerOpen, openSuccessDrawer, closeSuccessDrawer] =
    useBoolean();
  const { errorToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<{ email: string }>();
  const handlePasswordReset = handleSubmit(async ({ email }) => {
    const { error } = await requestPasswordReset(email, i18n.language);
    if (error) {
      errorToast(error);
    } else {
      closeFormDrawer();
      openSuccessDrawer();
    }
  });

  return (
    <div className="PasswordReset">
      <div className="text-sm">
        <p
          onClick={openFormDrawer}
          className="text-purple cursor-pointer text-sm font-medium hover:text-purple-500"
        >
          {tu('question')}
        </p>
      </div>
      <Drawer
        scrollable
        open={isSuccessDrawerOpen}
        onOpenChange={closeSuccessDrawer}
      >
        <SpacedContainer centered className="mx-auto max-w-[512px]">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {tu('email-sent-confirmation-heading')}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {tu('email-sent-confirmation-text')}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <Button className="w-full" onClick={closeSuccessDrawer}>
              {tu('close', 'shared')}
            </Button>
          </div>
        </SpacedContainer>
      </Drawer>
      <Drawer scrollable onOpenChange={closeFormDrawer} open={isFormDrawerOpen}>
        <form className="mx-auto max-w-[512px]" onSubmit={handlePasswordReset}>
          <SpacedContainer>
            <p className="text-lg font-semibold">{tu('question')}</p>
            <p>{tu('email-instructions')}</p>
            <Input
              placeholder="email@example.com"
              type="email"
              {...register('email', { required: true })}
              error={errors.email && 'invalid-email'}
            />
            <Button onClick={handlePasswordReset} isLoading={isSubmitting}>
              {tu('email-cta')}
            </Button>
          </SpacedContainer>
        </form>
      </Drawer>
    </div>
  );
};
