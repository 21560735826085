import { useTranslation as useI18nTranslation } from 'react-i18next';

import { ObjectType } from 'shared/utils/types';

const COUNTRIES: {
  code: string;
  labels: ObjectType<string>;
}[] = [
  { code: 'AF', labels: { en: 'Afghanistan' } },
  { code: 'AX', labels: { en: 'Aland Islands' } },
  { code: 'AL', labels: { en: 'Albania' } },
  { code: 'DZ', labels: { en: 'Algeria' } },
  { code: 'AS', labels: { en: 'American Samoa' } },
  { code: 'AD', labels: { en: 'Andorra' } },
  { code: 'AO', labels: { en: 'Angola' } },
  { code: 'AI', labels: { en: 'Anguilla' } },
  { code: 'AQ', labels: { en: 'Antarctica' } },
  { code: 'AG', labels: { en: 'Antigua And Barbuda' } },
  { code: 'AR', labels: { en: 'Argentina' } },
  { code: 'AM', labels: { en: 'Armenia' } },
  { code: 'AW', labels: { en: 'Aruba' } },
  { code: 'AU', labels: { en: 'Australia' } },
  { code: 'AT', labels: { en: 'Austria' } },
  { code: 'AZ', labels: { en: 'Azerbaijan' } },
  { code: 'BS', labels: { en: 'Bahamas' } },
  { code: 'BH', labels: { en: 'Bahrain' } },
  { code: 'BD', labels: { en: 'Bangladesh' } },
  { code: 'BB', labels: { en: 'Barbados' } },
  { code: 'BY', labels: { en: 'Belarus' } },
  { code: 'BE', labels: { en: 'Belgium' } },
  { code: 'BZ', labels: { en: 'Belize' } },
  { code: 'BJ', labels: { en: 'Benin' } },
  { code: 'BM', labels: { en: 'Bermuda' } },
  { code: 'BT', labels: { en: 'Bhutan' } },
  { code: 'BO', labels: { en: 'Bolivia' } },
  { code: 'BA', labels: { en: 'Bosnia And Herzegovina' } },
  { code: 'BW', labels: { en: 'Botswana' } },
  { code: 'BV', labels: { en: 'Bouvet Island' } },
  { code: 'BR', labels: { en: 'Brazil' } },
  { code: 'IO', labels: { en: 'British Indian Ocean Territory' } },
  { code: 'BN', labels: { en: 'Brunei Darussalam' } },
  { code: 'BG', labels: { en: 'Bulgaria' } },
  { code: 'BF', labels: { en: 'Burkina Faso' } },
  { code: 'BI', labels: { en: 'Burundi' } },
  { code: 'KH', labels: { en: 'Cambodia' } },
  { code: 'CM', labels: { en: 'Cameroon' } },
  { code: 'CA', labels: { en: 'Canada' } },
  { code: 'CV', labels: { en: 'Cape Verde' } },
  { code: 'KY', labels: { en: 'Cayman Islands' } },
  { code: 'CF', labels: { en: 'Central African Republic' } },
  { code: 'TD', labels: { en: 'Chad' } },
  { code: 'CL', labels: { en: 'Chile' } },
  { code: 'CN', labels: { en: 'China' } },
  { code: 'CX', labels: { en: 'Christmas Island' } },
  { code: 'CC', labels: { en: 'Cocos (Keeling) Islands' } },
  { code: 'CO', labels: { en: 'Colombia' } },
  { code: 'KM', labels: { en: 'Comoros' } },
  { code: 'CG', labels: { en: 'Congo' } },
  { code: 'CD', labels: { en: 'Congo, Democratic Republic' } },
  { code: 'CK', labels: { en: 'Cook Islands' } },
  { code: 'CR', labels: { en: 'Costa Rica' } },
  { code: 'CI', labels: { en: "Cote D'Ivoire" } },
  { code: 'HR', labels: { en: 'Croatia' } },
  { code: 'CU', labels: { en: 'Cuba' } },
  { code: 'CY', labels: { en: 'Cyprus' } },
  { code: 'CZ', labels: { en: 'Czech Republic' } },
  { code: 'DK', labels: { en: 'Denmark' } },
  { code: 'DJ', labels: { en: 'Djibouti' } },
  { code: 'DM', labels: { en: 'Dominica' } },
  { code: 'DO', labels: { en: 'Dominican Republic' } },
  { code: 'EC', labels: { en: 'Ecuador' } },
  { code: 'EG', labels: { en: 'Egypt' } },
  { code: 'SV', labels: { en: 'El Salvador' } },
  { code: 'GQ', labels: { en: 'Equatorial Guinea' } },
  { code: 'ER', labels: { en: 'Eritrea' } },
  { code: 'EE', labels: { en: 'Estonia' } },
  { code: 'ET', labels: { en: 'Ethiopia' } },
  { code: 'FK', labels: { en: 'Falkland Islands (Malvinas)' } },
  { code: 'FO', labels: { en: 'Faroe Islands' } },
  { code: 'FJ', labels: { en: 'Fiji' } },
  { code: 'FI', labels: { en: 'Finland' } },
  { code: 'FR', labels: { en: 'France' } },
  { code: 'GF', labels: { en: 'French Guiana' } },
  { code: 'PF', labels: { en: 'French Polynesia' } },
  { code: 'TF', labels: { en: 'French Southern Territories' } },
  { code: 'GA', labels: { en: 'Gabon' } },
  { code: 'GM', labels: { en: 'Gambia' } },
  { code: 'GE', labels: { en: 'Georgia' } },
  { code: 'DE', labels: { en: 'Germany' } },
  { code: 'GH', labels: { en: 'Ghana' } },
  { code: 'GI', labels: { en: 'Gibraltar' } },
  { code: 'GR', labels: { en: 'Greece' } },
  { code: 'GL', labels: { en: 'Greenland' } },
  { code: 'GD', labels: { en: 'Grenada' } },
  { code: 'GP', labels: { en: 'Guadeloupe' } },
  { code: 'GU', labels: { en: 'Guam' } },
  { code: 'GT', labels: { en: 'Guatemala' } },
  { code: 'GG', labels: { en: 'Guernsey' } },
  { code: 'GN', labels: { en: 'Guinea' } },
  { code: 'GW', labels: { en: 'Guinea-Bissau' } },
  { code: 'GY', labels: { en: 'Guyana' } },
  { code: 'HT', labels: { en: 'Haiti' } },
  { code: 'HM', labels: { en: 'Heard Island & Mcdonald Islands' } },
  { code: 'VA', labels: { en: 'Holy See (Vatican City State)' } },
  { code: 'HN', labels: { en: 'Honduras' } },
  { code: 'HK', labels: { en: 'Hong Kong' } },
  { code: 'HU', labels: { en: 'Hungary' } },
  { code: 'IS', labels: { en: 'Iceland' } },
  { code: 'IN', labels: { en: 'India' } },
  { code: 'ID', labels: { en: 'Indonesia' } },
  { code: 'IR', labels: { en: 'Iran, Islamic Republic Of' } },
  { code: 'IQ', labels: { en: 'Iraq' } },
  { code: 'IE', labels: { en: 'Ireland' } },
  { code: 'IM', labels: { en: 'Isle Of Man' } },
  { code: 'IL', labels: { en: 'Israel' } },
  { code: 'IT', labels: { en: 'Italy' } },
  { code: 'JM', labels: { en: 'Jamaica' } },
  { code: 'JP', labels: { en: 'Japan' } },
  { code: 'JE', labels: { en: 'Jersey' } },
  { code: 'JO', labels: { en: 'Jordan' } },
  { code: 'KZ', labels: { en: 'Kazakhstan' } },
  { code: 'KE', labels: { en: 'Kenya' } },
  { code: 'KI', labels: { en: 'Kiribati' } },
  { code: 'KR', labels: { en: 'Korea' } },
  { code: 'KW', labels: { en: 'Kuwait' } },
  { code: 'KG', labels: { en: 'Kyrgyzstan' } },
  { code: 'LA', labels: { en: "Lao People's Democratic Republic" } },
  { code: 'LV', labels: { en: 'Latvia' } },
  { code: 'LB', labels: { en: 'Lebanon' } },
  { code: 'LS', labels: { en: 'Lesotho' } },
  { code: 'LR', labels: { en: 'Liberia' } },
  { code: 'LY', labels: { en: 'Libyan Arab Jamahiriya' } },
  { code: 'LI', labels: { en: 'Liechtenstein' } },
  { code: 'LT', labels: { en: 'Lithuania' } },
  { code: 'LU', labels: { en: 'Luxembourg' } },
  { code: 'MO', labels: { en: 'Macao' } },
  { code: 'MK', labels: { en: 'Macedonia' } },
  { code: 'MG', labels: { en: 'Madagascar' } },
  { code: 'MW', labels: { en: 'Malawi' } },
  { code: 'MY', labels: { en: 'Malaysia' } },
  { code: 'MV', labels: { en: 'Maldives' } },
  { code: 'ML', labels: { en: 'Mali' } },
  { code: 'MT', labels: { en: 'Malta' } },
  { code: 'MH', labels: { en: 'Marshall Islands' } },
  { code: 'MQ', labels: { en: 'Martinique' } },
  { code: 'MR', labels: { en: 'Mauritania' } },
  { code: 'MU', labels: { en: 'Mauritius' } },
  { code: 'YT', labels: { en: 'Mayotte' } },
  { code: 'MX', labels: { en: 'Mexico' } },
  { code: 'FM', labels: { en: 'Micronesia, Federated States Of' } },
  { code: 'MD', labels: { en: 'Moldova' } },
  { code: 'MC', labels: { en: 'Monaco' } },
  { code: 'MN', labels: { en: 'Mongolia' } },
  { code: 'ME', labels: { en: 'Montenegro' } },
  { code: 'MS', labels: { en: 'Montserrat' } },
  { code: 'MA', labels: { en: 'Morocco' } },
  { code: 'MZ', labels: { en: 'Mozambique' } },
  { code: 'MM', labels: { en: 'Myanmar' } },
  { code: 'NA', labels: { en: 'Namibia' } },
  { code: 'NR', labels: { en: 'Nauru' } },
  { code: 'NP', labels: { en: 'Nepal' } },
  { code: 'NL', labels: { en: 'Netherlands' } },
  { code: 'AN', labels: { en: 'Netherlands Antilles' } },
  { code: 'NC', labels: { en: 'New Caledonia' } },
  { code: 'NZ', labels: { en: 'New Zealand' } },
  { code: 'NI', labels: { en: 'Nicaragua' } },
  { code: 'NE', labels: { en: 'Niger' } },
  { code: 'NG', labels: { en: 'Nigeria' } },
  { code: 'NU', labels: { en: 'Niue' } },
  { code: 'NF', labels: { en: 'Norfolk Island' } },
  { code: 'MP', labels: { en: 'Northern Mariana Islands' } },
  { code: 'NO', labels: { en: 'Norway' } },
  { code: 'OM', labels: { en: 'Oman' } },
  { code: 'PK', labels: { en: 'Pakistan' } },
  { code: 'PW', labels: { en: 'Palau' } },
  { code: 'PS', labels: { en: 'Palestinian Territory, Occupied' } },
  { code: 'PA', labels: { en: 'Panama' } },
  { code: 'PG', labels: { en: 'Papua New Guinea' } },
  { code: 'PY', labels: { en: 'Paraguay' } },
  { code: 'PE', labels: { en: 'Peru' } },
  { code: 'PH', labels: { en: 'Philippines' } },
  { code: 'PN', labels: { en: 'Pitcairn' } },
  { code: 'PL', labels: { en: 'Poland' } },
  { code: 'PT', labels: { en: 'Portugal' } },
  { code: 'PR', labels: { en: 'Puerto Rico' } },
  { code: 'QA', labels: { en: 'Qatar' } },
  { code: 'RE', labels: { en: 'Reunion' } },
  { code: 'RO', labels: { en: 'Romania' } },
  { code: 'RU', labels: { en: 'Russian Federation' } },
  { code: 'RW', labels: { en: 'Rwanda' } },
  { code: 'BL', labels: { en: 'Saint Barthelemy' } },
  { code: 'SH', labels: { en: 'Saint Helena' } },
  { code: 'KN', labels: { en: 'Saint Kitts And Nevis' } },
  { code: 'LC', labels: { en: 'Saint Lucia' } },
  { code: 'MF', labels: { en: 'Saint Martin' } },
  { code: 'PM', labels: { en: 'Saint Pierre And Miquelon' } },
  { code: 'VC', labels: { en: 'Saint Vincent And Grenadines' } },
  { code: 'WS', labels: { en: 'Samoa' } },
  { code: 'SM', labels: { en: 'San Marino' } },
  { code: 'ST', labels: { en: 'Sao Tome And Principe' } },
  { code: 'SA', labels: { en: 'Saudi Arabia' } },
  { code: 'SN', labels: { en: 'Senegal' } },
  { code: 'RS', labels: { en: 'Serbia' } },
  { code: 'SC', labels: { en: 'Seychelles' } },
  { code: 'SL', labels: { en: 'Sierra Leone' } },
  { code: 'SG', labels: { en: 'Singapore' } },
  { code: 'SK', labels: { en: 'Slovakia' } },
  { code: 'SI', labels: { en: 'Slovenia' } },
  { code: 'SB', labels: { en: 'Solomon Islands' } },
  { code: 'SO', labels: { en: 'Somalia' } },
  { code: 'ZA', labels: { en: 'South Africa' } },
  { code: 'GS', labels: { en: 'South Georgia And Sandwich Isl.' } },
  { code: 'ES', labels: { en: 'Spain' } },
  { code: 'LK', labels: { en: 'Sri Lanka' } },
  { code: 'SD', labels: { en: 'Sudan' } },
  { code: 'SR', labels: { en: 'Suriname' } },
  { code: 'SJ', labels: { en: 'Svalbard And Jan Mayen' } },
  { code: 'SZ', labels: { en: 'Swaziland' } },
  { code: 'SE', labels: { en: 'Sweden' } },
  { code: 'CH', labels: { en: 'Switzerland' } },
  { code: 'SY', labels: { en: 'Syrian Arab Republic' } },
  { code: 'TW', labels: { en: 'Taiwan' } },
  { code: 'TJ', labels: { en: 'Tajikistan' } },
  { code: 'TZ', labels: { en: 'Tanzania' } },
  { code: 'TH', labels: { en: 'Thailand' } },
  { code: 'TL', labels: { en: 'Timor-Leste' } },
  { code: 'TG', labels: { en: 'Togo' } },
  { code: 'TK', labels: { en: 'Tokelau' } },
  { code: 'TO', labels: { en: 'Tonga' } },
  { code: 'TT', labels: { en: 'Trinidad And Tobago' } },
  { code: 'TN', labels: { en: 'Tunisia' } },
  { code: 'TR', labels: { en: 'Turkey' } },
  { code: 'TM', labels: { en: 'Turkmenistan' } },
  { code: 'TC', labels: { en: 'Turks And Caicos Islands' } },
  { code: 'TV', labels: { en: 'Tuvalu' } },
  { code: 'UG', labels: { en: 'Uganda' } },
  { code: 'UA', labels: { en: 'Ukraine' } },
  { code: 'AE', labels: { en: 'United Arab Emirates' } },
  { code: 'GB', labels: { en: 'United Kingdom' } },
  { code: 'US', labels: { en: 'United States' } },
  { code: 'UM', labels: { en: 'United States Outlying Islands' } },
  { code: 'UY', labels: { en: 'Uruguay' } },
  { code: 'UZ', labels: { en: 'Uzbekistan' } },
  { code: 'VU', labels: { en: 'Vanuatu' } },
  { code: 'VE', labels: { en: 'Venezuela' } },
  { code: 'VN', labels: { en: 'Viet Nam' } },
  { code: 'VG', labels: { en: 'Virgin Islands, British' } },
  { code: 'VI', labels: { en: 'Virgin Islands, U.S.' } },
  { code: 'WF', labels: { en: 'Wallis And Futuna' } },
  { code: 'EH', labels: { en: 'Western Sahara' } },
  { code: 'YE', labels: { en: 'Yemen' } },
  { code: 'ZM', labels: { en: 'Zambia' } },
  { code: 'ZW', labels: { en: 'Zimbabwe' } },
];

export const useCountries = (locale: 'en' | 'fr' | 'it' = 'en') => {
  const { i18n } = useI18nTranslation();

  const getCountryByCode = (code: string) => {
    const labels: ObjectType<string> =
      COUNTRIES.find((country) => country.code === code)?.labels || {};
    return labels[i18n.language] || labels['en'] || 'unknown';
  };

  return {
    getCountryByCode,
    countries: COUNTRIES.map((c) => ({
      code: c.code,
      label: c.labels[locale] || c.labels['en'],
    })),
  };
};
