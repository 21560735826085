import React from 'react';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Button, SpacedContainer } from 'revibe-ui';

import { useHistory } from 'shared/hooks';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

type Props = {
  children: React.ReactNode;
};

function ErrorFallback({ error }: any) {
  const history = useHistory();
  return (
    <LoggedInPage title="Something went wrong">
      <SpacedContainer centered>
        <p>Something went wrong:</p>
        <p>{error.message}</p>
        <Button onClick={() => history.go(0)}>Try again</Button>
      </SpacedContainer>
    </LoggedInPage>
  );
}

export const ErrorBoundary = ({ children }: Props) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
    {children}
  </ReactErrorBoundary>
);
