import {
  Item,
  ItemCustomFieldEdit,
  ItemEdit,
  createItem,
  updateItem,
  useMutation,
} from 'revibe-api';
import { SELLER_ROUTES } from 'routes';

import { ItemFormManager } from 'modules/catalog/components/ItemForm/ItemFormManager';
import { ItemFormData } from 'modules/catalog/types/itemForm';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';
import { DEFAULT_ITEM_STOCK } from 'modules/catalog/utils/itemStock';

import { useHistory, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

const DEFAULT_VALUES: ItemFormData = {
  sex: 'female',
  available_for_preorder: false,
  stock: DEFAULT_ITEM_STOCK,
  colors: [],
  custom_fields: [],
  alternative_size_guide: false,
};

interface Props {
  item?: Item;
}

export const ItemForm = ({ item }: Props) => {
  const initialData: ItemFormData = item
    ? {
        ...item,
        category: item.category.id,
        colors:
          item.custom_fields.find((cf) => cf.type === 'COLOR')
            ?.custom_field_values || [],
        custom_fields: item.custom_fields.filter((cf) => cf.type === 'SELECT'),
      }
    : DEFAULT_VALUES;

  const { sellerID } = useSeller();
  const { errorToast, successToast } = useToast();
  const history = useHistory();

  const [createNewItem] = useMutation('create-item', createItem, {
    onSuccess: () => {
      successToast('item-added-text');
      history.push(
        `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.PENDING_APPROVAL}`
      );
    },
    onError: errorToast,
  });

  const [updateExistingItem] = useMutation(
    `update-item-${item?.id}`,
    updateItem,
    {
      onSuccess: () => {
        successToast('item-updated-text');
      },
      onError: errorToast,
    }
  );

  const onFormSubmit = async (
    itemData: ItemEdit,
    customFields: ItemCustomFieldEdit[],
    images: File[]
  ) => {
    if (item) {
      await updateExistingItem({
        itemID: item.id,
        item: itemData,
        customFields,
      });
    } else {
      await createNewItem({
        sellerID,
        item: itemData,
        customFields,
        photos: images,
      });
    }
  };

  return (
    <ItemFormManager
      onItemPhotosUpdate={() => history.go(0)}
      initialItemData={initialData}
      onSubmit={onFormSubmit}
      itemPhotos={item?.photos || []}
      itemID={item?.id || null}
    />
  );
};
