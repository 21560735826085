import { WorldIcon } from 'ui/icons';

type Entry = {
  label: string;
  id: string;
  icon: ({ className, onClick }: any) => JSX.Element;
};

export const SERVICES_ENTRIES: Entry[] = [
  {
    label: 'online-distribution',
    id: 'online-distribution',
    icon: WorldIcon,
  },
];
