import { cn } from 'revibe-ui';

import { SparklesIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';

export type PhotoProps = {
  id: string;
  src: string;
  canBeDeleted: boolean;
  is_retouched: boolean;
  srcSet?: string | undefined;
  sizes?: string | undefined;
  width: number;
  height: number;
  alt?: string | undefined;
  key?: string | undefined;
  onDelete: () => void;
  isMoving?: boolean;
};

export const Photo = ({
  isMoving,
  key,
  src,
  alt,
  canBeDeleted,
  onDelete,
  is_retouched,
}: PhotoProps) => {
  return (
    <span className="relative mb-4 mr-4 h-32 w-32 ">
      <img
        className={cn(
          isMoving && 'active:cursor-grabbing',
          'hover:ring-purple h-32 w-32 rounded-md bg-[#f3f3f3] object-cover ring-1 ring-gray-200 ring-offset-2 focus:outline-none '
        )}
        key={key}
        src={src}
        alt={alt}
      />
      {canBeDeleted && (
        <span
          className="absolute inset-0 top-[4px] left-[100px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full bg-red-500 text-sm  font-medium text-white focus-within:opacity-100"
          onClick={onDelete}
        >
          <XMarkIcon className="h-4 w-4 stroke-white" />
        </span>
      )}
      {is_retouched && (
        <span className="absolute inset-0 top-[4px] left-[100px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full bg-purple-100 text-sm  font-medium text-white focus-within:opacity-100">
          <SparklesIcon className="text-purple h-4 w-4" />
        </span>
      )}
    </span>
  );
};
