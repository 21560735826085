import { useTranslation } from 'i18n/hooks';

import { ChevronDownIcon } from '@heroicons/react/20/solid';

type Props = {
  onChange?: (language: string) => void;
};

export const LanguageSelector = ({ onChange }: Props) => {
  const { t, tu, i18n, language } = useTranslation('shared');

  const handleLanguageChange = async (language: string) => {
    onChange && onChange(language);
    await i18n.changeLanguage(language);
  };

  return (
    <form className="mt-4 sm:max-w-xs">
      <fieldset className="w-full">
        <label htmlFor="language" className="sr-only">
          {tu('language')}
        </label>
        <div className="relative">
          <select
            id="language"
            name="language"
            className="focus:ring-purple focus:border-purple block w-full appearance-none rounded-md border border-gray-300 bg-white bg-none py-2 pl-3 pr-10 text-base text-gray-900 focus:outline-none sm:text-sm"
            defaultValue={language}
            onChange={(e) => handleLanguageChange(e.currentTarget.value)}
          >
            <option value={'en'}>{t('english')}</option>
            <option value={'fr'}>{t('french')}</option>
            <option value={'it'}>{t('italian')}</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
            <ChevronDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
};
