import React, { useEffect, useState } from 'react';

import { editItemCollection, getCollection } from 'api/collections.api';
import { Collection, CollectionEdit } from 'api/collections.types';
import { useTranslation } from 'i18n/hooks';
import { useParams } from 'react-router-dom';
import { SpacedContainer, Spinner } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { validateItemsCollection } from 'modules/catalog/utils/itemValidator';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useBoolean, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

import { CollectionForm } from '../components/CollectionForm';

export const CollectionPage = () => {
  const { collection: collectionID } = useParams<{ collection: string }>();
  const { sellerID } = useSeller();
  const { tu } = useTranslation('collections');
  const { errorToast, toast } = useToast();
  const [collection, setCollection] = useState<Collection | null>(null);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const fetchCollection = async () => {
    startFetching();
    const { data, error } = await getCollection(collectionID);
    if (data) {
      setCollection(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionEditRequest = async (
    collectionData: CollectionEdit
  ) => {
    const errors = validateItemsCollection(collectionData);
    if (errors.length !== 0) {
      window.scrollTo(0, 0);
      return;
    }

    const { data, error } = await editItemCollection(
      sellerID,
      collectionID,
      collectionData,
      collectionData.items
    );
    if (data) {
      toast('collection-updated-text');
      stopFetching();
    } else {
      stopFetching();
      errorToast(error);
    }
  };

  if (isFetching || !collection) {
    return (
      <LoggedInPage
        title={tu('collection-heading')}
        breadcrumbs={[
          {
            label: tu('collections-heading'),
            to: SELLER_ROUTES.COLLECTIONS,
          },
          {
            label: tu('collection-heading'),
          },
        ]}
      >
        <SpacedContainer centered>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  return (
    <LoggedInPage
      title={collection.name}
      breadcrumbs={[
        {
          label: tu('collections-heading'),
          to: SELLER_ROUTES.COLLECTIONS,
        },
        {
          label: collection.name,
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{collection.name}</ModuleHeading>

        <CollectionForm
          collectionID={collection.id}
          collectionSlug={collection.slug}
          initialCollectionData={{
            ...collection,
            date: new Date(collection.date),
            items: collection.items.map((i) => i.id),
          }}
          onSubmit={handleCollectionEditRequest}
        />
      </SpacedContainer>
    </LoggedInPage>
  );
};
