import { CollectionEdit } from 'api/collections.types';

export const ITEM_VALIDATION_ERRORS = {
  MISSING_NAME: 'missing-name',
  MISSING_DESCRIPTION: 'missing-description',
  MISSING_PRICE: 'missing-price',
  MISSING_CATEGORY: 'missing-category',
  MISSING_SIZES_INFO: 'missing-sizes-info',
  MISSING_MATERIALS: 'missing-materials',
  MISSING_MAINTENANCE: 'missing-maintenance',
};

export const ITEMS_COLLECTION_VALIDATION_ERRORS = {
  MISSING_NAME: 'missing-collection-name',
  MISSING_DESCRIPTION: 'missing-collection-description',
  MISSING_RELEASE_DATE: 'missing-collection-release-date',
  MISSING_COLLAB_BRAND_NAME: 'missing-collection-collab-name',
  MISSING_COLLAB_BRAND_DESCRIPTION: 'missing-collection-collab-description',
};

export const validateItemsCollection = (collectionData: CollectionEdit) => {
  const errors = [];

  if (!collectionData.name) {
    errors.push(ITEMS_COLLECTION_VALIDATION_ERRORS.MISSING_NAME);
  }
  if (!collectionData.description) {
    errors.push(ITEMS_COLLECTION_VALIDATION_ERRORS.MISSING_DESCRIPTION);
  }
  if (!collectionData.date) {
    errors.push(ITEMS_COLLECTION_VALIDATION_ERRORS.MISSING_RELEASE_DATE);
  }
  if (collectionData.collab?.is_collab) {
    if (!collectionData.collab.brand_name) {
      errors.push(ITEMS_COLLECTION_VALIDATION_ERRORS.MISSING_COLLAB_BRAND_NAME);
    }
    if (!collectionData.collab.brand_description) {
      errors.push(
        ITEMS_COLLECTION_VALIDATION_ERRORS.MISSING_COLLAB_BRAND_DESCRIPTION
      );
    }
  }
  return errors;
};
