import { ItemStock } from 'api/items.types';

export const getItemStock = (stock: ItemStock) =>
  Object.values(stock).reduce((totalStock, amount) => totalStock + amount, 0);

export const isItemInPreorder = (stock: ItemStock) => {
  let inPreorder = false;
  Object.values(stock).forEach((stockEntry) => {
    if (stockEntry === -1) {
      inPreorder = true;
    }
  });
  return inPreorder;
};

export const getIsItemInStock = (stock: ItemStock) =>
  getItemStock(stock) > 0 || isItemInPreorder(stock);

export const DEFAULT_ITEM_STOCK = {
  xxs: 0,
  xs: 0,
  s: 0,
  m: 0,
  l: 0,
  xl: 0,
  xxl: 0,
  '3xl': 0,
  '4xl': 0,
  '5xl': 0,
  '6xl': 0,
  '7xl': 0,
  '8xl': 0,
  unisize: 0,
};

export const APPAREL_SIZES = [
  'unisize',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  '3xl',
];

export const SHOES_SIZES = [
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  '8xl',
];
