import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';
import { AUTH_ROUTES } from 'router';

import { Page } from 'ui/components/Layout/Page';

export const OnboardingSuccessPage = () => {
  const { tu } = useTranslation('onboarding.success');

  return (
    <Page title="Registration completed">
      <div className="flex w-screen items-center justify-center bg-purple-100 sm:h-screen">
        <SpacedContainer centered>
          <div className="bg-purple-100">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
              <div className="bg-purple overflow-hidden rounded-lg shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                  <div className="lg:self-center">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">{tu('heading')}</span>
                      <span className="block">{tu('subheading')}</span>
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-indigo-200">
                      {tu('text')}
                    </p>
                    <a
                      href={AUTH_ROUTES.LOGIN}
                      className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-indigo-600 shadow hover:bg-indigo-50"
                    >
                      {tu('cta')}
                    </a>
                  </div>
                </div>
                <div className="aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 -mt-6">
                  <img
                    className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                    src="https://res.cloudinary.com/revibe/image/upload/v1652089969/Seller%20App/Onboarding/Demo.png"
                    alt="App screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </SpacedContainer>
      </div>
    </Page>
  );
};
