import { forwardRef } from 'react';

import { CollectionEdit } from 'api/collections.types';
import { arrayRemove } from 'revibe-ui';
import { format } from 'date-fns';
import {
  enGB as enLocale,
  fr as frLocale,
  it as itLocale,
} from 'date-fns/esm/locale';
import { useTranslation } from 'i18n/hooks';
import DatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  Button,
  Checkbox,
  Input,
  Label,
  SpacedContainer,
  Tag,
  Textarea,
} from 'revibe-ui';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { ItemsTable } from 'modules/catalog/components';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import { useHistory, useSeller } from 'shared/hooks';

import { NoData } from 'ui/components/Layout/NoData';

import { CollectionPhotosConfigurator } from './CollectionPhotosConfigurator';

interface Props {
  collectionID?: string;
  collectionSlug?: string;
  initialCollectionData: CollectionEdit;
  onSubmit: (data: CollectionEdit) => void;
}

export const CollectionFormSection = ({
  label,
  sublabel,
  children,
}: {
  label: string;
  sublabel: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="space-y-6 pt-8 sm:space-y-5">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{label}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{sublabel}</p>
      </div>

      <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">{children}</div>
    </div>
  );
};

export const CollectionFormField = ({
  label,
  htmlFor,
  children,
}: {
  label: string;
  children: React.ReactNode;
  htmlFor?: string;
}) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-100 sm:pt-5">
      <div>
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
      </div>
      <div className="mt-1 sm:col-span-2 sm:mt-0">{children}</div>
    </div>
  );
};

const TODAY = new Date();

const ButtonInput = forwardRef(function BtnInfo({ onClick }: any, ref: any) {
  const { language } = useTranslation();
  const locale =
    language === 'fr' ? frLocale : language === 'it' ? itLocale : enLocale;
  const { watch } = useFormContext();
  const date = watch('date');

  return (
    <button
      onClick={onClick}
      ref={ref}
      type="button"
      className="focus:ring-purple inline-flex w-full justify-start whitespace-nowrap rounded border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0"
    >
      {format(new Date(date), 'dd MMMM yyyy, HH:mm', { locale: locale })}
    </button>
  );
});

export const CollectionForm = ({
  collectionID,
  collectionSlug,
  initialCollectionData,
  onSubmit,
}: Props) => {
  const { sellerID } = useSeller();
  const { tu, language } = useTranslation('collections');
  const locale =
    language === 'fr' ? frLocale : language === 'it' ? itLocale : enLocale;
  const history = useHistory();
  const methods = useForm<CollectionEdit>({
    defaultValues: {
      ...initialCollectionData,
      date: initialCollectionData.rawDate
        ? new Date(initialCollectionData.rawDate)
        : new Date(initialCollectionData.date),
    },
  });
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
  } = methods;
  const date = watch('date');
  const items = watch('items');
  const isCollab = watch('collab.is_collab');
  const isPublished = watch('is_published');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-12 space-y-14 divide-gray-200">
          {collectionID && (
            <div className="bg-gray-50 ">
              <div className="px-4 py-5 sm:p-6">
                <div className="sm:flex sm:items-start sm:justify-between">
                  <div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {tu('status')}
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <Tag>
                        {isPublished ? tu('published') : tu('in-draft')}
                      </Tag>
                    </div>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      {!isPublished ? (
                        <p>{tu('unpublished-disclaimer')}</p>
                      ) : TODAY < new Date(date) ? (
                        <p>
                          {tu('unreleased-disclaimer')}
                          {new Date(date).toLocaleDateString()}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <SpacedContainer className="mt-5 sm:mt-0 sm:ml-6">
                    {isPublished ? (
                      <Button
                        className="w-full"
                        type="button"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={async () => {
                          setValue('is_published', false);
                          handleSubmit(onSubmit)();
                        }}
                      >
                        {tu('unpublish')}
                      </Button>
                    ) : (
                      <Button
                        className="w-full"
                        type="button"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={async () => {
                          setValue('is_published', true);
                          handleSubmit(onSubmit)();
                        }}
                      >
                        {tu('publish')}
                      </Button>
                    )}
                    <Button
                      variant="subtle"
                      className="w-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.pushBlank(
                          `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/collections/${collectionSlug}?sellerView=true`
                        );
                      }}
                    >
                      {tu('preview', 'shared')}
                    </Button>
                  </SpacedContainer>
                </div>
              </div>
            </div>
          )}
          <CollectionFormSection
            label={tu('general-heading')}
            sublabel={tu('general-subheading')}
          >
            <CollectionFormField label={tu('name-label')}>
              <Input
                {...register('name', { required: true })}
                className="max-w-lg"
                placeholder={tu('name-example')}
                required
              />
            </CollectionFormField>
            <CollectionFormField label={tu('description-label')}>
              <Textarea
                className="max-w-lg"
                {...register('description', { required: true })}
                placeholder={tu('description-example')}
              />
            </CollectionFormField>
          </CollectionFormSection>
          <CollectionFormSection
            label={tu('release-heading')}
            sublabel={tu('release-subheading')}
          >
            <CollectionFormField label={tu('release-date-label')}>
              <div className="flex max-w-lg">
                {' '}
                <div className="relative max-w-lg">
                  <DatePicker
                    locale={language}
                    selected={date}
                    onChange={(d: Date) => {
                      setValue('date', d);
                    }}
                    showTimeSelect
                    popperClassName="react-datepicker-left"
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    customInput={<ButtonInput />}
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="flex items-center justify-between px-2 py-2">
                        <span className="text-lg text-gray-700">
                          {format(date, 'MMMM yyyy', { locale: locale })}
                        </span>

                        <div className="space-x-2">
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                            type="button"
                            className={`
                                    ${
                                      prevMonthButtonDisabled &&
                                      'cursor-not-allowed opacity-50'
                                    }
                                    inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0
                                `}
                          >
                            <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
                          </button>

                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                            type="button"
                            className={`
                                    ${
                                      nextMonthButtonDisabled &&
                                      'cursor-not-allowed opacity-50'
                                    }
                                    inline-flex rounded border border-gray-300 bg-white p-1 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-0
                                `}
                          >
                            <ChevronRightIcon className="h-5 w-5 text-gray-600" />
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </CollectionFormField>
          </CollectionFormSection>

          {collectionID && (
            <CollectionFormSection
              label={tu('photos-heading')}
              sublabel={tu('photos-subheading')}
            >
              <CollectionPhotosConfigurator
                collectionID={collectionID}
                existingPhotos={initialCollectionData.photos}
              />
            </CollectionFormSection>
          )}

          <CollectionFormSection
            label={tu('collab-heading')}
            sublabel={tu('collab-subheading')}
          >
            <CollectionFormField label={tu('collab-label')}>
              <div className="flex items-center space-x-2">
                <Controller
                  name="collab.is_collab"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className="max-w-lg"
                      id="collab.is_collab"
                      value={undefined}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  )}
                />
                <Label htmlFor="collab.is_collab">{tu('is-collab')}</Label>
              </div>
            </CollectionFormField>
            {isCollab && (
              <>
                <CollectionFormField label={tu('collab-brand-name-label')}>
                  <Input
                    {...register('collab.brand_name', { required: true })}
                    className="max-w-lg"
                    required
                    placeholder={tu('collab-brand-name-example')}
                  />
                </CollectionFormField>
                <CollectionFormField
                  label={tu('collab-brand-description-label')}
                >
                  <Textarea
                    className="max-w-lg"
                    {...register('collab.brand_description', {
                      required: true,
                    })}
                    placeholder={tu('collab-brand-description-example')}
                  />
                </CollectionFormField>
              </>
            )}
          </CollectionFormSection>

          {collectionID && (
            <CollectionFormSection
              label={tu('items-heading')}
              sublabel={tu('items-subheading')}
            >
              <CollectionFormField label={tu('items-added-label')}>
                {items.length ? (
                  <ItemsTable
                    filters={{
                      [ITEM_FILTERS.INCLUDE]: items.join(','),
                      [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                      [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                      [ITEM_FILTERS.STATUS]: [
                        ITEM_STATUSES.APPROVED,
                        ITEM_STATUSES.PENDING_APPROVAL,
                        ITEM_STATUSES.REJECTED,
                      ].join(','),
                      [ITEM_FILTERS.SELLER]: sellerID,
                    }}
                    resetToken={items.length.toString()}
                    mainAction={{
                      label: tu('remove', 'shared'),
                      onClick: (item) => {
                        if (!item) {
                          return;
                        }
                        const index = items.findIndex((i) => i === item.id);
                        setValue('items', arrayRemove(items, index));
                      },
                    }}
                  />
                ) : (
                  <NoData heading={tu('no-items')} text="" />
                )}
              </CollectionFormField>
              <CollectionFormField label={tu('items-to-add-label')}>
                <ItemsTable
                  filters={{
                    [ITEM_FILTERS.STATUS]: [
                      ITEM_STATUSES.APPROVED,
                      ITEM_STATUSES.PENDING_APPROVAL,
                      ITEM_STATUSES.REJECTED,
                    ].join(','),
                    [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                    [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                    [ITEM_FILTERS.SELLER]: sellerID,
                    [ITEM_FILTERS.ONLY_NOT_IN_COLLECTION]: true,
                    [ITEM_FILTERS.EXCLUDE]: items.join(','),
                  }}
                  resetToken={items.length.toString()}
                  mainAction={{
                    label: tu('add', 'shared'),
                    onClick: (item) => {
                      if (item) {
                        setValue('items', [...items, item.id]);
                      }
                    },
                  }}
                />
              </CollectionFormField>
            </CollectionFormSection>
          )}

          <Button
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            {collectionID ? tu('edit') : tu('add')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
