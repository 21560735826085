import { apiCall, useQuery } from 'revibe-api';
import { InfoBanner, SpacedContainer, Spinner } from 'revibe-ui';
import { useHistory, useSeller, useToast } from 'shared/hooks';
import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import { SELLER_ROUTES } from 'routes';
import { CSVMappingFieldsForm } from '../components/CSVMappingFieldsForm';

type Mapping = {
  name: { mapping: string | null; label: string };
  description: { mapping: string | null; label: string };
  available_for_preorder: { mapping: string | null; label: string };
  category: { mapping: string | null; label: string };
  maintenance_tips: { mapping: string | null; label: string };
  materials: { mapping: string | null; label: string };
  price: { mapping: string | null; label: string };
  selling_price: { mapping: string | null; label: string };
  sex: { mapping: string | null; label: string };
  sizes_info: { mapping: string | null; label: string };
  slug: { mapping: string | null; label: string };
  traceability: { mapping: string | null; label: string };
  xxs: { mapping: string | null; label: string };
  xs: { mapping: string | null; label: string };
  s: { mapping: string | null; label: string };
  m: { mapping: string | null; label: string };
  l: { mapping: string | null; label: string };
  xl: { mapping: string | null; label: string };
  xxl: { mapping: string | null; label: string };
  '3xl': { mapping: string | null; label: string };
  '4xl': { mapping: string | null; label: string };
  '5xl': { mapping: string | null; label: string };
  '6xl': { mapping: string | null; label: string };
  '7xl': { mapping: string | null; label: string };
  '8xl': { mapping: string | null; label: string };
};

type CSVParseResult = {
  data: Record<string, any>;
  columns: string[];
  records: number;
  mapping: Mapping;
};

const steps = [
  {
    id: 'Step 1',
    name: 'Upload CSV File',
    href: SELLER_ROUTES.CATALOG_CSV_IMPORT,
    status: 'complete',
  },
  { id: 'Step 2', name: 'Column Mapping', href: '#', status: 'complete' },
  { id: 'Step 3', name: 'Import', href: '#', status: 'upcoming' },
  { id: 'Step 4', name: 'Done!', href: '#', status: 'upcoming' },
];

export const CSVMappingPage = () => {
  const { sellerID } = useSeller();
  const { errorToast } = useToast();
  const history = useHistory();
  const [csvFile] = useQuery(
    'get-csv-file',
    async () => {
      return await apiCall({
        endpoint: `business/catalog/csv/parse?sellerID=${sellerID}`,
        method: 'GET',
        reshaper: (res: { result: CSVParseResult }) => res.result,
      });
    },
    {
      defaultValue: null,
      onError: (e) => {
        history.push(SELLER_ROUTES.CATALOG_CSV_IMPORT);
        errorToast(e);
      },
      onSuccess: (res) => console.log(res),
    }
  );

  return (
    <LoggedInPage
      title="CSV Column Mapping"
      breadcrumbs={[
        {
          label: 'CSV Import',
          to: SELLER_ROUTES.CATALOG_CSV_IMPORT,
        },
        {
          label: 'CSV Column Mapping',
        },
      ]}
    >
      <SpacedContainer className="mx-auto w-full max-w-3xl" spacing="large">
        <nav aria-label="Progress">
          <ol
            role="list"
            className="space-y-4 md:flex md:space-x-8 md:space-y-0"
          >
            {steps.map((step) => (
              <li key={step.name} className="md:flex-1">
                {step.status === 'complete' ? (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-purple-600 py-2 pl-4 hover:border-purple-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-purple-600 group-hover:text-purple-800">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : step.status === 'current' ? (
                  <a
                    href={step.href}
                    className="flex flex-col border-l-4 border-purple-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                    aria-current="step"
                  >
                    <span className="text-sm font-medium text-purple-600">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                ) : (
                  <a
                    href={step.href}
                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  >
                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                      {step.id}
                    </span>
                    <span className="text-sm font-medium">{step.name}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
        <div className="boorder-gray-200 overflow-hidden rounded-md border">
          <div className="border-b border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Map CSV fields to products
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Select fields from your CSV file to map against products fields,
              or to ignore during import.
            </p>
          </div>
          <div className="px-4 py-5 sm:p-6">
            {csvFile ? (
              <SpacedContainer>
                <InfoBanner>
                  We found {csvFile.records} records in your CSV file. Please
                  map the fields below.
                </InfoBanner>
                <CSVMappingFieldsForm
                  csvColumns={csvFile.columns}
                  mapping={csvFile.mapping}
                />
              </SpacedContainer>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </SpacedContainer>
    </LoggedInPage>
  );
};
