import { Button, SpacedContainer } from 'revibe-ui';
import { useHistory, useSeller, useToast } from 'shared/hooks';
import { CSVSteps } from './CSVSteps';
import { CSVParseResult } from '../types/csv.types';
import { formatPrice } from 'shared/utils';
import { useMutation, apiCall } from 'revibe-api';
import { SELLER_ROUTES } from 'routes';

interface Props {
  csvData: CSVParseResult;
}

export const CSVRecordsReview = ({ csvData }: Props) => {
  const { sellerID } = useSeller();
  const history = useHistory();
  const { errorToast } = useToast();
  const [importCSVRecords, { isLoading }] = useMutation(
    'import-csv-file',
    async () => {
      return await apiCall({
        endpoint: 'business/catalog/csv/import',
        method: 'POST',
        body: {
          sellerID,
          records: csvData.parsedRecords,
        },
        reshaper: (res: { result: any }) => res.result,
      });
    },
    {
      onSuccess: () => {
        history.push(SELLER_ROUTES.CATALOG_CSV_SUCCESS);
      },
      onError: errorToast,
    }
  );

  return (
    <SpacedContainer className="mx-auto w-full max-w-3xl" spacing="large">
      <CSVSteps
        steps={[
          {
            id: 'Step 1',
            name: 'Upload CSV File',
            href: '#',
            status: 'complete',
          },
          { id: 'Step 2', name: 'Import', href: '#', status: 'current' },
          { id: 'Step 3', name: 'Done!', href: '#', status: 'upcoming' },
        ]}
      />
      <div className="boorder-gray-200 overflow-hidden rounded-md border">
        <div className="border-b border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {csvData.parsedRecordsCount} products found
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {csvData.parsedRecordsCount} products were found in the uploaded
            file. You can now import them in your catalog.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <ul role="list" className="divide-y divide-gray-100">
            {csvData.parsedRecords.map((record) => (
              <li
                key={record.name}
                className="flex justify-between gap-x-6 py-5"
              >
                <div className="flex gap-x-4">
                  <img
                    className="h-12 w-12 flex-none rounded-full bg-gray-50"
                    src={
                      record.photos[0] ||
                      'https://res.cloudinary.com/revibe/image/upload/v1639411886/home/Frame_17_ocht0g.svg'
                    }
                    alt=""
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {record.name}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {record.description}
                    </p>
                  </div>
                </div>
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">
                    {formatPrice(record.selling_price || record.price)} &euro;
                  </p>
                  {record.selling_price && (
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      {formatPrice(record.price)} &euro;
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-end border-t border-gray-200  bg-gray-50 px-4 py-5 sm:px-6">
          <Button
            isLoading={isLoading}
            disabled={!csvData || csvData.parsedRecordsCount === 0}
            onClick={async () => {
              return await importCSVRecords({});
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </SpacedContainer>
  );
};
