import { useEffect, useState } from 'react';

import { deletePhoto, updatePhoto } from 'api/items.api';
import { ItemPhoto } from 'api/items.types';
import { arrayRemove } from 'revibe-ui';
import Gallery from 'react-photo-gallery';

import { Photo } from 'shared/components/Photo';
import { useToast } from 'shared/hooks';

type Props = {
  itemID: string;
  photos: ItemPhoto[];
  onUpdate: () => void;
};

export const ItemPhotosDeleter = ({ photos, itemID, onUpdate }: Props) => {
  const { errorToast, toast } = useToast();
  const [galleryPhotos, setGalleryPhotos] = useState(
    photos.map((p) => ({
      ...p,
      src: p.medium_link,
      width: 1,
      height: 1,
      id: p.id,
      canBeDeleted: !p.is_retouched,
      onDelete: () => onDeletePhoto(p),
    }))
  );
  useEffect(() => {
    setGalleryPhotos(
      photos.map((p) => ({
        ...p,
        src: p.medium_link,
        width: 1,
        height: 1,
        id: p.id,
        canBeDeleted: !p.is_retouched,
        onDelete: () => onDeletePhoto(p),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const onDeletePhoto = async (photo: ItemPhoto) => {
    const index = galleryPhotos.findIndex((i) => i.id === photo.id);
    const newItemsOrder = arrayRemove(galleryPhotos, index);
    setGalleryPhotos(newItemsOrder);
    const { error } = await deletePhoto(itemID, photo.id);
    if (error) {
      errorToast(error);
    }
    await Promise.all(
      newItemsOrder.map(
        async (photo, i) =>
          await updatePhoto(itemID, photo.id, {
            order: i,
          })
      )
    );
    toast('item-updated-text');
    onUpdate();
  };

  return (
    <div className="min-h-32 mb-4 ">
      <Gallery
        photos={galleryPhotos}
        renderImage={(props: any) => <Photo {...props.photo} />}
      />
    </div>
  );
};
