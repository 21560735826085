import { OrderItem as OrderItemType } from 'api/items.types';
import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { OrderItemUpdate, updateOrderItem } from 'revibe-api';
import { Button, SpacedContainer, Tag } from 'revibe-ui';
import { MARKETPLACE_ROUTES } from 'routes';

import { getSizeLabelFromSize } from 'modules/catalog/utils/itemSize';
import {
  ITEM_ORDER_STATUS,
  getOrderItemStepFromStatus,
} from 'modules/orders/utils/orderStatus';

import { useBoolean, useToast } from 'shared/hooks';
import { formatPrice } from 'shared/utils';

type OrderItemProps = {
  item: OrderItemType;
  onUpdate: () => void;
};

export const OrderItem = ({ item, onUpdate }: OrderItemProps) => {
  const { tu, t } = useTranslation('orders');
  const { errorToast, toast } = useToast();
  const [isUpdating, startUpdating, stopUpdating] = useBoolean();
  const handleItemUpdate = async (itemUpdate: OrderItemUpdate) => {
    startUpdating();
    const { error } = await updateOrderItem(item.id, itemUpdate);
    stopUpdating();
    if (error) {
      errorToast(error);
    } else {
      toast('Item order updated!');
      onUpdate();
    }
  };

  const renderAvailabilityInfo = () => {
    if (item.availability_confirmed === null) {
      return (
        <SpacedContainer
          type="horizontal"
          className="mt-3 text-sm text-gray-500"
          centered
        >
          <p>{tu('availability', 'shared')}:</p>
          <Button
            variant="subtle"
            isLoading={isUpdating}
            disabled={isUpdating}
            onClick={() =>
              handleItemUpdate({
                availability_confirmed: true,
                status: item.is_preorder
                  ? ITEM_ORDER_STATUS.TO_PRODUCE
                  : ITEM_ORDER_STATUS.TO_SHIP,
              })
            }
            className="whitespace-nowrap"
          >
            {tu('confirm-availability')}
          </Button>
          <Button
            variant="error"
            isLoading={isUpdating}
            disabled={isUpdating}
            onClick={() =>
              handleItemUpdate({
                availability_confirmed: false,
              })
            }
            className="whitespace-nowrap"
          >
            {tu('not-available')}
          </Button>
        </SpacedContainer>
      );
    }
    return (
      <p className="mt-3 text-sm text-gray-500">
        {tu('availability')}:{' '}
        <strong>
          {item.availability_confirmed
            ? tu('availability-confirmed')
            : tu('availability-not-confirmed')}
        </strong>
      </p>
    );
  };

  return (
    <div
      key={item.id}
      className="rounded-md border-t border-b border-gray-200 bg-white sm:border"
    >
      <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
        <div className="sm:flex lg:col-span-7">
          <div className="aspect-w-1 aspect-h-1 sm:aspect-none w-full flex-shrink-0 overflow-hidden sm:h-40 sm:w-40">
            <img
              src={item.photos[0].small_link}
              alt={item.name}
              className="h-full w-full object-cover object-center sm:h-full sm:w-full"
            />
          </div>

          <div className="mt-6 sm:mt-0 sm:ml-6">
            <h3 className="text-base font-medium text-gray-900">
              <a
                href={`${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}${MARKETPLACE_ROUTES.ITEM}/${item.slug}`}
              >
                {item.name}
              </a>
            </h3>
            <p className="mt-2 text-sm font-medium text-gray-900">
              {formatPrice((item.selling_price || item.price) * item.amount)}{' '}
              &euro;{' '}
              {item.amount > 1 && (
                <span>
                  ({formatPrice(item.selling_price || item.price)} &euro;{' '}
                  {t('each')})
                </span>
              )}
            </p>
            <p className="mt-3 text-sm text-gray-500">
              {tu('quantity')}: <strong>{item.amount}</strong>
            </p>
            <p className="mt-3 text-sm text-gray-500">
              {tu('size')}:{' '}
              <strong>
                {getSizeLabelFromSize(item.size, item.category).toUpperCase()}
              </strong>
            </p>
            {item.colors && (
              <SpacedContainer
                spacing="small"
                centered
                type="horizontal"
                className="mt-3 text-sm text-gray-500"
              >
                <p>{tu('color')}: </p>
                {item.colors.split(',').map((color) => (
                  <span
                    key={color}
                    className="ml-2 h-[12px] w-[12px] rounded-full"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </SpacedContainer>
            )}
            {item.custom_options && (
              <p className="mt-3 text-sm text-gray-500">
                {tu('custom-fields')}: <strong>{item.custom_options}</strong>
              </p>
            )}
            {item.user_info && (
              <p className="mt-3 text-sm text-gray-500">
                {tu('additional-info')}: <strong>{item.user_info}</strong>
              </p>
            )}
            {item.is_preorder && (
              <Tag size="small">
                {tu('type-made-to-order', 'catalog.stock')}
              </Tag>
            )}
            <p className="mt-3 text-sm text-gray-500">
              {tu('status')}:{' '}
              <strong>{tu(`item.status.${item.status}`)}</strong>
            </p>
            {renderAvailabilityInfo()}
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
        <h4 className="sr-only">Status</h4>
        <p className="text-sm font-medium text-gray-900">
          {tu(`item.status.${item.status}`)}
        </p>
        {getOrderItemStepFromStatus(item.status) !== -1 && (
          <div className="mt-6" aria-hidden="true">
            <div className="overflow-hidden rounded-full bg-gray-200">
              <div
                className="bg-purple h-2 rounded-full"
                style={{
                  width:
                    getOrderItemStepFromStatus(item.status) > 2
                      ? '100%'
                      : `calc((${getOrderItemStepFromStatus(
                          item.status
                        )} * 2 + 1) / 8 * 100%)`,
                }}
              />
            </div>
            <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
              <div className="text-purple">{tu('item.status.IN_REVIEW')}</div>
              <div
                className={cn(
                  getOrderItemStepFromStatus(item.status) > 0
                    ? 'text-purple'
                    : '',
                  'text-center'
                )}
              >
                {tu('item.status.TO_SHIP')}
              </div>
              <div
                className={cn(
                  getOrderItemStepFromStatus(item.status) > 1
                    ? 'text-purple'
                    : '',
                  'text-center'
                )}
              >
                {tu('item.status.SHIPPED')}
              </div>
              <div
                className={cn(
                  getOrderItemStepFromStatus(item.status) > 2
                    ? 'text-purple'
                    : '',
                  'text-right'
                )}
              >
                {tu('item.status.DELIVERED')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
