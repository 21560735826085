export const AUTH_ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  SIGNUP_CONFIRMATION: '/signup-confirmation',
  UNAUTHORIZED: '/unauthorized',
  RESET_PASSWORD: '/reset-password',
  ADMIN_SESSION: '/admin-session',
};

export const SELLER_ROUTES = {
  HOME: '/',
  ITEMS: '/items',
  ITEMS_NEW: '/items/new',
  ITEM: '/items/:itemID',
  CATALOG_CSV: '/catalog/csv',
  CATALOG_CSV_IMPORT: '/catalog/csv/import',
  CATALOG_CSV_MAPPING: '/catalog/csv/mapping',
  CATALOG_CSV_PROCESS: '/catalog/csv/process',
  CATALOG_CSV_SUCCESS: '/catalog/csv/success',
  CATALOG_CSV_EXPORT: '/catalog/csv/export',
  COLLECTIONS: '/collections',
  COLLECTION_NEW: '/collections/new',
  COLLECTION: '/collections/:collection',
  ORDERS_CURRENT: '/orders/current',
  ORDERS_PAST: '/orders/past',
  ORDER: '/orders/:orderID',
  ONBOARDING: '/create-account',
  ONBOARDING_SUCCESS: '/create-account/success',
  STRIPE_CONNECTED_ACCOUNT_SETUP_REFRESH: '/onboarding/refresh',
  SETTINGS: '/settings',
  SETTINGS_SELL: '/settings/selling',
};

export const COMPANY_ROUTES = {
  SERVICES: '/services',
  CUSTOMERS: '/customers',
  CONTACTS: '/contacts',
};

export const MARKETPLACE_ROUTES = {
  ITEM: '/shop/item',
  SELLER: '/shop/brands',
};
