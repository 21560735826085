import { createItemCollection } from 'api/collections.api';
import { CollectionEdit } from 'api/collections.types';
import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { validateItemsCollection } from 'modules/catalog/utils/itemValidator';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useHistory, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';
import { getDynamicRouteUrl } from 'shared/utils';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

import { CollectionForm } from '../components/CollectionForm';

const TODAY = new Date();

export const NewCollectionPage = () => {
  const { tu } = useTranslation('collections');
  const history = useHistory();
  const { sellerID } = useSeller();
  const { errorToast, toast } = useToast();

  const handleCollectionCreationRequest = async (
    collectionData: CollectionEdit
  ) => {
    const errors = validateItemsCollection(collectionData);
    if (errors.length !== 0) {
      window.scrollTo(0, 0);
      return;
    }
    const { data, error } = await createItemCollection(
      sellerID,
      collectionData
    );
    if (data) {
      toast('collection-added-text');
      history.push(
        getDynamicRouteUrl(SELLER_ROUTES.COLLECTION, {
          id: sellerID,
          collection: data.id,
        })
      );
    } else {
      errorToast(error);
    }
  };

  const defaultValues: CollectionEdit = {
    name: '',
    date: TODAY,
    description: '',
    is_published: false,
    is_released: false,
    items: [],
    photos: [],
    collab: {
      is_collab: false,
      brand_name: '',
      brand_description: '',
    },
  };

  return (
    <LoggedInPage
      title={tu('create-a-collection')}
      breadcrumbs={[
        {
          label: tu('collections-heading'),
          to: SELLER_ROUTES.COLLECTIONS,
        },
        {
          label: tu('create-a-collection'),
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{tu('create-a-collection')}</ModuleHeading>

        <CollectionForm
          initialCollectionData={defaultValues}
          onSubmit={handleCollectionCreationRequest}
        />
      </SpacedContainer>
    </LoggedInPage>
  );
};
