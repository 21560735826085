import { useEffect, useMemo, useState } from 'react';

import {
  deleteCollectionPhoto,
  updateCollectionPhoto,
} from 'api/collections.api';
import { useAuthentication } from 'auth/hooks/useAuthentication';
import { arrayMove, arrayRemove } from 'revibe-ui';
import Gallery from 'react-photo-gallery';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { useHistory, useToast } from 'shared/hooks';

type Props = {
  collectionID: string | null;
  photos: { id: string; src: string }[];
};

type ListItemPhoto = {
  id: string;
  src: string;
  canBeDeleted: boolean;
  srcSet?: string | undefined;
  sizes?: string | undefined;
  width: number;
  height: number;
  alt?: string | undefined;
  key?: string | undefined;
  onDelete: () => void;
};

const Photo = ({ photo }: { photo: ListItemPhoto }) => {
  return (
    <span className="relative mr-4 mb-4 h-[96px] w-[96px]">
      <img
        className="hover:ring-purple h-[96px] w-[96px] cursor-grab rounded-md ring-1 ring-gray-200 ring-offset-2 focus:outline-none active:cursor-grabbing"
        key={photo.key}
        src={photo.src}
        alt="seller img"
      />
      {photo.canBeDeleted && (
        <span
          className="absolute inset-0 top-[4px] left-[68px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full bg-red-500 bg-opacity-75 text-sm font-medium text-white transition-opacity focus-within:opacity-100 hover:opacity-100"
          onClick={photo.onDelete}
        >
          <XMarkIcon className="h-3 w-3 stroke-white" />
        </span>
      )}
    </span>
  );
};

const SortablePhoto = SortableElement((item: { photo: ListItemPhoto }) => (
  <Photo {...item} />
));
const SortableGallery = SortableContainer<{ items: ListItemPhoto[] }>(
  ({ items }: { items: ListItemPhoto[] }) => (
    <Gallery
      photos={items}
      renderImage={(props: any) => <SortablePhoto {...props} />}
    />
  )
);

export const CollectionPhotoOrder = ({ collectionID, photos }: Props) => {
  const { userID } = useAuthentication();
  const history = useHistory();
  const onDeletePhoto = async (p: { id: string; src: string }) => {
    if (!collectionID) {
      return;
    }
    const index = items.findIndex((i) => i.id === p.id);
    const newItemsOrder = arrayRemove(items, index);
    setItems(newItemsOrder);
    const { error } = await deleteCollectionPhoto(
      userID || '',
      collectionID,
      p.id
    );
    if (error) {
      errorToast(error);
      history.go(0);
    }
    await Promise.all(
      newItemsOrder.map(
        async (photo, i) =>
          await updateCollectionPhoto(userID || '', collectionID, photo.id, {
            order: i,
          })
      )
    );
    toast('item-updated-text');
    history.go(0);
  };
  const { toast, errorToast } = useToast();
  const listItems = useMemo(() => {
    return photos.map((p) => ({
      ...p,
      src: p.src,
      width: 1,
      height: 1,
      id: p.id,
      canBeDeleted: collectionID !== null && photos.length > 1,
      onDelete: () => onDeletePhoto(p),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);
  const [items, setItems] = useState(listItems);
  useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }
    if (!collectionID) {
      return;
    }
    const newItemsOrder = arrayMove(items, oldIndex, newIndex);
    setItems(newItemsOrder);
    await Promise.all(
      newItemsOrder.map(
        async (photo, i) =>
          await updateCollectionPhoto(userID || '', collectionID, photo.id, {
            order: i,
          })
      )
    );
    toast('item-updated-text');
  };

  return (
    <SortableGallery
      distance={1}
      items={items}
      onSortEnd={onSortEnd}
      axis={'xy'}
    />
  );
};
