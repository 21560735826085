export const getPercentageCutFromPrice = (
  price: number,
  sellingPrice: number
) => {
  return Number((((price - sellingPrice) / price) * 100).toFixed(2));
};

export const getPriceFromPercentageCut = (
  price: number,
  percentage: number
) => {
  return Number((price - (price / 100) * percentage || 0).toFixed(2));
};
