import { Controller, useForm } from 'react-hook-form';
import { apiCall, useMutation } from 'revibe-api';
import {
  Table,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SpacedContainer,
  Button,
} from 'revibe-ui';
import { useSeller, useToast } from 'shared/hooks';

type Mapping = {
  name: { mapping: string | null; label: string };
  description: { mapping: string | null; label: string };
  available_for_preorder: { mapping: string | null; label: string };
  category: { mapping: string | null; label: string };
  maintenance_tips: { mapping: string | null; label: string };
  materials: { mapping: string | null; label: string };
  price: { mapping: string | null; label: string };
  selling_price: { mapping: string | null; label: string };
  sex: { mapping: string | null; label: string };
  sizes_info: { mapping: string | null; label: string };
  // slug: { mapping: string | null; label: string };
  // traceability: { mapping: string | null; label: string };
  xxs: { mapping: string | null; label: string };
  xs: { mapping: string | null; label: string };
  s: { mapping: string | null; label: string };
  m: { mapping: string | null; label: string };
  l: { mapping: string | null; label: string };
  xl: { mapping: string | null; label: string };
  xxl: { mapping: string | null; label: string };
  '3xl': { mapping: string | null; label: string };
  '4xl': { mapping: string | null; label: string };
  '5xl': { mapping: string | null; label: string };
  '6xl': { mapping: string | null; label: string };
  '7xl': { mapping: string | null; label: string };
  '8xl': { mapping: string | null; label: string };
};

interface Props {
  csvColumns: string[];
  mapping: Mapping;
}

export const CSVMappingFieldsForm = ({ csvColumns, mapping }: Props) => {
  const { sellerID } = useSeller();
  const { errorToast } = useToast();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: mapping.name.mapping,
      description: mapping.description.mapping,
      available_for_preorder: mapping.available_for_preorder.mapping,
      category: mapping.category.mapping,
      maintenance_tips: mapping.maintenance_tips.mapping,
      materials: mapping.materials.mapping,
      price: mapping.price.mapping,
      selling_price: mapping.selling_price.mapping,
      sex: mapping.sex.mapping,
      sizes_info: mapping.sizes_info.mapping,
      // slug: mapping.slug.mapping,
      // traceability: mapping.traceability.mapping,
      xxs: mapping.xxs.mapping,
      xs: mapping.xs.mapping,
      s: mapping.s.mapping,
      m: mapping.m.mapping,
      l: mapping.l.mapping,
      xl: mapping.xl.mapping,
      xxl: mapping.xxl.mapping,
      '3xl': mapping['3xl'].mapping,
      '4xl': mapping['4xl'].mapping,
      '5xl': mapping['5xl'].mapping,
      '6xl': mapping['6xl'].mapping,
      '7xl': mapping['7xl'].mapping,
      '8xl': mapping['8xl'].mapping,
    },
  });
  const [importCSVRecords] = useMutation(
    'import-csv-file',
    async () => {
      return await apiCall({
        endpoint: 'business/catalog/csv/import',
        method: 'POST',
        body: {
          sellerID,
        },
        reshaper: (res: { result: any }) => res.result,
      });
    },
    {
      onSuccess: () => {
        // history.push(SELLER_ROUTES.CATALOG_CSV_MAPPING);
      },
      onError: errorToast,
    }
  );

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        console.log(values);
        await importCSVRecords({});
      })}
    >
      <SpacedContainer>
        <Table
          columns={['Column name', 'Map to field']}
          data={Object.entries(mapping).map(([key, value]) => {
            return [
              value.label,
              <Controller
                name={key as any}
                control={control}
                render={({ field }) => (
                  <Select
                    value={field.value || undefined}
                    onValueChange={field.onChange}
                    required
                  >
                    <SelectTrigger
                      className="max-w-xl"
                      placeholder="Select a column from the CSV"
                    >
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="max-w-xl">
                      {csvColumns.map((c) => (
                        <SelectItem value={c}>{c}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />,
            ];
          })}
        />
        <Button isLoading={isSubmitting} type="submit">
          Run the importer
        </Button>
      </SpacedContainer>
    </form>
  );
};
