import { useTranslation } from 'i18n/hooks';
import { Button, SpacedContainer, Tag } from 'revibe-ui';

import { useHistory } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

import { AddressSettings } from './AddressSettings';

export const SellingSettings = () => {
  const { tu } = useTranslation('settings');
  const { seller } = useSeller();

  const history = useHistory();

  const handleOpenDashboard = (url: string, accountID: string) => {
    localStorage.setItem('StripeSellerAccountID', accountID);
    const win = history.pushBlank(url);
    if (win) {
      win.focus();
    }
  };

  return (
    <SpacedContainer spacing="large" className="divide-y">
      <SpacedContainer spacing="large">
        <div>
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {tu('stripe.heading')}
          </h2>
          <p className="mt-1 text-sm text-gray-500">{tu('stripe.text')}</p>
        </div>
        <div className="w-full">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-sm font-medium text-gray-900">
                {tu('stripe.connect.heading')}
              </h3>
              {!seller.stripe_account_active && (
                <Tag color="red">{tu('stripe.setup-status.incomplete')}</Tag>
              )}
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p className="text-sm text-gray-400">
                  {tu('stripe.connect.text')}{' '}
                  <a
                    href="https://stripe.com/connect"
                    className="text-sm text-gray-400 underline hover:text-gray-800"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tu('stripe.connect.learn-more')}
                  </a>
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
              {seller.stripe_account_active ? (
                <Button
                  onClick={() =>
                    handleOpenDashboard(
                      seller.stripe_login_link,
                      seller.stripe_account_id
                    )
                  }
                >
                  {tu('stripe.connect.go-to-dashboard')}
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    handleOpenDashboard(
                      seller.stripe_login_link,
                      seller.stripe_account_id
                    )
                  }
                >
                  {tu('stripe.connect.finish-setup')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </SpacedContainer>
      <div className="pt-8">
        <AddressSettings />
      </div>
    </SpacedContainer>
  );
};
