import React, { useState } from 'react';

import { updateSeller, updateSellerLogo } from 'api/sellers.api';
import { Seller, SellerUpdate } from 'api/sellers.types';
import { useCountries, useTranslation } from 'i18n/hooks';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Button,
  ErrorBanner,
  FormControl,
  Input,
  Drawer,
  MultiInfoField,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SpacedContainer,
  Textarea,
  ProfilePhotoPlaceholder,
} from 'revibe-ui';

import { ITEM_PHOTOS_ERRORS } from 'modules/catalog/utils/itemPhotos';

import { useBoolean, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

import { FacebookIcon, InstagramIcon, WorldIcon } from 'ui/icons';

export const GeneralSettings = () => {
  const { seller, refresh } = useSeller();
  const { register, control, handleSubmit, reset } = useForm<Seller>({
    defaultValues: seller,
  });
  const { tu, t } = useTranslation('settings');
  const { errorToast, toast } = useToast();
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [isLogoDrawerOpen, openLogoDrawer, closeLogoDrawer] = useBoolean();
  const { countries } = useCountries();
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    maxSize: 1500000,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setLogo(acceptedFiles[0]);
        setLogoPreview(URL.createObjectURL(acceptedFiles[0]));
      }
    },
  });
  const hasFilesTooBig =
    fileRejections.findIndex(({ errors }) =>
      errors.find((error) => error.code === 'file-too-large')
    ) !== -1;
  const handleProfileUpdate = async (sellerUpdate: Seller) => {
    const { error } = await updateSeller(
      seller.id,
      sellerUpdate as unknown as SellerUpdate
    );
    if (error) {
      return errorToast(error);
    }
    toast('profile-updated');
  };

  return (
    <form className="space-y-8" onSubmit={handleSubmit(handleProfileUpdate)}>
      <div className="space-y-8 divide-y">
        <div>
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {tu('profile.heading')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {tu('profile.subheading')}
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <FormControl
              label={tu('profile.email')}
              htmlFor="email"
              className="sm:col-span-4"
            >
              <Input
                {...register('email', { required: true })}
                id="email"
                defaultValue={seller.email}
              />
            </FormControl>

            <div className="relative ml-auto mr-auto h-40 w-40 overflow-hidden rounded-full sm:col-span-2 sm:row-span-2">
              {seller.logo ? (
                <img
                  className="relative h-40 w-40 rounded-full"
                  src={seller.logo}
                  alt=""
                />
              ) : (
                <ProfilePhotoPlaceholder size={40} />
              )}
              <label
                htmlFor="desktop-user-photo"
                className="bg-purple absolute inset-0 flex h-40 w-40 cursor-pointer items-center justify-center rounded-full bg-opacity-75 text-sm font-medium text-white opacity-0 transition-opacity focus-within:opacity-100 hover:opacity-100"
                onClick={openLogoDrawer}
              >
                <span>{tu('change')}</span>
              </label>
            </div>

            <FormControl
              label={tu('profile.username')}
              htmlFor="slug"
              className="sm:col-span-4"
            >
              <MultiInfoField position="pre" info="revibe-upcycling.com/">
                <Input
                  {...register('slug')}
                  className="w-full rounded-l-none"
                  disabled
                  defaultValue={seller.slug}
                />
              </MultiInfoField>
            </FormControl>

            <FormControl
              label={tu('profile.about')}
              sublabel={tu('profile.about-sublabel')}
              htmlFor="description"
              className="sm:col-span-6"
            >
              <Textarea
                {...register('description')}
                defaultValue={seller.description}
              />
            </FormControl>
            <FormControl
              label={tu('profile.country')}
              className="min-w-[380px]"
            >
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select value={field.value} onValueChange={field.onChange}>
                    <SelectTrigger className="max-w-xl">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="max-w-xl">
                      {countries.map((country) => (
                        <SelectItem value={country.code}>
                          {country.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className="pt-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {tu('social.heading')}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {tu('social.subheading')}
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <FormControl
              label={tu('social.website')}
              htmlFor="website_url"
              className="sm:col-span-3"
            >
              <MultiInfoField
                position="pre"
                info={<WorldIcon className="h-5 w-5 stroke-gray-500" />}
              >
                <Input
                  {...register('website_url')}
                  className="w-full rounded-l-none"
                  defaultValue={seller.website_url || ''}
                />
              </MultiInfoField>
            </FormControl>

            <FormControl
              label={tu('social.instagram')}
              htmlFor="ig_url"
              className="sm:col-span-3"
            >
              <MultiInfoField
                position="pre"
                info={<InstagramIcon className="h-5 w-5 stroke-gray-500" />}
              >
                <Input
                  {...register('ig_url')}
                  className="w-full rounded-l-none"
                  defaultValue={seller.ig_url || ''}
                />
              </MultiInfoField>
            </FormControl>

            <FormControl
              label={tu('social.facebook')}
              htmlFor="fb_url"
              className="sm:col-span-3"
            >
              <MultiInfoField
                position="pre"
                info={<FacebookIcon className="h-5 w-5 stroke-gray-500" />}
              >
                <Input
                  {...register('fb_url')}
                  className="w-full rounded-l-none"
                  id="fb_url"
                  defaultValue={seller.fb_url || ''}
                />
              </MultiInfoField>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <SpacedContainer type="horizontal" className="justify-end">
          <Button variant="subtle" type="button" onClick={() => reset(seller)}>
            {tu('cancel')}
          </Button>
          <Button type="submit">{tu('save')}</Button>
        </SpacedContainer>
      </div>

      <Drawer scrollable open={isLogoDrawerOpen} onOpenChange={closeLogoDrawer}>
        <section className="flex max-w-lg justify-center rounded-md border border-dashed border-gray-200 px-6 pt-5 pb-6">
          <p>{tu('logo.heading')}</p>
          <div
            {...getRootProps({
              className: 'space-y-1 text-center dropzone w-full',
            })}
          >
            {logoPreview && (
              <>
                <div className="mx-auto mb-4 flex w-fit flex-wrap space-x-4">
                  <span className="relative h-[96px] w-[96px]">
                    <img
                      className="hover:ring-purple h-[96px] w-[96px] rounded-md ring-1 ring-gray-200 ring-offset-2 focus:outline-none"
                      src={logoPreview}
                      alt="seller img"
                    />
                  </span>
                </div>
                <div className="pb-4">
                  <Button
                    type="button"
                    disabled={isUploadingLogo || logo === null}
                    isLoading={isUploadingLogo}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!logo) {
                        return;
                      }
                      setIsUploadingLogo(true);
                      const { error } = await updateSellerLogo(seller.id, logo);
                      if (error) {
                        setIsUploadingLogo(false);
                        return errorToast();
                      }
                      setIsUploadingLogo(false);
                      await refresh();
                      closeLogoDrawer();
                    }}
                  >
                    {tu('save')}
                  </Button>
                </div>
              </>
            )}
            {hasFilesTooBig && (
              <ErrorBanner>
                {tu(ITEM_PHOTOS_ERRORS.TOO_BIG, 'photos.items')}
                <br />
                <br />
                <span>
                  {tu('item-photo-too-big-tip', 'photos.items')}{' '}
                  <Link to="https://www.reduceimages.com/" target="_blank">
                    https://www.reduceimages.com/
                  </Link>
                </span>
              </ErrorBanner>
            )}
            <svg
              className="mx-auto h-8 w-8 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="mx-auto flex w-full justify-center text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="text-purple relative cursor-pointer rounded-md bg-white font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-500 focus-within:ring-offset-2 hover:text-purple-800"
              >
                <span>{tu('upload', 'photos')}</span>
                <input
                  {...getInputProps()}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="dropzone w-full"
                />
              </label>
              <p className="pl-1">{t('drag-drop', 'photos')}</p>
            </div>
            <p className="text-xs text-gray-500">{tu('format', 'photos')}</p>
          </div>
        </section>
      </Drawer>
    </form>
  );
};
