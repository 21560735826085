import {
  Item,
  ItemCustomFieldEdit,
  ItemEdit,
  ItemFilters,
  ItemPhoto,
  ItemPhotoUpdate,
} from 'revibe-api';

import { convertObjectToQueryParams } from 'shared/utils';

import { PaginationResponse } from './pagination.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const createItem = async (
  sellerID: string,
  item: ItemEdit,
  customFields: ItemCustomFieldEdit[],
  photos: File[]
) => {
  const data = new FormData();
  const stringifiedItem = JSON.stringify(item);
  const stringifiedCustomFields = JSON.stringify(customFields);
  data.append('item', stringifiedItem);
  data.append('customFields', stringifiedCustomFields);
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/items`,
    body: data,
    method: 'POST',
    reshaper: (res: Item) => res,
    rawBody: true,
  });
};

export const updateItem = async (
  itemID: string,
  item: ItemEdit,
  customFields?: ItemCustomFieldEdit[]
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}`,
    body: { ...item, customFields },
    method: 'PUT',
    reshaper: (res: Item) => res,
  });

export const getItems = async (filters: ItemFilters, page = 1, limit = 0) => {
  let url = `${REVIBE_API_URL}/items?page=${page}&limit=${limit}${convertObjectToQueryParams(
    filters
  )}`;

  return await apiCall({
    endpoint: url,
    method: 'GET',
    reshaper: (res: PaginationResponse<Item>) => res,
  });
};

export const getItem = async (itemID: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}`,
    method: 'GET',
    reshaper: (res: Item) => res,
  });

export const getItemPhotos = async (itemID: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/photos`,
    method: 'GET',
    reshaper: (res: ItemPhoto[]) => res,
  });

export const addPhotos = async (photos: File[], itemID: string) => {
  const data = new FormData();
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/photos`,
    method: 'POST',
    body: data,
    rawBody: true,
    reshaper: (res: ItemPhoto[]) => res,
  });
};

export const updatePhoto = async (
  itemID: string,
  photoID: string,
  update: ItemPhotoUpdate
) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/photos/${photoID}`,
    method: 'PUT',
    body: update,
    reshaper: (res: any) => res,
  });

export const deletePhoto = async (itemID: string, photoID: string) =>
  await apiCall({
    endpoint: `${REVIBE_API_URL}/items/${itemID}/photos/${photoID}`,
    method: 'DELETE',
    reshaper: (res: any) => res,
  });
