import { Item, ItemCustomFieldEdit, ItemEdit } from '../types/items.types';
import { Photo, PhotoUpdate } from '../types/photos.types';
import { apiCall } from '../utils/apiCall';

export const createItem = async ({
  sellerID,
  item,
  customFields,
  photos,
}: {
  sellerID: string;
  item: ItemEdit;
  customFields: ItemCustomFieldEdit[];
  photos: File[];
}) => {
  const data = new FormData();
  const stringifiedItem = JSON.stringify(item);
  const stringifiedCustomFields = JSON.stringify(customFields);
  data.append('item', stringifiedItem);
  data.append('customFields', stringifiedCustomFields);
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `sellers/${sellerID}/items`,
    body: data,
    method: 'POST',
    reshaper: (res: Item) => res,
    rawBody: true,
  });
};

export const updateItem = async ({
  item,
  itemID,
  customFields,
}: {
  itemID: string;
  item: ItemEdit;
  customFields?: ItemCustomFieldEdit[];
}) =>
  await apiCall({
    endpoint: `items/${itemID}`,
    body: { ...item, customFields },
    method: 'PUT',
    reshaper: (res: Item) => res,
  });

export const addPhotos = async ({
  photos,
  itemID,
}: {
  photos: File[];
  itemID: string;
}) => {
  const data = new FormData();
  photos.forEach((f) => data.append('files', f));
  return await apiCall({
    endpoint: `items/${itemID}/photos`,
    method: 'POST',
    body: data,
    rawBody: true,
    reshaper: (res: Photo[]) => res,
  });
};

export const updatePhoto = async ({
  itemID,
  photoID,
  update,
}: {
  itemID: string;
  photoID: string;
  update: PhotoUpdate;
}) =>
  await apiCall({
    endpoint: `items/${itemID}/photos/${photoID}`,
    method: 'PUT',
    body: update,
    reshaper: (res: any) => res,
  });

export const deletePhoto = async ({
  itemID,
  photoID,
}: {
  itemID: string;
  photoID: string;
}) =>
  await apiCall({
    endpoint: `items/${itemID}/photos/${photoID}`,
    method: 'DELETE',
    reshaper: (res: any) => res,
  });
