import { useTranslation } from 'i18n/hooks';
import { toast as sonnerToast } from 'sonner';

export const useToast = () => {
  const { tu } = useTranslation('notifications');

  const errorToast = (text?: string | null) => {
    sonnerToast.error(tu(`error.${text || 'unknown'}`));
  };

  const toast = (text: string) => {
    sonnerToast(tu(`info.${text}`));
  };

  const successToast = (text: string) => {
    sonnerToast.success(tu(`info.${text}`));
  };

  return {
    errorToast,
    toast,
    successToast,
    promiseToast: sonnerToast.promise,
  };
};
