import { useEffect, useMemo, useState } from 'react';

import { updatePhoto } from 'api/items.api';
import { ItemPhoto } from 'api/items.types';
import { arrayMove } from 'revibe-ui';
import Gallery from 'react-photo-gallery';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { Photo, PhotoProps } from 'shared/components/Photo';
import { useToast } from 'shared/hooks';

type Props = {
  itemID: string | null;
  photos: ItemPhoto[];
  lockedPhotos: ItemPhoto[];
  onUpdate: () => void;
};

const SortablePhoto = SortableElement((item: { photo: PhotoProps }) => (
  <Photo {...{ ...item.photo, isMoving: true }} />
));
const SortableGallery = SortableContainer<{ items: PhotoProps[] }>(
  ({ items }: { items: PhotoProps[] }) => (
    <Gallery
      photos={items}
      renderImage={(props: any) => (
        <SortablePhoto {...props} disabled={props.is_retouched} />
      )}
    />
  )
);

export const ItemPhotosOrder = ({
  itemID,
  lockedPhotos,
  photos,
  onUpdate,
}: Props) => {
  const { toast } = useToast();

  const [galleryPhotos, setGalleryPhotos] = useState(
    photos.map((p) => ({
      ...p,
      src: p.small_link,
      width: 1,
      height: 1,
      id: p.id,
      canBeDeleted: false,
      onDelete: () => undefined,
    }))
  );
  const lockedGalleryPhotos = useMemo(
    () =>
      lockedPhotos.map((p) => ({
        ...p,
        src: p.small_link,
        width: 1,
        height: 1,
        id: p.id,
        canBeDeleted: false,
        onDelete: () => undefined,
      })),
    [lockedPhotos]
  );

  useEffect(() => {
    setGalleryPhotos(
      photos.map((p) => ({
        ...p,
        src: p.small_link,
        width: 1,
        height: 1,
        id: p.id,
        canBeDeleted: false,
        onDelete: () => undefined,
      }))
    );
  }, [photos]);

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }
    if (!itemID) {
      return;
    }
    const newItemsOrder = arrayMove(galleryPhotos, oldIndex, newIndex);
    setGalleryPhotos(newItemsOrder);
    await Promise.all(
      newItemsOrder.map(
        async (photo, i) =>
          await updatePhoto(itemID, photo.id, {
            order: i + lockedPhotos.length,
          })
      )
    );
    toast('item-updated-text');
    onUpdate();
  };

  return (
    <div className="min-h-32 mb-4 flex">
      <Gallery
        photos={lockedGalleryPhotos}
        renderImage={(props: any) => <Photo {...props.photo} />}
      />
      <SortableGallery
        distance={1}
        items={galleryPhotos}
        onSortEnd={onSortEnd}
        axis={'xy'}
      />
    </div>
  );
};
