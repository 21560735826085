import React, { useEffect, useState } from 'react';

import { getSellerOrders } from 'api/sellers.api';
import { useTranslation } from 'i18n/hooks';
import { SellerOrder } from 'revibe-api';
import { Button, SpacedContainer, Spinner, Table, Tag } from 'revibe-ui';
import { SELLER_ROUTES } from 'router';

import { NoSellerOrders } from 'modules/orders/components';
import { SELLER_ORDER_STATUS } from 'modules/orders/utils';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useBoolean, useHistory, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';
import { formatPrice } from 'shared/utils';
import { getDynamicRouteUrl } from 'shared/utils/routes';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

export const PastOrdersPage = () => {
  const history = useHistory();
  const { tu } = useTranslation('orders');
  const { sellerID } = useSeller();
  const [orders, setOrders] = useState<SellerOrder[]>([]);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { errorToast } = useToast();
  const fetchOrders = async () => {
    startFetching();
    const { data, error } = await getSellerOrders(sellerID);
    if (data) {
      setOrders(data.res);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <LoggedInPage
        title={tu('paid-heading')}
        breadcrumbs={[
          {
            label: tu('paid-heading'),
            to: SELLER_ROUTES.ORDERS_PAST,
          },
        ]}
      >
        <SpacedContainer>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  const paidOutOrders = orders.filter(
    (order) => order.status === SELLER_ORDER_STATUS.PAID_OUT
  );

  return (
    <LoggedInPage
      title={tu('paid-heading')}
      breadcrumbs={[
        {
          label: tu('paid-heading'),
          to: SELLER_ROUTES.ORDERS_PAST,
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{tu('paid-heading')}</ModuleHeading>
        {paidOutOrders.length !== 0 ? (
          <Table
            columns={[
              tu('date'),
              tu('status'),
              tu('total'),
              `${tu('heading-single')} ID`,
              '',
            ]}
            data={paidOutOrders.map((order) => [
              order.creation_date,
              <Tag>{tu(order.status, 'orders.sub-order-status')}</Tag>,
              `${formatPrice(order.subtotal)} €`,
              order.order_id,
              <Button
                variant="subtle"
                onClick={() =>
                  history.push(
                    getDynamicRouteUrl(SELLER_ROUTES.ORDER, {
                      id: sellerID || '',
                      orderID: order.id,
                    })
                  )
                }
              >
                {tu('details')}
              </Button>,
            ])}
          />
        ) : (
          <NoSellerOrders />
        )}
      </SpacedContainer>
    </LoggedInPage>
  );
};
