import { useEffect, useState } from 'react';

import { Collection } from 'api/collections.types';
import { getSellerCollections } from 'api/sellers.api';
import { useTranslation } from 'i18n/hooks';
import { Button, SpacedContainer, Table, Tag } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useHistory, useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';
import { getDynamicRouteUrl } from 'shared/utils';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import { NoData } from 'ui/components/Layout/NoData';

export const CollectionsPage = () => {
  const { sellerID } = useSeller();
  const { tu } = useTranslation('collections');
  const [collections, setCollections] = useState<Collection[]>([]);
  const { errorToast } = useToast();
  const history = useHistory();

  const fetchCollections = async () => {
    const { data, error } = await getSellerCollections(sellerID);
    if (data) {
      setCollections(data);
    } else {
      errorToast(error);
    }
  };

  useEffect(() => {
    fetchCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoggedInPage
      title={tu('collections-heading')}
      breadcrumbs={[
        {
          label: tu('collections-heading'),
          to: SELLER_ROUTES.COLLECTIONS,
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{tu('collections-heading')}</ModuleHeading>
        {collections.length === 0 && (
          <NoData
            heading={tu('empty-heading')}
            text={tu('empty-text')}
            closeButton={
              <Button
                onClick={() => history.push(SELLER_ROUTES.COLLECTION_NEW)}
              >
                {tu('create-a-collection')}
              </Button>
            }
          />
        )}
        {collections.length > 0 && (
          <>
            <Button onClick={() => history.push(SELLER_ROUTES.COLLECTION_NEW)}>
              {tu('create-a-collection')}
            </Button>
            <Table
              columns={[
                'Status',
                tu('name-label'),
                tu('release-date-label'),
                '',
                '',
              ]}
              data={collections.map((collection) => [
                <SpacedContainer type="horizontal">
                  <Tag
                    size="small"
                    color={collection.is_published ? 'green' : 'red'}
                  >
                    {collection.is_published ? tu('published') : tu('in-draft')}
                  </Tag>
                </SpacedContainer>,
                collection.name,
                collection.date,
                <Button
                  variant="subtle"
                  onClick={() =>
                    history.push(
                      getDynamicRouteUrl(SELLER_ROUTES.COLLECTION, {
                        id: sellerID || '',
                        collection: collection.id,
                      })
                    )
                  }
                >
                  {tu('details', 'shared')}
                </Button>,
                <Button
                  variant="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.pushBlank(
                      `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}/shop/collections/${collection.slug}?sellerView=true`
                    );
                  }}
                >
                  {tu('preview', 'shared')}
                </Button>,
              ])}
            />
          </>
        )}
      </SpacedContainer>
    </LoggedInPage>
  );
};
