import { Address } from '../types/addresses.types';
import { Seller, SellerUpdate } from '../types/sellers.types';
import { apiCall } from '../utils/apiCall';

export const updateSellerAddress = async ({
  sellerID,
  address,
}: {
  sellerID: number | string;
  address: Address;
}) => {
  return await apiCall({
    endpoint: `sellers/${sellerID}/address`,
    method: 'PUT',
    body: address,
    reshaper: (res: Address) => res,
  });
};

export const updateSeller = async ({
  seller,
  sellerID,
}: {
  sellerID: string | number;
  seller: SellerUpdate;
}) => {
  return await apiCall({
    endpoint: `sellers/${sellerID}`,
    method: 'PUT',
    body: seller,
    reshaper: (data: Seller) => data,
  });
};
