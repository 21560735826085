import { SellerOrder } from 'revibe-api';

import { Collection } from './collections.types';
import { PaginationResponse } from './pagination.types';
import { Seller, SellerCreation, SellerUpdate } from './sellers.types';
import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const getSeller = async (sellerID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}?isInternal=true`,
    method: 'GET',
    reshaper: (data: Seller) => data,
  });

export const getSellerCollections = async (sellerID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/collections`,
    method: 'GET',
    reshaper: (res: PaginationResponse<Collection>) => res.res,
  });

export const getSellerOrders = async (sellerID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/orders`,
    method: 'GET',
    reshaper: (res: PaginationResponse<SellerOrder>) => res,
  });

export const getSellerOrder = async (sellerID: string, orderID: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/orders/${orderID}`,
    method: 'GET',
    reshaper: (res: SellerOrder) => res,
  });

export const checkSellerExistance = async (email: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/check/${email}`,
    method: 'GET',
    reshaper: (data: { exists: boolean }) => data,
  });

export const updateSellerLogo = async (
  sellerID: string | number,
  logo: File
) => {
  const data = new FormData();
  data.append('files', logo);
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}/logo`,
    method: 'POST',
    body: data,
    reshaper: (data: any) => data.res,
    rawBody: true,
  });
};

export const createNewSellerAccount = async (seller: SellerCreation) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers`,
    method: 'POST',
    body: seller,
    reshaper: (data: Seller) => data,
  });
};

export const updateSeller = async (
  sellerID: string | number,
  seller: SellerUpdate
) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/${sellerID}`,
    method: 'PUT',
    body: seller,
    reshaper: (data: Seller) => data,
  });
};

export const refreshSellerOnboarding = async (id: string) => {
  return await apiCall({
    endpoint: `${REVIBE_API_URL}/sellers/stripe/onboard/${id}`,
    method: 'GET',
    reshaper: (data: { url: string }) => data,
  });
};
