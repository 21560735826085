import { useTranslation as useI18nTranslation } from 'react-i18next';

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const useTranslation = (prefix = '') => {
  const { t: translate, i18n } = useI18nTranslation();

  const t = (s: string, forcedPrefix = '') => {
    if (typeof s !== 'string') {
      return '';
    }
    if (s.indexOf(' ') >= 0) {
      return s;
    }
    const p = forcedPrefix || prefix;
    return p ? translate(`${p.toLowerCase()}.${s}`) : translate(s);
  };
  const tu = (s: string, forcedPrefix = '') =>
    capitalizeFirstLetter(t(s, forcedPrefix));

  return { t, tu, i18n, language: i18n.language };
};
