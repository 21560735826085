import { useTranslation } from 'i18n/hooks';
import { getCategories, useQuery } from 'revibe-api';

import { useToast } from 'shared/hooks';

export const useCatalogCategories = (
  group?: 'clothing' | 'accessories-more'
) => {
  const { tu } = useTranslation('catalog');
  const { errorToast } = useToast();
  const [categories, { isLoading }] = useQuery('categories', getCategories, {
    defaultValue: [],
    onError: errorToast,
  });
  const translatedCategories = categories
    .filter((c) => !group || c.group === group)
    .map((c) => ({
      ...c,
      slug: tu(c.slug, 'catalog.categories'),
    }));

  const sortedCategories = translatedCategories.sort((a, b) =>
    a.slug.localeCompare(b.slug)
  );

  const selectCategories = sortedCategories.map((c) => ({
    value: c.id,
    label: c.slug,
  }));

  return {
    selectCategories,
    translatedCategories,
    sortedCategories,
    isLoading,
  };
};
