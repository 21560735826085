import { useTranslation } from 'i18n/hooks/useTranslation';
import { SpacedContainer, Tabs, TabsList, TabsTrigger } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { SellingSettings } from 'modules/settings/components/SellingSettings';

import { useHistory } from 'shared/hooks';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

export const SellingSettingsPage = () => {
  const { tu } = useTranslation('settings');
  const history = useHistory();
  return (
    <LoggedInPage
      title={tu('settings')}
      breadcrumbs={[
        {
          label: tu('settings'),
        },
        {
          label: tu('selling'),
        },
      ]}
    >
      <div className="relative mr-auto max-w-4xl md:px-8 xl:px-0">
        <SpacedContainer spacing="large">
          <Tabs defaultValue="selling">
            <TabsList>
              <TabsTrigger
                onClick={() => history.push(SELLER_ROUTES.SETTINGS)}
                value="general"
              >
                {tu('general')}
              </TabsTrigger>
              <TabsTrigger
                value="selling"
                onClick={() => history.push(SELLER_ROUTES.SETTINGS_SELL)}
              >
                {tu('selling')}
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <SellingSettings />
        </SpacedContainer>
      </div>
    </LoggedInPage>
  );
};
