import { useContext } from 'react';

import { SellerContext } from 'auth/components/SellerProvider';

export const useSeller = () => {
  const sellerContext = useContext(SellerContext);

  return {
    ...sellerContext,
    refresh: sellerContext.refetchSeller,
    isLoading: false,
  };
};
