export const ITEM_FILTERS = {
  STATUS: 'status',
  INCLUDE_OUT_OF_STOCK: 'includeOutOfStock',
  IN_PREORDER: 'inPreorder',
  SELLER: 'seller',
  SELLER_NAME: 'sellerName',
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  SIZE: 'size',
  SEX: 'sex',
  SEARCH: 'search',
  LIST: 'list',
  CATEGORY: 'category',
  CATEGORY_NAME: 'categoryName',
  NOT_VERIFIED_ITEMS: 'notVerifiedItems',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  ORDER: 'order',
  USER: 'userID',
  ONLY_FAVORITES: 'onlyFavorites',
  ONLY_SALES: 'onlySales',
  COLLECTION: 'collection',
  ONLY_NOT_IN_COLLECTION: 'notCollection',
  INCLUDE_UNRELEASED_COLLECTIONS: 'unreleasedCollections',
};
