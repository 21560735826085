import { useEffect } from 'react';

import { useAuthentication } from 'auth/hooks/useAuthentication';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setAdminSession } from 'redux/features/adminSession';
import { Button, FormControl, Input, SpacedContainer } from 'revibe-ui';

import { useQueryStringParameters } from 'shared/hooks';

import { Page } from 'ui/components/Layout/Page';

export const AdminSessionLoginPage = () => {
  const { loginAdminSession } = useAuthentication();
  const query = useQueryStringParameters();
  const sellerName = query.get('sellerName') || '';
  const sellerID = query.get('sellerID') || '';
  const adminEmail = query.get('adminEmail') || '';
  const shouldForceLogin = query.get('shouldForceLogin') || false;
  const redirect = decodeURIComponent(query.get('redirect') || '');
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{
    password: string;
    email: string;
  }>({ defaultValues: { email: adminEmail } });
  const handleLogin = handleSubmit(
    async ({ password, email }) =>
      await loginAdminSession(email, password, sellerID, sellerName)
  );

  useEffect(() => {
    if (shouldForceLogin) {
      dispatch(setAdminSession({ id: adminEmail, name: adminEmail }));

      window.location.href = redirect;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldForceLogin]);

  return (
    <Page title="Admin session">
      <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="w-full">
            <img
              className="mx-auto h-12 w-auto"
              src="https://res.cloudinary.com/revibe/image/upload/v1649685438/business%20app/logo_zsvqi5.svg"
              alt="logo revibe"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Revibe for business admin access
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 lg:text-base">
              Login as {sellerName}
            </p>
          </div>
          <form className="mt-8 w-full space-y-6" onSubmit={handleLogin}>
            <SpacedContainer centered className="w-full">
              <FormControl className="w-full" label="Your admin email">
                <Input {...register('email', { required: true })} />
              </FormControl>
              <FormControl label="Password" className="w-full">
                <Input
                  type="password"
                  {...register('password', { required: true })}
                />
              </FormControl>
              <Button className="w-full" type="submit" isLoading={isSubmitting}>
                Login
              </Button>
            </SpacedContainer>
          </form>
        </div>
      </div>
    </Page>
  );
};
