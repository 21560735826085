import { CirclePicker } from 'react-color';

import { COLORS } from './colors';

interface Props {
  hiddenColors?: string[];
  onSelect: (colorHEX: string) => void;
}

export const ColorPicker = ({ onSelect, hiddenColors = [] }: Props) => {
  return (
    <CirclePicker
      colors={COLORS.filter((el) => hiddenColors.indexOf(el) === -1)}
      width={`${window.parent}px`}
      onChangeComplete={(color) => onSelect(color.hex)}
    />
  );
};
