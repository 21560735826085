import { OrderItemUpdate } from '../types/items.types';
import { MarketplaceOrder } from '../types/orders.types';
import { apiCall } from '../utils/apiCall';

export const updateOrderItem = async (
  orderItemID: string,
  update: OrderItemUpdate
) =>
  apiCall({
    endpoint: `orders/items/${orderItemID}`,
    method: 'PUT',
    body: update,
    reshaper: (data: MarketplaceOrder) => data,
  });
