import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { useHistory } from 'shared/hooks';

import { Footer } from 'ui/components/Layout/Footer';

export const Page404 = () => {
  const { tu } = useTranslation('auth');
  const history = useHistory();
  return (
    <>
      <div className="h-screen">
        <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-purple text-4xl font-extrabold sm:text-5xl">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                    {tu('404.heading')}
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    {tu('404.text')}
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Button
                    onClick={() => history.push(SELLER_ROUTES.HOME)}
                    className="bg-purple focus:ring-purple inline-flex items-center border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-offset-2"
                  >
                    {tu('404.cta-home')}
                  </Button>
                  <Button
                    variant="subtle"
                    className="text-purple hover:purple-100 focus:ring-purple inline-flex items-center border border-transparent bg-purple-100 px-4 py-2 text-sm font-medium focus:outline-none focus:ring-1 focus:ring-offset-2"
                  >
                    {tu('404.cta-support')}
                  </Button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
