import { useTranslation } from 'i18n/hooks';

export const PasswordRequirements = () => {
  const { tu } = useTranslation('auth');

  return (
    <ul className="list-none text-xs text-gray-400">
      <li>{tu('password-requirement1')}</li>
      <li>{tu('password-requirement2')}</li>
      <li>{tu('password-requirement3')}</li>
    </ul>
  );
};
