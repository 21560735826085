import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';

import en from './en';
import fr from './fr';
import it from './it';

registerLocale('fr', frLocale);
registerLocale('en', enLocale);
registerLocale('it', itLocale);

const languageDetector = new LanguageDetector(null, {
  lookupQuerystring: 'lng',
});

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
