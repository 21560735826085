import { persistStore } from 'redux-persist';

import { configureStore } from '@reduxjs/toolkit';

import { adminSessionReducer } from './features/adminSession';

export const store = configureStore({
  reducer: {
    adminSession: adminSessionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
