import { useState } from 'react';
import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import { CSVRecordsReview } from '../components/CSVRecordsReview';
import { CSVUploadForm } from '../components/CSVUploadForm';
import { CSVParseResult } from '../types/csv.types';

export const CSVImportPage = () => {
  const [csvData, setCSVData] = useState<CSVParseResult | null>(null);

  return (
    <LoggedInPage
      title="CSV Import"
      breadcrumbs={[
        {
          label: 'CSV Import',
        },
      ]}
    >
      {csvData ? (
        <CSVRecordsReview csvData={csvData} />
      ) : (
        <CSVUploadForm
          onSuccess={(data) => {
            setCSVData(data);
          }}
        />
      )}
    </LoggedInPage>
  );
};
