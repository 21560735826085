import React, { useCallback, useEffect } from 'react';

import { getSeller, refreshSellerOnboarding } from 'api/sellers.api';
import { useAuthentication } from 'auth/hooks/useAuthentication';
import { useQuery } from 'revibe-api';

import { useToast } from 'shared/hooks';

import { Page } from 'ui/components/Layout/Page';

export const StripeConnectedAccountSetupRefreshPage = () => {
  const { errorToast } = useToast();
  const { userID, logout } = useAuthentication();

  const [seller] = useQuery(
    `SELLER=${userID}`,
    async () => {
      return userID
        ? await getSeller(userID)
        : { data: null, error: 'not-found', status: 404 };
    },
    {
      defaultValue: null,
      enabled: !!userID,
      onError: (error) => {
        errorToast(error);
        logout();
      },
    }
  );

  const handleRefreshRequest = useCallback(async () => {
    const stripeSellerAccountID =
      localStorage.getItem('StripeSellerAccountID') ||
      seller?.stripe_account_id ||
      null;
    if (stripeSellerAccountID === null) {
      return errorToast('no-onboarding-found');
    }
    const { data, error } = await refreshSellerOnboarding(
      stripeSellerAccountID
    );
    if (data) {
      window.location.assign(data.url);
    } else {
      errorToast(error);
    }
  }, [errorToast, seller?.stripe_account_id]);
  useEffect(() => {
    handleRefreshRequest();
  }, [handleRefreshRequest, seller]);

  return (
    <Page title="Seller onboarding redirect">
      <div className="flex w-screen items-center justify-center bg-purple-100 sm:h-screen">
        <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8">
            <div className="w-full">
              <p>Redirecting you to the Stripe Connected Account setup...</p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
