import { useState } from 'react';

import { ItemPhoto } from 'api/items.types';
import { arrayRemove } from 'revibe-ui';
import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { ErrorBanner, SpacedContainer } from 'revibe-ui';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { ItemFormField } from 'modules/catalog/components/ItemForm/ItemFormField';
import { ITEM_PHOTOS_ERRORS } from 'modules/catalog/utils/itemPhotos';

type Props = {
  onValueChanged: (values: File[]) => void;
  existingPhotos: ItemPhoto[];
  validationErrors: string[];
};

export const ItemPhotosConfigurator = ({
  onValueChanged,
  existingPhotos,
  validationErrors,
}: Props) => {
  const { tu, t } = useTranslation('photos');
  const [images, setImages] = useState<
    {
      name: string;
      src: string;
    }[]
  >([]);
  const [files, setFiles] = useState<File[]>([]);
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    maxSize: 1500000,
    disabled: existingPhotos.length + images.length >= 5,
    maxFiles: 5 - images.length - existingPhotos.length,
    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
      onValueChanged([...files, ...acceptedFiles]);
      setImages([
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            src: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const hasFilesTooBig =
    fileRejections.findIndex(({ errors }) =>
      errors.find((error) => error.code === 'file-too-large')
    ) !== -1;

  const tooManyFiles =
    fileRejections.findIndex(({ errors }) =>
      errors.find((error) => error.code === 'too-many-files')
    ) !== -1;

  const handleItemPhotoToUploadRemoved = async (i: number) => {
    setFiles(arrayRemove(files, i));
    onValueChanged(arrayRemove(files, i));
    setImages(arrayRemove(images, i));
  };

  return (
    <SpacedContainer>
      <ItemFormField label={tu('add-photos')}>
        <SpacedContainer className="max-w-lg">
          <section className="flex max-w-lg justify-center rounded-md border border-dashed border-gray-200 px-6 pt-5 pb-6">
            <div
              {...getRootProps({
                className: 'space-y-1 text-center dropzone w-full',
              })}
            >
              {images.length > 0 && (
                <div className="mx-auto mb-4 flex w-fit flex-wrap gap-4">
                  {images.map((image, i) => (
                    <span
                      className="relative h-[96px] w-[96px]"
                      key={image.name + i}
                    >
                      <img
                        className="hover:ring-purple h-[96px] w-[96px] rounded-md ring-1 ring-gray-200 ring-offset-2 focus:outline-none"
                        key={image.name}
                        src={image.src}
                        alt="seller img"
                      />
                      <span
                        className="absolute inset-0 flex h-[96px] w-[96px] cursor-pointer items-center justify-center rounded-md bg-red-500 bg-opacity-75 text-sm font-medium text-white opacity-0 transition-opacity focus-within:opacity-100 hover:opacity-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleItemPhotoToUploadRemoved(i);
                        }}
                      >
                        <XMarkIcon className="h-5 w-5 stroke-white" />
                      </span>
                    </span>
                  ))}
                </div>
              )}
              {existingPhotos.length + images.length >= 5 ? null : (
                <>
                  <svg
                    className="mx-auto h-8 w-8 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="mx-auto flex w-full justify-center text-sm text-gray-600">
                    <div className="text-purple relative cursor-pointer rounded-md bg-white font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-500 focus-within:ring-offset-2 hover:text-purple-800">
                      <span>{tu('upload')}</span>
                      <input
                        {...getInputProps()}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className={cn('dropzone w-full')}
                      />
                    </div>
                    <p className="pl-1">{t('drag-drop')}</p>
                  </div>
                  <p className="text-xs text-gray-500">{tu('format')}</p>
                </>
              )}
            </div>
          </section>
          {validationErrors.map((error) => (
            <ErrorBanner key={error}>{tu(error, 'photos.items')}</ErrorBanner>
          ))}
          {hasFilesTooBig && (
            <ErrorBanner>
              {tu(ITEM_PHOTOS_ERRORS.TOO_BIG, 'photos.items')}
              <br />
              <br />
              <span>
                {tu('items.item-photo-too-big-tip')}{' '}
                <Link to="https://www.reduceimages.com/" target="_blank">
                  https://www.reduceimages.com/
                </Link>
              </span>
            </ErrorBanner>
          )}
          {tooManyFiles && (
            <ErrorBanner>
              {tu(ITEM_PHOTOS_ERRORS.TOO_MANY, 'photos.items')}
            </ErrorBanner>
          )}
        </SpacedContainer>
      </ItemFormField>
    </SpacedContainer>
  );
};
