import { useEffect, useState } from 'react';

import { getItems } from 'api/items.api';
import { useAuthentication } from 'auth/hooks/useAuthentication';
import { useTranslation } from 'i18n/hooks';
import { Item, ItemFilters } from 'revibe-api';
import { Input, MultiButton, SpacedContainer, Table, Tag } from 'revibe-ui';

import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { getIsItemInStock } from 'modules/catalog/utils/itemStock';

import { useBoolean, useToast } from 'shared/hooks';

import { NoData } from 'ui/components/Layout/NoData';

type Props = {
  filters?: ItemFilters;
  resetToken?: string;
  onItemsLoaded?: (items: Item[], itemsCount: number) => void;
  onItemSelected?: (item: Item | null) => void;
  mainAction: {
    label: string;
    onClick: (item: Item) => void;
  };
  secondaryActions?: {
    label: string;
    onClick: (item: Item) => void;
  }[];
};

export const ItemsTable = ({
  filters = {},
  resetToken = '',
  onItemsLoaded = () => {},
  mainAction,
  secondaryActions = [],
}: Props) => {
  const { tu } = useTranslation('catalog');
  const { errorToast } = useToast();
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { userID } = useAuthentication();
  const [search, setSearch] = useState('');
  const [items, setItems] = useState<Item[]>([]);
  const userFilters = {
    ...filters,
    [ITEM_FILTERS.USER]: userID,
    [ITEM_FILTERS.INCLUDE_UNRELEASED_COLLECTIONS]: true,
  };
  const columns = [
    tu('name', 'shared'),
    tu('photo', 'shared'),
    tu('category-label'),
    '',
    '',
  ];
  const rows = items
    .filter((item) => {
      if (!search) {
        return true;
      }
      const lowercaseSearch = search.toLowerCase();
      return (
        item.name.toLowerCase().includes(lowercaseSearch) ||
        tu(item.category.slug, 'categories')
          .toLowerCase()
          .includes(lowercaseSearch) ||
        tu(item.sex || 'unisex', 'shared')
          .toLowerCase()
          .includes(lowercaseSearch)
      );
    })
    .map((item) => [
      item.name,
      Array.isArray(item.photos) && item.photos.length > 0 ? (
        <div className="flex items-center">
          <img
            className="h-8 w-8 rounded-md"
            src={item.photos[0].mini_link}
            alt={item.photos[0].id}
          />
        </div>
      ) : null,
      tu(`categories.${item.category.slug}`),
      <SpacedContainer spacing="small">
        {!getIsItemInStock(item.stock) && (
          <Tag size="small" color="red">
            {tu('out-of-stock')}
          </Tag>
        )}
      </SpacedContainer>,
      <MultiButton
        mainAction={{
          label: mainAction.label,
          onClick: () => mainAction.onClick(item),
        }}
        secondaryActions={secondaryActions.map((secondaryAction) => ({
          label: secondaryAction.label,
          onClick: () => secondaryAction.onClick(item),
        }))}
      />,
    ]);
  const fetchPage = async (isReset = false) => {
    startFetching();
    const { data, error } = await getItems(userFilters);
    if (data) {
      const newItems = isReset ? data.res : [...items, ...data.res];
      setItems(newItems);
      onItemsLoaded(newItems, data.res.length);
    } else {
      errorToast(error);
    }
    stopFetching();
  };

  useEffect(() => {
    fetchPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToken]);

  if (items.length === 0) {
    return <NoData heading={tu('no-items')} text="" />;
  }

  return (
    <SpacedContainer>
      <Input
        className="max-w-lg"
        value={search}
        onChange={(e) => setSearch(e.currentTarget.value)}
        placeholder={tu('search', 'shared')}
      />
      <Table
        maxRows={25}
        isLoading={isFetching}
        columns={columns}
        data={rows}
      />
    </SpacedContainer>
  );
};
