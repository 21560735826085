import React from 'react';

import { useTranslation } from 'i18n/hooks';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectAdminSessionId } from 'redux/features/adminSession';
import { getSeller, useQuery } from 'revibe-api';
import { Spinner } from 'revibe-ui';
import { AUTH_ROUTES } from 'router';

import { useToast } from 'shared/hooks';

import { useAuthentication } from '../hooks/useAuthentication';
import { SellerProvider } from './SellerProvider';

type Props = RouteProps & {
  Component: React.ComponentType<any>;
  shouldNotRedirect?: boolean;
};

export const LoggedInRoute = (loggedInRouteProps: Props) => {
  const { isLoggedIn } = useAuthentication();
  const { tu } = useTranslation('shared');
  const adminSessionId = useSelector(selectAdminSessionId);
  const { errorToast } = useToast();
  const { userID, logout } = useAuthentication();
  let id = adminSessionId;
  if (userID) {
    id = userID;
  }

  const [seller, { isLoading, refetch }] = useQuery(
    'seller',
    async () => {
      return id
        ? await getSeller(id)
        : { data: null, error: 'not-found', status: 404 };
    },
    {
      defaultValue: null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!id,
      onError: (error) => {
        errorToast(error);
        logout();
      },
    }
  );

  return (
    <Route
      {...loggedInRouteProps}
      render={(props) => {
        // USER NOT LOGGED IN: REDIRECT TO LOGIN
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: AUTH_ROUTES.LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }

        // USER LOADING: SHOW SPINNER
        if (isLoading || !seller) {
          return (
            <div className="flex h-screen w-screen items-center justify-center bg-purple-100">
              <div className="flex-col items-center justify-center">
                <div className="mx-auto w-fit">
                  <Spinner />
                </div>
                <p className="text-purple">{tu('loading')}</p>
              </div>
            </div>
          );
        }

        if (loggedInRouteProps.shouldNotRedirect) {
          return (
            <SellerProvider seller={seller} refetchSeller={refetch}>
              <loggedInRouteProps.Component {...props} />
            </SellerProvider>
          );
        }

        return (
          <SellerProvider seller={seller} refetchSeller={refetch}>
            <loggedInRouteProps.Component {...props} />
          </SellerProvider>
        );
      }}
    />
  );
};
