import { useState } from 'react';
import { apiCall, useMutation } from 'revibe-api';
import {
  Button,
  FilesUploader,
  FormControl,
  InfoBanner,
  Link,
  SpacedContainer,
} from 'revibe-ui';
import { useSeller, useToast } from 'shared/hooks';
import { CSVSteps } from './CSVSteps';
import { CSVParseResult } from '../types/csv.types';

interface Props {
  onSuccess: (data: CSVParseResult) => void;
}

export const CSVUploadForm = ({ onSuccess }: Props) => {
  const { sellerID } = useSeller();
  const [files, setFiles] = useState<File[]>([]);
  const { errorToast } = useToast();
  const [parseCSVFile, { isLoading }] = useMutation(
    'parse-csv-file',
    async ({ files }: { files: File[] }) => {
      const data = new FormData();
      data.append(
        'config',
        JSON.stringify({
          sellerID: Number(sellerID),
        })
      );
      files.forEach((f) => data.append('files', f));

      return await apiCall({
        endpoint: 'business/catalog/csv/parse',
        method: 'POST',
        body: data,
        reshaper: (res: { result: CSVParseResult }) => res.result,
        rawBody: true,
      });
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: errorToast,
    }
  );

  return (
    <SpacedContainer className="mx-auto w-full max-w-3xl" spacing="large">
      <CSVSteps
        steps={[
          {
            id: 'Step 1',
            name: 'Upload CSV File',
            href: '#',
            status: 'current',
          },
          { id: 'Step 2', name: 'Import', href: '#', status: 'upcoming' },
          { id: 'Step 3', name: 'Done!', href: '#', status: 'upcoming' },
        ]}
      />
      <InfoBanner>
        Upload your CSV using the Revibe template. Download{' '}
        <Link to="https://revibe-backend-files.s3.eu-west-3.amazonaws.com/Revibe+CSV+Catalog+Template.csv">
          here
        </Link>
      </InfoBanner>
      <div className="boorder-gray-200 overflow-hidden rounded-md border">
        <div className="border-b border-gray-200 bg-gray-50 px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Import products from a CSV file
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            This tool allows you to import product data to your catalog from a
            CSV file.
          </p>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <FormControl label="Choose a CSV file from your computer:">
            <FilesUploader
              accept={{ 'application/csv': ['.csv', '.CSV'] }}
              maxFiles={1}
              onUpdate={setFiles}
            />
          </FormControl>
        </div>
        <div className="flex justify-end border-t border-gray-200  bg-gray-50 px-4 py-5 sm:px-6">
          <Button
            isLoading={isLoading}
            disabled={files.length === 0}
            onClick={async () => {
              if (files.length > 0) {
                await parseCSVFile({ files: [files[0]] });
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </SpacedContainer>
  );
};
