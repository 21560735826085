import { ReactComponent as AddSvg } from './add.svg';
import { ReactComponent as CollectionsSvg } from './collections.svg';
import { ReactComponent as EmptySvg } from './empty.svg';
import { ReactComponent as FacebookSvg } from './facebook.svg';
import { ReactComponent as FiltersSvg } from './filters.svg';
import { ReactComponent as HomeSvg } from './home.svg';
import { ReactComponent as InstagramSvg } from './instagram.svg';
import { ReactComponent as ItemsSvg } from './items.svg';
import { ReactComponent as LogoSvg } from './logo.svg';
import { ReactComponent as MenuSvg } from './menu.svg';
import { ReactComponent as OrdersSvg } from './orders.svg';
import { ReactComponent as WorldSvg } from './world.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const Logo = ({ className, onClick }: Props) => (
  <LogoSvg className={className} onClick={onClick} />
);

export const EmptyIcon = ({ className, onClick }: Props) => (
  <EmptySvg className={className} onClick={onClick} />
);

export const MenuIcon = ({ className, onClick }: Props) => (
  <MenuSvg className={className} onClick={onClick} />
);

export const AddIcon = ({ className, onClick }: Props) => (
  <AddSvg className={className} onClick={onClick} />
);

export const InstagramIcon = ({ className, onClick }: Props) => (
  <InstagramSvg className={className} onClick={onClick} />
);

export const FacebookIcon = ({ className, onClick }: Props) => (
  <FacebookSvg className={className} onClick={onClick} />
);

export const ItemsIcon = ({ className, onClick }: Props) => (
  <ItemsSvg className={className} onClick={onClick} />
);

export const OrdersIcon = ({ className, onClick }: Props) => (
  <OrdersSvg className={className} onClick={onClick} />
);

export const FiltersIcon = ({ className, onClick }: Props) => (
  <FiltersSvg className={className} onClick={onClick} />
);

export const CollectionsIcon = ({ className, onClick }: Props) => (
  <CollectionsSvg className={className} onClick={onClick} />
);

export const WorldIcon = ({ className, onClick }: Props) => (
  <WorldSvg className={className} onClick={onClick} />
);

export const HomeIcon = ({ className, onClick }: Props) => (
  <HomeSvg className={className} onClick={onClick} />
);
