import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SELLER_ROUTES } from 'routes';

import { useAuthentication } from '../hooks/useAuthentication';

type Props = RouteProps & {
  Component: React.ComponentType<any>;
  shouldNotRedirect?: boolean;
};

export const LoggedOutRoute = (loggedOutRouteProps: Props) => {
  const { isLoggedIn } = useAuthentication();

  return (
    <Route
      {...loggedOutRouteProps}
      render={(props) => {
        if (loggedOutRouteProps.shouldNotRedirect) {
          return <loggedOutRouteProps.Component {...props} />;
        }

        if (!isLoggedIn) {
          return <loggedOutRouteProps.Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: SELLER_ROUTES.HOME,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
