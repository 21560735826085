import { useState } from 'react';

import { createNewSellerAccount } from 'api/sellers.api';
import { SellerCreation } from 'api/sellers.types';
import { PasswordRequirements } from 'auth/components/PasswordRequirements';
import { useTranslation } from 'i18n/hooks';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  ErrorBanner,
  FormControl,
  Input,
  Label,
  Drawer,
  SpacedContainer,
} from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useBoolean, useHistory } from 'shared/hooks';

import { OnboardingTermsAndCondition } from './OnboardingTermsAndConditions';

const schema = yup
  .object({
    email: yup.string().required(),
    name: yup.string().required(),
    isContractAccepted: yup.boolean().required().isTrue(),
    password: yup
      .string()
      .required('')
      .min(8)
      .max(30)
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, ''),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'error-match'),
  })
  .required();

export const OnboardingAccountCreation = () => {
  const { tu } = useTranslation('onboarding.account-info');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const [isTCDrawerOpen, openTCDrawer, closeTCDrawer] = useBoolean();
  const handleSellerCreation = async (accountInfo: SellerCreation) => {
    const { data, error } = await createNewSellerAccount(accountInfo);
    if (data) {
      history.push(SELLER_ROUTES.ONBOARDING_SUCCESS);
    } else if (error) {
      return setErrorMessage(error);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      password_confirmation: '',
      isContractAccepted: false,
    },
    resolver: yupResolver(schema),
  });

  return (
    <div className="box-border bg-purple-100 py-8 px-4 sm:py-14">
      <SpacedContainer className="mx-auto box-border min-h-screen w-full max-w-4xl ">
        <ModuleHeading>{tu('heading')}</ModuleHeading>

        <div className="mt-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {tu('heading')}
                </h3>
                <p className="mt-1 text-sm text-gray-600">{tu('subheading')}</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={handleSubmit(handleSellerCreation)}>
                <div className="sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    {errorMessage && (
                      <ErrorBanner>{tu(errorMessage)}</ErrorBanner>
                    )}
                    {formErrors.password_confirmation &&
                      formErrors.password_confirmation.message?.trim() && (
                        <ErrorBanner>
                          {tu(formErrors.password_confirmation.message)}
                        </ErrorBanner>
                      )}
                    <FormControl label={tu('email-label')}>
                      <Input
                        {...register('email', { required: true })}
                        type="email"
                      />
                    </FormControl>
                    <FormControl label={tu('name-label')}>
                      <Input {...register('name', { required: true })} />
                    </FormControl>
                    <FormControl
                      label={tu('password-label')}
                      sublabel={<PasswordRequirements />}
                    >
                      <Input
                        {...register('password', { required: true })}
                        id="password"
                      />
                    </FormControl>
                    <FormControl label={tu('password-confirmation-label')}>
                      <Input
                        type="password"
                        {...register('password_confirmation', {
                          required: true,
                        })}
                      />
                    </FormControl>
                    <div className="space-y-2">
                      <p
                        className="text-purple cursor-pointer text-sm font-semibold"
                        onClick={openTCDrawer}
                      >
                        {tu('subheading', 'onboarding.contract')}
                      </p>
                      <Drawer
                        scrollable
                        open={isTCDrawerOpen}
                        size="xl"
                        className="p-4"
                        onOpenChange={closeTCDrawer}
                      >
                        <OnboardingTermsAndCondition />
                      </Drawer>
                      <div className="flex items-center space-x-2">
                        <Controller
                          name="isContractAccepted"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              id="isContractAccepted"
                              value={undefined}
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          )}
                        />
                        <Label htmlFor="isContractAccepted">
                          {tu('is-accepted-label', 'onboarding.contract')}
                        </Label>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <Button type="submit" isLoading={isSubmitting}>
                      {tu('cta')}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SpacedContainer>
    </div>
  );
};
