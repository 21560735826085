import { useContext } from 'react';

import { useDispatch } from 'react-redux';
import { setAdminSession } from 'redux/features/adminSession';
import { SELLER_ROUTES } from 'routes';

import { useHistory } from 'shared/hooks';

import { AuthContext } from '../components/AuthProvider';

export const useAuthentication = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const logout = async () => {
    await authContext.logout();
    history.push('/');
  };
  const dispatch = useDispatch();

  return {
    userID: authContext.userID,
    isLoading: authContext.isLoading,
    isLoggedIn: authContext.userID !== null,
    login: async (
      email: string,
      password: string,
      isPermanentLogin: boolean,
      from?: string
    ) => {
      if (from) {
        return authContext.login(email, password, isPermanentLogin, () =>
          history.replace(from)
        );
      }
      return authContext.login(email, password, isPermanentLogin);
    },
    loginAdminSession: async (
      email: string,
      password: string,
      sellerID: string,
      sellerName: string
    ) => {
      return authContext.loginAdminSession(email, password, sellerID, () => {
        history.replace(SELLER_ROUTES.HOME);
        dispatch(setAdminSession({ id: sellerID, name: sellerName }));
      });
    },
    logout,
  };
};
