import passwordValidator from 'password-validator';

const schema = new passwordValidator();
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(30) // Maximum length 100
  .has()
  .letters() // Must have letter
  .has()
  .digits() // Must have numbers
  .has()
  .not()
  .spaces(); // Should not have spaces

export const validatePasswordFormat = (password: string) =>
  schema.validate(password);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (
  password: string,
  password_confirmation: string
) => {
  const isPasswordValid = validatePasswordFormat(password);
  const arePasswordsMatching = password === password_confirmation;
  const errors: string[] = [];
  if (!isPasswordValid) {
    errors.push('invalid-password');
  }
  if (!arePasswordsMatching) {
    errors.push('passwords-mismatch');
  }
  return errors;
};
