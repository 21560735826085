import { useEffect, useState } from 'react';

import { ErrorBanner, arrayRemove } from 'revibe-ui';
import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { Controller, useForm } from 'react-hook-form';
import {
  ItemCustomFieldEdit,
  ItemEdit,
  ItemPhoto,
  ItemStock,
} from 'revibe-api';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  Label,
  Drawer,
  MultiInfoField,
  RadioGroup,
  RadioGroupItem,
  SpacedContainer,
  Textarea,
} from 'revibe-ui';

import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { ItemFormData } from 'modules/catalog/types/itemForm';
import {
  APPAREL_SIZES,
  DEFAULT_ITEM_STOCK,
  SHOES_SIZES,
} from 'modules/catalog/utils/itemStock';

import { ColorPicker } from 'shared/components/ColorPicker';
import { useBoolean, useSeller } from 'shared/hooks';

import { CategorySelect } from '../CategorySelect';
import { ItemPhotosConfigurator } from '../ItemPhotos/ItemPhotosConfigurator';
import { ItemPhotosManager } from '../ItemPhotos/ItemPhotosManager';
import { ItemFormField } from './ItemFormField';
import { ItemFormSection } from './ItemFormSection';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ITEM_VALIDATION_ERRORS } from 'modules/catalog/utils/itemValidator';

interface FormProps {
  initialItemData: ItemFormData;
  itemPhotos: ItemPhoto[];
  itemID: string | null;
  onItemPhotosUpdate: () => void;
  onSubmit: (
    itemData: ItemEdit,
    customFields: ItemCustomFieldEdit[],
    images: File[]
  ) => Promise<void>;
}

const schema: yup.ObjectSchema<ItemFormData> = yup.object().shape({
  name: yup.string().required(ITEM_VALIDATION_ERRORS.MISSING_NAME),
  description: yup
    .string()
    .required(ITEM_VALIDATION_ERRORS.MISSING_DESCRIPTION),
  category: yup
    .number()
    .positive()
    .required(ITEM_VALIDATION_ERRORS.MISSING_CATEGORY),
  price: yup.number().positive().required(ITEM_VALIDATION_ERRORS.MISSING_PRICE),
  sex: yup
    .mixed<'male' | 'female' | 'unisex'>()
    .oneOf(['male', 'female', 'unisex'])
    .required(),
  available_for_preorder: yup.boolean().required(),
  maintenance_tips: yup
    .string()
    .required(ITEM_VALIDATION_ERRORS.MISSING_MAINTENANCE),
  materials: yup.string().required(ITEM_VALIDATION_ERRORS.MISSING_MATERIALS),
  sizes_info: yup.string().required(ITEM_VALIDATION_ERRORS.MISSING_SIZES_INFO),
  traceability: yup.string().nullable(),
  stock: yup
    .object<ItemStock>()
    .shape({
      xxs: yup.number().required(),
      xs: yup.number().required(),
      s: yup.number().required(),
      m: yup.number().required(),
      l: yup.number().required(),
      xl: yup.number().required(),
      xxl: yup.number().required(),
      '3xl': yup.number().required(),
      '4xl': yup.number().defined(),
      '5xl': yup.number().defined(),
      '6xl': yup.number().defined(),
      '7xl': yup.number().defined(),
      '8xl': yup.number().defined(),
      unisize: yup.number().required(),
    })
    .required(),
  colors: yup.array().of(yup.string().required()).required(),
  custom_fields: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        multi: yup.boolean().required(),
        custom_field_values: yup.array().of(yup.string().required()).required(),
        type: yup
          .mixed<'COLOR' | 'SELECT'>()
          .oneOf(['COLOR', 'SELECT'])
          .required(),
      })
    )
    .required(),
  alternative_size_guide: yup.boolean().required(),
});

export const ItemFormManager = ({
  initialItemData,
  itemPhotos,
  itemID,
  onItemPhotosUpdate,
  onSubmit,
}: FormProps) => {
  const { tu, t } = useTranslation('catalog');
  const resolver = yupResolver(schema);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ItemFormData>({
    defaultValues: initialItemData,
    resolver,
  });

  const {
    register: registerCF,
    setValue: setValueCF,
    handleSubmit: handleSubmitCF,
    watch: watchCF,
    reset: resetCF,
  } = useForm<ItemCustomFieldEdit>({
    defaultValues: {
      type: 'SELECT',
      name: '',
      multi: false,
      custom_field_values: [],
    },
  });
  const cfValues = watchCF('custom_field_values');
  const [newCfValue, setNewCfValue] = useState('');
  const isShoes = watch('category')?.toString() === '33';
  const customFields = watch('custom_fields');
  const isInStock = watch('available_for_preorder') === false;
  const colors = watch('colors');
  const [selectedCustomFieldIndex, setSelectedCustomFieldIndex] = useState(-1);
  const [selectedCustomField, setSelectedCustomField] =
    useState<ItemCustomFieldEdit | null>(null);
  useEffect(() => {
    if (selectedCustomField) {
      resetCF(selectedCustomField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomField]);
  const [isColorDrawerOpen, openColorDrawer, closeColorDrawer] = useBoolean();
  const [
    isCustomFieldDrawerOpen,
    openCustomFieldDrawer,
    closeCustomFieldDrawer,
  ] = useBoolean();
  const { seller } = useSeller();
  const [images, setImages] = useState<File[]>([]);
  const sizes = isShoes ? SHOES_SIZES : APPAREL_SIZES;
  const sizesInputs = isInStock
    ? sizes.map((size, i) => (
        <MultiInfoField
          key={isShoes ? 36 + i : size.toUpperCase()}
          position="pre"
          info={
            <span className="w-14">
              {isShoes ? 36 + i : size.toUpperCase()}
            </span>
          }
          className="w-full"
        >
          <Input
            className="w-full"
            type="number"
            step={1}
            {...register(`stock.${size}` as any, {
              valueAsNumber: true,
              min: 0,
              max: 2000,
            })}
          />
        </MultiInfoField>
      ))
    : sizes.map((size, i) => (
        <div className="flex items-center space-x-2">
          <Controller
            name={`stock.${size}` as any}
            control={control}
            render={({ field }) => (
              <Checkbox
                className="max-w-lg"
                id={`stock.${size}`}
                checked={field.value === -1}
                onCheckedChange={(checked) =>
                  checked === true ? field.onChange(-1) : field.onChange(0)
                }
              />
            )}
          />

          <Label htmlFor={`stock.${size}`}>
            {isShoes ? size + i : size.toUpperCase()}
          </Label>
        </div>
      ));

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        const cfs: ItemCustomFieldEdit[] = [
          ...values.custom_fields.map(
            (cf) => ({ ...cf, type: 'SELECT' } as ItemCustomFieldEdit)
          ),
        ];
        if (values.colors.length > 0) {
          cfs.push({
            type: 'COLOR',
            name: 'color',
            multi: false,
            custom_field_values: values.colors,
          });
        }

        const itemData: ItemEdit = {
          name: values.name,
          description: values.description,
          category: values.category,
          price: values.price,
          available_for_preorder: values.available_for_preorder,
          maintenance_tips: values.maintenance_tips,
          materials: values.materials,
          sizes_info: values.sizes_info,
          traceability: values.traceability,
          sex: values.sex,
          alternative_size_guide: values.alternative_size_guide,
          ...values.stock,
        };

        await onSubmit(itemData, cfs, images);
      })}
    >
      <div className="mb-12 space-y-14 divide-gray-200">
        {Object.keys(errors).length > 0 && (
          <ErrorBanner>
            <ul className="list-disc pl-4">
              {Object.values(errors).map((error) => {
                return error.message ? (
                  <li>{tu(error.message, 'catalog.errors')}</li>
                ) : null;
              })}
            </ul>
          </ErrorBanner>
        )}

        <ItemFormSection
          label={tu('product-info-heading')}
          sublabel={tu('product-info-subheading')}
        >
          <ItemFormField label={tu('name-label')} htmlFor="name">
            <Input
              {...register('name')}
              error={
                errors.name?.message
                  ? tu(errors.name.message, 'catalog.errors')
                  : undefined
              }
              containerClassName="max-w-lg"
              placeholder={t('name-example')}
            />
          </ItemFormField>

          <ItemFormField label={tu('description-label')} htmlFor="name">
            <Textarea
              {...register('description')}
              error={
                errors.description?.message
                  ? tu(errors.description.message, 'catalog.errors')
                  : undefined
              }
              className="max-w-lg"
              placeholder={t('description-example')}
            />
          </ItemFormField>

          <ItemFormField label={tu('price-label')} htmlFor="price">
            <MultiInfoField className="max-w-lg" position="post" info="€">
              <Input
                {...register('price', {
                  valueAsNumber: true,
                  min: 0,
                  max: 2000,
                })}
                step={0.01}
                type="number"
                error={
                  errors.price?.message
                    ? tu(errors.price.message, 'catalog.errors')
                    : undefined
                }
                placeholder={t('price-example')}
              />
            </MultiInfoField>
          </ItemFormField>

          <ItemFormField label={tu('category-label')} htmlFor="category">
            <CategorySelect
              control={control}
              className="max-w-lg"
              name="category"
            />
          </ItemFormField>

          <ItemFormField label={tu('sex-label')} htmlFor="category">
            <Controller
              name={'sex'}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  className="mb-2 max-w-lg"
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="female" id="female" />
                    <Label htmlFor="female">
                      {tu('female', 'catalog.sex')}
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="male" id="male" />
                    <Label htmlFor="male">{tu('male', 'catalog.sex')}</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="unisex" id="unisex" />
                    <Label htmlFor="unisex">
                      {tu('unisex', 'catalog.sex')}
                    </Label>
                  </div>
                </RadioGroup>
              )}
            />
          </ItemFormField>

          <ItemFormField
            label={tu('maintenance-label')}
            htmlFor="maintenance_tips"
          >
            <Textarea
              {...register('maintenance_tips')}
              error={
                errors.maintenance_tips?.message
                  ? tu(errors.maintenance_tips.message, 'catalog.errors')
                  : undefined
              }
              className="max-w-lg"
              placeholder={tu('maintenance-example')}
            />
          </ItemFormField>

          <ItemFormField label={tu('material-label')} htmlFor="materials">
            <Textarea
              {...register('materials')}
              error={
                errors.materials?.message
                  ? tu(errors.materials.message, 'catalog.errors')
                  : undefined
              }
              className="max-w-lg"
              placeholder={tu('material-example')}
            />
          </ItemFormField>

          <ItemFormField
            label={tu('traceability-label')}
            htmlFor="traceability"
          >
            <Textarea
              {...register('traceability')}
              className="max-w-lg"
              placeholder={tu('traceability-example')}
            />
          </ItemFormField>
        </ItemFormSection>

        <ItemFormSection
          label={tu('items.section-heading', 'photos')}
          sublabel={tu('items.section-subheading', 'photos')}
        >
          {itemID ? (
            <ItemPhotosManager
              itemID={itemID}
              existingPhotos={itemPhotos}
              validationErrors={[]}
              onUpdate={onItemPhotosUpdate}
            />
          ) : (
            <ItemPhotosConfigurator
              onValueChanged={(values) => setImages(values)}
              existingPhotos={itemPhotos}
              validationErrors={[]}
            />
          )}
        </ItemFormSection>

        <ItemFormSection
          label={tu('stock.heading')}
          sublabel={tu('stock.subheading')}
        >
          <ItemFormField
            label={tu('stock.type')}
            htmlFor="available_for_preorder"
          >
            <Controller
              name={'available_for_preorder'}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  className="mb-2 max-w-lg"
                  defaultValue={
                    field.value === true ? 'made-to-order' : 'stock'
                  }
                  onValueChange={(value) => {
                    const availableForPreorder =
                      value === 'made-to-order' ? true : false;
                    field.onChange(availableForPreorder);
                    availableForPreorder ===
                    initialItemData.available_for_preorder
                      ? setValue('stock', initialItemData.stock)
                      : setValue('stock', DEFAULT_ITEM_STOCK);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="stock" id="stock" />
                    <Label htmlFor="stock">{tu('stock.type-stock')}</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="made-to-order" id="made-to-order" />
                    <Label htmlFor="made-to-order">
                      {tu('stock.type-made-to-order')}
                    </Label>
                  </div>
                </RadioGroup>
              )}
            />
          </ItemFormField>

          <ItemFormField label={tu('stock.sizes')} htmlFor="stock">
            <div className="grid max-w-lg grid-cols-1 gap-2 sm:grid-cols-2">
              <SpacedContainer spacing="small">
                {sizesInputs.slice(0, sizesInputs.length / 2 + 1)}
              </SpacedContainer>
              <SpacedContainer spacing="small">
                {sizesInputs.slice(sizesInputs.length / 2 + 1)}
              </SpacedContainer>
            </div>
          </ItemFormField>

          <ItemFormField
            label={tu('stock.detailed-info-label')}
            htmlFor="materials"
          >
            <Textarea
              error={
                errors.sizes_info?.message
                  ? tu(errors.sizes_info.message, 'catalog.errors')
                  : undefined
              }
              {...register('sizes_info')}
              className="max-w-lg"
              placeholder={tu('stock.detailed-info-example')}
            />
          </ItemFormField>

          {!seller.is_revibe_owned && (
            <ItemFormField
              label="Alternative size guide"
              htmlFor="alternative_size_guide"
            >
              <div className="flex items-center space-x-2">
                <Controller
                  name="alternative_size_guide"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className="max-w-lg"
                      id="alternative_size_guide"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  )}
                />

                <Label htmlFor="alternative_size_guide">
                  Use alternative size guide
                </Label>
              </div>
            </ItemFormField>
          )}
        </ItemFormSection>

        <ItemFormSection
          label={tu('customization.heading')}
          sublabel={tu('customization.subheading')}
        >
          <ItemFormField label={tu('customization.color.heading')}>
            <div className="flex max-w-lg flex-wrap gap-2">
              {colors?.map((color) => (
                <button
                  onClick={() =>
                    setValue(
                      'colors',
                      colors.filter((c) => c !== color)
                    )
                  }
                  type="button"
                  key={color}
                  className="relative inline-flex items-center rounded-full border border-transparent p-2 text-white shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-2"
                  style={{ backgroundColor: color }}
                >
                  <span className="h-5 w-5 " />
                  <XMarkIcon className="path-gray-300 absolute top-2 h-5 w-5 stroke-white opacity-0 hover:opacity-100" />
                </button>
              ))}
              <button
                type="button"
                onClick={openColorDrawer}
                className="inline-flex items-center rounded-full border border-transparent bg-gray-400 p-2 text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-2"
              >
                <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </ItemFormField>

          <ItemFormField label={tu('customization.select.heading')}>
            <SpacedContainer>
              {customFields.length > 0 && (
                <div className="flex w-full max-w-lg flex-wrap gap-2">
                  <div className="flex w-full flex-col">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden ring-1 ring-black ring-opacity-5">
                          <table className="min-w-full divide-y divide-gray-300">
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {customFields.map((cf, i) => (
                                <tr key={cf.name}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {cf.name}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <SpacedContainer
                                      type="horizontal"
                                      className="justify-end"
                                    >
                                      <p
                                        className="cursor-pointer text-red-600 hover:text-red-800"
                                        onClick={() => {
                                          const index = customFields.findIndex(
                                            (customField) =>
                                              customField.name === cf.name
                                          );
                                          setValue(
                                            'custom_fields',
                                            arrayRemove(customFields, index)
                                          );
                                        }}
                                      >
                                        {tu('customization.select.remove')}
                                      </p>
                                      <p
                                        onClick={() => {
                                          setSelectedCustomField(cf);
                                          openCustomFieldDrawer();
                                          setSelectedCustomFieldIndex(i);
                                        }}
                                        className="hover:text-purple cursor-pointer text-purple-800"
                                      >
                                        {tu('customization.select.edit')}
                                      </p>
                                    </SpacedContainer>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <button
                type="button"
                onClick={openCustomFieldDrawer}
                className="inline-flex w-fit items-center rounded-full border border-transparent bg-gray-400 p-2 text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-1 focus:ring-offset-2"
              >
                <PlusSmallIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </SpacedContainer>
          </ItemFormField>
        </ItemFormSection>
      </div>

      <div className="pt-5">
        <Button type="submit" isLoading={isSubmitting}>
          {tu(itemID ? 'edit-cta' : 'add-cta')}
        </Button>
      </div>

      <Drawer
        scrollable
        className="p-4"
        open={isColorDrawerOpen}
        onOpenChange={closeColorDrawer}
      >
        <div className="space-y-4 overflow-y-scroll pb-12">
          <h1>{tu('customization.select-color')}</h1>
          <ColorPicker
            hiddenColors={colors}
            onSelect={(color) => {
              setValue('colors', [...colors, color]);
              closeColorDrawer();
            }}
          />
        </div>
      </Drawer>

      <Drawer
        scrollable
        className="p-4"
        open={isCustomFieldDrawerOpen}
        onOpenChange={closeCustomFieldDrawer}
      >
        <div className="overflow-y-scrollspace-y-4 px-1 pb-12">
          <h1>{tu('customization.select.add-heading')}</h1>
          <form
            onSubmit={handleSubmitCF(async (cfData) => {
              setValue('custom_fields', [...customFields, cfData]);
              resetCF();
              closeCustomFieldDrawer();
            })}
          >
            <SpacedContainer>
              <FormControl label={tu('customization.select.name-label')}>
                <Input {...registerCF('name')} />
              </FormControl>
              <FormControl label={tu('customization.select.values-label')}>
                <div className="flex flex-col rounded-md">
                  <div className="-mx-4 -my-2 overflow-x-auto rounded-md sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full rounded-md py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden rounded-md ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full divide-y divide-gray-300 rounded-md">
                          <tbody className="divide-y divide-gray-200 rounded-md bg-white">
                            {cfValues.map((cfValue, i) => (
                              <tr key={cfValue} className="rounded-md ">
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {cfValue}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <p
                                    onClick={() => {
                                      setValueCF(
                                        'custom_field_values',
                                        arrayRemove(cfValues, i)
                                      );
                                    }}
                                    className="cursor-pointer text-red-600 hover:text-red-800"
                                  >
                                    {tu('customization.select.remove')}
                                  </p>
                                </td>
                              </tr>
                            ))}
                            <tr className="rounded-md ">
                              <td className="whitespace-nowrap rounded-md py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                <Input
                                  value={newCfValue}
                                  onChange={(e) =>
                                    setNewCfValue(e.currentTarget.value)
                                  }
                                />
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <p
                                  aria-disabled={!newCfValue}
                                  className={cn(
                                    'hover:text-purple cursor-pointer text-purple-800',
                                    !newCfValue &&
                                      'cursor-not-allowed text-gray-400 hover:text-gray-400'
                                  )}
                                  onClick={() => {
                                    if (!newCfValue) {
                                      return;
                                    }
                                    setValueCF('custom_field_values', [
                                      ...cfValues,
                                      newCfValue,
                                    ]);
                                    setNewCfValue('');
                                  }}
                                >
                                  {tu('customization.select.add')}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </FormControl>
              <Button
                disabled={cfValues.length < 2}
                onClick={() => {
                  handleSubmitCF(async (cfData) => {
                    setValue('custom_fields', [
                      ...customFields.slice(0, selectedCustomFieldIndex),
                      { ...cfData, type: 'SELECT' },
                      ...customFields.slice(selectedCustomFieldIndex + 1),
                    ]);
                    resetCF();
                    setSelectedCustomField(null);
                    setSelectedCustomFieldIndex(-1);
                    closeCustomFieldDrawer();
                  })();
                }}
              >
                {selectedCustomFieldIndex !== -1
                  ? tu('customization.select.edit-cf')
                  : tu('customization.select.add-cf')}
              </Button>
            </SpacedContainer>
          </form>
        </div>
      </Drawer>
      <DevTool control={control} />
    </form>
  );
};
