import auth from './auth.json';
import catalog from './catalog.json';
import collections from './collections.json';
import footer from './footer.json';
import home from './home.json';
import notifications from './notifications.json';
import onboarding from './onboarding.json';
import orders from './orders.json';
import photos from './photos.json';
import services from './services.json';
import settings from './settings.json';
import shared from './shared.json';

const translations = {
  auth,
  notifications,
  catalog,
  collections,
  footer,
  settings,
  shared,
  home,
  orders,
  onboarding,
  photos,
  services,
};

export default translations;
