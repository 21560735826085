import { useEffect } from 'react';

import { AuthProvider } from 'auth/components/AuthProvider';
import GTM from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'sonner';
import { PersistGate } from 'redux-persist/integration/react';
import { RevibeApiProvider } from 'revibe-api';
import { RevibeRouter } from 'router';

import { ErrorBoundary, ScrollToTop } from 'shared/components';

import i18n from './i18n';
import { persistor, store } from './redux/store';

export const App = () => {
  useEffect(() => {
    GTM.initialize({
      gtmId: process.env.REACT_APP_GTM_PUBLIC_ID as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen">
      <ReduxProvider store={store}>
        <RevibeApiProvider>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <I18nextProvider i18n={i18n}>
                <Router>
                  <ErrorBoundary>
                    <ScrollToTop>
                      <Toaster />
                      <RevibeRouter />
                    </ScrollToTop>
                  </ErrorBoundary>
                </Router>
              </I18nextProvider>
            </AuthProvider>
          </PersistGate>
        </RevibeApiProvider>
      </ReduxProvider>
    </div>
  );
};
