import { useTranslation } from 'i18n/hooks';

import { Page } from 'ui/components/Layout/Page';

import { OnboardingAccountCreation } from '../components/OnboardingAccountCreation';

export const OnboardingPage = () => {
  const { tu } = useTranslation('onboarding');

  return (
    <Page title={tu('main-heading')}>
      <OnboardingAccountCreation />
    </Page>
  );
};
