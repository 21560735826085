import React, { createContext } from 'react';

import { Seller } from 'revibe-api';

interface Props {
  seller: Seller;
  refetchSeller: () => Promise<any>;
  children: React.ReactNode;
}
const SellerContext = createContext<{
  seller: Seller;
  sellerID: string;
  refetchSeller: () => Promise<any>;
}>({
  seller: {
    id: '-1',
    name: 'unknown',
    country: 'unknown',
    description: 'unknown',
    email: 'unknown',
    fb_url: null,
    feature_flags: {},
    ig_url: null,
    is_revibe_owned: false,
    is_test: false,
    items: [],
    logo: null,
    mkt_image: null,
    slug: 'unknown',
    stripe_account_active: false,
    stripe_account_id: 'unknown',
    stripe_customer_id: 'unknown',
    stripe_login_link: 'unknown',
    website_url: null,
    shipping_address: null,
    account_created_on: 'unknown',
  },
  sellerID: '-1',
  refetchSeller: async () => {},
});

const SellerProvider = ({ children, seller, refetchSeller }: Props) => {
  const context = {
    seller,
    sellerID: seller.id,
    refetchSeller,
  };

  return (
    <SellerContext.Provider value={context}>{children}</SellerContext.Provider>
  );
};

export { SellerProvider, SellerContext };
