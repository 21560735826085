export const ItemFormSection = ({
  label,
  sublabel,
  children,
}: {
  label: string;
  sublabel: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-12">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{label}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{sublabel}</p>
      </div>

      <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">{children}</div>
    </div>
  );
};
