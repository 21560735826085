import { useMemo } from 'react';

import { ItemPhoto } from 'api/items.types';
import Gallery from 'react-photo-gallery';

import { Photo } from 'shared/components/Photo';

type Props = {
  photos: ItemPhoto[];
};

export const ItemPhotosViewer = ({ photos }: Props) => {
  const galleryPhotos = useMemo(() => {
    return photos.map((p) => ({
      ...p,
      src: p.medium_link,
      width: 1,
      height: 1,
      id: p.id,
      canBeDeleted: false,
      onDelete: () => undefined,
    }));
  }, [photos]);

  return (
    <div className="min-h-32 mb-4 ">
      <Gallery
        photos={galleryPhotos}
        renderImage={(props: any) => <Photo {...props.photo} />}
      />
    </div>
  );
};
