import { useAuthentication } from 'auth/hooks/useAuthentication';
import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectIsAdminSession } from 'redux/features/adminSession';
import { Collapsible, ProfilePhotoPlaceholder } from 'revibe-ui';

import { useHistory } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';
import { MENU_ENTRIES } from 'shared/utils/menuEntries';
import { SubEntry as SubEntryType } from 'shared/utils/menuEntries';

import { Logo } from 'ui/icons';

const SubEntry = ({ subentry }: { subentry: SubEntryType }) => {
  const { tu } = useTranslation('shared.navbar');
  const history = useHistory();
  const location = useLocation();

  return (
    <p
      key={subentry.id}
      onClick={() => history.push(subentry.to)}
      className={cn(
        'font-small group relative m-2 my-1 flex cursor-pointer items-center rounded-md px-3 py-2 text-sm hover:bg-purple-800 hover:text-purple-100',
        subentry.getIsActive(location.pathname)
          ? 'bg-purple-800 text-purple-100'
          : 'text-purple-800'
      )}
    >
      <span className="mr-3 h-6 w-6 flex-shrink-0" aria-hidden="true" />
      <span className="flex-1 font-medium">{tu(subentry.label)}</span>
    </p>
  );
};

export const AppNavbar = () => {
  const location = useLocation();
  const { logout } = useAuthentication();
  const { tu } = useTranslation('shared.navbar');
  const { seller } = useSeller();
  const history = useHistory();
  const isAdminSession = useSelector(selectIsAdminSession);

  return (
    <div className="flex h-full flex-col justify-between overflow-x-hidden bg-gray-50">
      <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden pt-10 pb-4">
        <div className="flex flex-shrink-0 items-center px-3">
          <Logo className="box-border h-8 w-28 px-3" />
        </div>
        <nav className="mt-8 flex-1  space-y-1.5" aria-label="Sidebar">
          {MENU_ENTRIES.map((menuEntry) =>
            menuEntry.subentries.length > 0 ? (
              <Collapsible
                isOpen={
                  !!menuEntry.subentries.find((e) =>
                    e.getIsActive(location.pathname)
                  )
                }
                key={menuEntry.id}
                className={
                  'border-transparent text-purple-800 hover:text-purple-800'
                }
                label={
                  <p
                    key={menuEntry.id}
                    className={cn(
                      'font-small group m-2 my-1 flex cursor-pointer items-center rounded-md px-3 py-2 text-sm'
                    )}
                  >
                    <menuEntry.icon
                      className={cn(
                        'stroke-purple-800 group-hover:stroke-purple-800',
                        'mr-3 h-6 w-6 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    <span className="flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap font-medium">
                      {tu(menuEntry.label)}
                    </span>
                  </p>
                }
                content={menuEntry.subentries.map((subentry) => (
                  <SubEntry key={subentry.id} subentry={subentry} />
                ))}
              />
            ) : (
              <p
                key={menuEntry.id}
                onClick={() => menuEntry.to && history.push(menuEntry.to)}
                className={cn(
                  menuEntry.getIsActive(location.pathname)
                    ? 'bg-purple-800 text-purple-100  hover:bg-purple-800'
                    : 'text-purple-800 hover:bg-purple-800 hover:text-purple-100',
                  'font-small group m-2 my-1 flex cursor-pointer items-center rounded-md px-3 py-2 text-sm'
                )}
              >
                <menuEntry.icon
                  className={cn(
                    menuEntry.getIsActive(location.pathname)
                      ? 'stroke-purple-100'
                      : 'stroke-purple-800 group-hover:stroke-purple-100',
                    'mr-3 h-6 w-6 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
                <span className="flex-1 font-medium">
                  {tu(menuEntry.label)}
                </span>
              </p>
            )
          )}
        </nav>
      </div>
      <div className="flex flex-shrink-0 border-t border-purple-200 p-4">
        <div className="group block w-full flex-shrink-0">
          {isAdminSession && (
            <div className="mb-4 flex flex-col items-center border-b border-purple-200 pb-4">
              <p className="text-sm font-medium text-red-700">Admin session</p>
            </div>
          )}
          <div className="flex items-center">
            {seller.logo ? (
              <div>
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src={seller.logo || ''}
                  alt="seller"
                />
              </div>
            ) : (
              <ProfilePhotoPlaceholder size={9} />
            )}
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                {seller.name}
              </p>
              <p
                onClick={logout}
                className="group-hover:text-purple cursor-pointer text-xs font-medium text-gray-500"
              >
                {tu('logout', 'auth')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
