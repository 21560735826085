import { apiCall } from './utils/apiCall';
import { REVIBE_API_URL } from './utils/endpoints';

export const requestPasswordReset = async (email: string, language: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/auth/request-password-reset`,
    method: 'POST',
    body: { email, type: 'seller', language },
    reshaper: (data: any) => data,
  });

export const resetPassword = async (password: string, hash: string) =>
  apiCall({
    endpoint: `${REVIBE_API_URL}/auth/password-reset`,
    method: 'POST',
    body: { password, hash, type: 'seller' },
    reshaper: (data: any) => data,
  });
