export const ItemFormField = ({
  label,
  htmlFor,
  children,
}: {
  label: React.ReactNode;
  htmlFor?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-100 sm:pt-5">
      <div>
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
      </div>
      <div className="mt-1 sm:col-span-2 sm:mt-0">{children}</div>
    </div>
  );
};
