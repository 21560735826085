import { SELLER_ROUTES } from 'routes';

import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import { FiltersIcon, HomeIcon, ItemsIcon, OrdersIcon } from 'ui/icons';

export type SubEntry = {
  label: string;
  id: string;
  to: string;
  getIsActive: (path: string) => boolean;
};

export type Entry = {
  label: string;
  id: string;
  icon: ({ className, onClick }: any) => JSX.Element;
  to: string | null;
  getIsActive: (path: string) => boolean;
  subentries: SubEntry[];
};

export const MENU_ENTRIES: Entry[] = [
  {
    label: 'home',
    id: 'home',
    icon: HomeIcon,
    to: SELLER_ROUTES.HOME,
    getIsActive: (path: string) => path === SELLER_ROUTES.HOME,
    subentries: [],
  },
  {
    label: 'catalog',
    id: 'catalog',
    icon: ItemsIcon,
    to: null,
    getIsActive: () => false,
    subentries: [
      {
        label: 'all-items',
        id: 'all-items',
        getIsActive: (path: string) =>
          path.includes(SELLER_ROUTES.ITEMS) &&
          path !== SELLER_ROUTES.ITEMS_NEW,
        to: `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.APPROVED}`,
      },
      {
        label: 'add-new-item',
        id: 'add-new-item',
        getIsActive: (path: string) => path === SELLER_ROUTES.ITEMS_NEW,
        to: SELLER_ROUTES.ITEMS_NEW,
      },
      {
        label: 'collections',
        id: 'collections',
        getIsActive: (path: string) => path.includes(SELLER_ROUTES.COLLECTIONS),
        to: SELLER_ROUTES.COLLECTIONS,
      },
      {
        label: 'csv-import',
        id: 'csv-import',
        getIsActive: (path: string) => path.includes(SELLER_ROUTES.CATALOG_CSV),
        to: SELLER_ROUTES.CATALOG_CSV_IMPORT,
      },
      // {
      //   label: 'csv-export',
      //   id: 'csv-export',
      //   getIsActive: (path: string) =>
      //     path.includes(SELLER_ROUTES.CATALOG_CSV_EXPORT),
      //   to: SELLER_ROUTES.CATALOG_CSV_EXPORT,
      // },
    ],
  },
  {
    label: 'orders',
    id: 'orders',
    icon: OrdersIcon,
    to: null,
    getIsActive: () => false,
    subentries: [
      {
        label: 'active-orders',
        id: 'active-orders',
        getIsActive: (path: string) => path === SELLER_ROUTES.ORDERS_CURRENT,
        to: SELLER_ROUTES.ORDERS_CURRENT,
      },
      {
        label: 'past-orders',
        id: 'past-orders',
        getIsActive: (path: string) => path === SELLER_ROUTES.ORDERS_PAST,
        to: SELLER_ROUTES.ORDERS_PAST,
      },
    ],
  },
  {
    label: 'settings',
    id: 'settings',
    icon: FiltersIcon,
    to: SELLER_ROUTES.SETTINGS,
    getIsActive: (path: string) => path.includes(SELLER_ROUTES.SETTINGS),
    subentries: [],
  },
];
