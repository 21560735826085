import { Helmet } from 'react-helmet';

import { MarketingNavbar } from 'shared/components/MarketingNavbar';

import { Footer } from './Footer';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const Page = ({ title, children }: Props) => {
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];

  return (
    <>
      <Helmet title={`${title} | Revibe for business`} meta={meta} />
      <div className="flex min-h-screen w-screen flex-col">
        <MarketingNavbar />
        <div className="flex w-full flex-grow items-center justify-center">
          <div className="w-full">{children}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};
