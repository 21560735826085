import React from 'react';

import { Helmet } from 'react-helmet';
import { Breadcrumbs, Drawer, ProfilePhotoPlaceholder } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { Bars3Icon } from '@heroicons/react/24/outline';

import { AppNavbar } from 'shared/components/AppNavbar';
import { useBoolean, useHistory } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

import { Logo } from 'ui/icons';

import { Footer } from './Footer';

type Props = {
  title: string;
  children: React.ReactNode;
  breadcrumbs?: {
    label: string;
    to?: string;
  }[];
};

export const LoggedInPage = ({ title, children, breadcrumbs }: Props) => {
  const [isSidebarOpen, openSidebar, closeSidebar] = useBoolean();
  const history = useHistory();
  const { seller } = useSeller();
  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    },
  ];

  return (
    <>
      <Helmet title={`${title} | Revibe for business`} meta={meta} />
      <div className="min-h-full">
        <div className="sticky top-0 z-50 border-b border-gray-200 bg-white lg:hidden">
          <div className="flex h-16 flex-shrink-0 items-center justify-between bg-gray-50">
            <button
              type="button"
              className="px-4 text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={openSidebar}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <Logo
              onClick={() => history.push(SELLER_ROUTES.HOME)}
              className="box-border h-8 w-28 px-3"
            />
            <div className="flex items-center px-4">
              <div className="relative ml-3">
                <div className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-1 focus:ring-purple-500 focus:ring-offset-2">
                  {seller.logo ? (
                    <img
                      onClick={() => history.push(SELLER_ROUTES.SETTINGS)}
                      className="h-8 w-8 rounded-full border border-gray-200"
                      src={seller.logo || ''}
                      alt="seller logo"
                    />
                  ) : (
                    <ProfilePhotoPlaceholder size={8} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="hidden lg:inset-y-0 lg:block lg:w-64 lg:flex-col lg:items-start">
            <div className="w-min-64 flex h-screen w-64 flex-col lg:sticky lg:top-0 ">
              <div className="flex min-h-0 flex-1 flex-col bg-white ">
                <AppNavbar />
              </div>
            </div>
          </div>
          <div className="min-h-full w-auto flex-auto overflow-x-hidden pb-16 lg:overflow-x-hidden ">
            {breadcrumbs && (
              <div className="fixed z-20 w-full bg-white">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
            )}
            <div className="px-4 pt-20 md:px-6">{children}</div>
          </div>
        </div>
        <Footer />
      </div>
      <Drawer
        scrollable
        onOpenChange={closeSidebar}
        open={isSidebarOpen}
        position="left"
      >
        <AppNavbar />
      </Drawer>
    </>
  );
};
