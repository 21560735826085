import React, { useState } from 'react';

import { updateItem } from 'api/items.api';
import { useTranslation } from 'i18n/hooks';
import { Item, ItemEdit } from 'revibe-api';
import { SpacedContainer, Tabs, TabsList, TabsTrigger } from 'revibe-ui';
import { MARKETPLACE_ROUTES, SELLER_ROUTES } from 'router';

import { ItemDiscountModal, ItemsTable } from 'modules/catalog/components';
import { ITEM_FILTERS } from 'modules/catalog/utils/itemFilter';
import { ITEM_STATUSES } from 'modules/catalog/utils/itemStatus';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import {
  useBoolean,
  useHistory,
  useToast,
  useQueryStringParameters,
} from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

export const ItemsPage = () => {
  const { tu } = useTranslation('catalog');
  const history = useHistory();
  const { sellerID } = useSeller();
  const query = useQueryStringParameters();
  const status = query.get('status') || ITEM_STATUSES.APPROVED;
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const { errorToast, toast } = useToast();
  const [isDiscountModalOpen, openDiscountModal, closeDiscountModal] =
    useBoolean();
  const tokenSetter = useBoolean();
  const updateItemData = async (item: Item, data: ItemEdit) => {
    const { error } = await updateItem(item.id, data);
    if (error) {
      return errorToast();
    } else {
      toast('item-updated-text');
      tokenSetter[3]();
    }
  };

  return (
    <LoggedInPage
      title={tu('items')}
      breadcrumbs={[
        {
          label: tu('items'),
          to: SELLER_ROUTES.ITEMS,
        },
      ]}
    >
      <div className="flex w-full">
        <SpacedContainer className="w-full">
          <div className="pb-4">
            <ModuleHeading>{tu('items')}</ModuleHeading>
          </div>
          <div className="h-full">
            <Tabs defaultValue={status}>
              <TabsList>
                <TabsTrigger
                  onClick={() =>
                    history.push(
                      `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.PENDING_APPROVAL}`
                    )
                  }
                  value={ITEM_STATUSES.PENDING_APPROVAL}
                >
                  {tu('status.heading.pending_approval')}
                </TabsTrigger>
                <TabsTrigger
                  onClick={() =>
                    history.push(
                      `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.APPROVED}`
                    )
                  }
                  value={ITEM_STATUSES.APPROVED}
                >
                  {tu('status.heading.approved')}
                </TabsTrigger>
                <TabsTrigger
                  onClick={() =>
                    history.push(
                      `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.REJECTED}`
                    )
                  }
                  value={ITEM_STATUSES.REJECTED}
                >
                  {tu('status.heading.rejected')}
                </TabsTrigger>
                <TabsTrigger
                  onClick={() =>
                    history.push(
                      `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.ARCHIVED}`
                    )
                  }
                  value={ITEM_STATUSES.ARCHIVED}
                >
                  {tu('status.heading.archived')}
                </TabsTrigger>
              </TabsList>
            </Tabs>

            <div className="h-full pt-8">
              <ItemsTable
                mainAction={{
                  label: tu('edit', 'shared'),
                  onClick: (item) => history.push(`/items/${item.id}`),
                }}
                secondaryActions={[
                  {
                    label: tu('preview', 'shared'),
                    onClick: (item) =>
                      history.pushBlank(
                        `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}${MARKETPLACE_ROUTES.ITEM}/${item.slug}`
                      ),
                  },
                  {
                    label: tu('discounts.heading'),
                    onClick: (item) => {
                      setSelectedItem(item);
                      openDiscountModal();
                    },
                  },
                  {
                    label: tu('mark-item-out-of-stock'),
                    onClick: (item) =>
                      updateItemData(item, {
                        ...item,
                        category: item.category.id,
                        xxs: 0,
                        xs: 0,
                        s: 0,
                        m: 0,
                        l: 0,
                        xl: 0,
                        xxl: 0,
                        '3xl': 0,
                        '4xl': 0,
                        '5xl': 0,
                        '6xl': 0,
                        '7xl': 0,
                        '8xl': 0,
                        unisize: 0,
                      }),
                  },
                  {
                    label:
                      status === ITEM_STATUSES.ARCHIVED
                        ? tu('unarchive-item')
                        : tu('archive-item'),
                    onClick: (item) => {
                      updateItemData(item, {
                        ...item,
                        category: item.category.id,
                        status:
                          status === ITEM_STATUSES.ARCHIVED
                            ? ITEM_STATUSES.PENDING_APPROVAL
                            : ITEM_STATUSES.ARCHIVED,
                      });
                      history.push(
                        `${SELLER_ROUTES.ITEMS}?status=${ITEM_STATUSES.PENDING_APPROVAL}`
                      );
                    },
                  },
                ]}
                resetToken={`${tokenSetter[0]}-${status}`}
                filters={{
                  [ITEM_FILTERS.STATUS]: status,
                  [ITEM_FILTERS.INCLUDE_OUT_OF_STOCK]: true,
                  [ITEM_FILTERS.NOT_VERIFIED_ITEMS]: true,
                  [ITEM_FILTERS.SELLER]: sellerID,
                }}
              />
            </div>
          </div>
        </SpacedContainer>
        {selectedItem && (
          <ItemDiscountModal
            item={selectedItem}
            isOpen={isDiscountModalOpen}
            onClose={closeDiscountModal}
            onUpdate={() => history.go(0)}
          />
        )}
      </div>
    </LoggedInPage>
  );
};
