import { useTranslation } from 'i18n/hooks';
import { useParams } from 'react-router-dom';
import { getItem, useQuery } from 'revibe-api';
import {
  Button,
  ErrorBanner,
  InfoBanner,
  SpacedContainer,
  Spinner,
} from 'revibe-ui';
import { MARKETPLACE_ROUTES, SELLER_ROUTES } from 'routes';

import { ItemForm } from 'modules/catalog/components/ItemForm/ItemForm';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useHistory, useToast } from 'shared/hooks';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

import { getIsItemInStock } from '../utils/itemStock';

export const EditItemPage = () => {
  const { itemID } = useParams<{ itemID: string }>();
  const { tu } = useTranslation('catalog');
  const { errorToast } = useToast();
  const history = useHistory();

  const [item, { isLoading }] = useQuery(
    `item-${itemID}`,
    () => getItem(itemID),
    {
      defaultValue: null,
      onError: errorToast,
    }
  );

  if (isLoading) {
    return (
      <LoggedInPage
        title={tu('page-edit-heading')}
        breadcrumbs={[
          {
            label: tu('items'),
            to: SELLER_ROUTES.ITEMS,
          },
          {
            label: tu('page-edit-heading'),
          },
        ]}
      >
        <Spinner />
      </LoggedInPage>
    );
  }

  if (!item) {
    return null;
  }

  const isOutOfStock = !getIsItemInStock(item.stock);

  return (
    <LoggedInPage
      title={tu('page-edit-heading')}
      breadcrumbs={[
        {
          label: tu('items'),
          to: SELLER_ROUTES.ITEMS,
        },
        {
          label: item.name,
        },
      ]}
    >
      <SpacedContainer>
        <ModuleHeading>{tu('page-edit-heading')}</ModuleHeading>
        <InfoBanner>
          {tu(`status.explanation.${item.status.toLowerCase()}`)}
        </InfoBanner>
        {isOutOfStock && <ErrorBanner>{tu('out-of-stock')}</ErrorBanner>}
        <Button
          className="w-fit"
          onClick={() =>
            history.pushBlank(
              `${process.env.REACT_APP_MARKETPLACE_WEBSITE_URL}${MARKETPLACE_ROUTES.ITEM}/${item.slug}`
            )
          }
        >
          {tu('preview', 'shared')}
        </Button>

        <ItemForm item={item} />
      </SpacedContainer>
    </LoggedInPage>
  );
};
