import { useCountries, useTranslation } from 'i18n/hooks';
import { Controller, useForm } from 'react-hook-form';
import { Address, updateSellerAddress, useMutation } from 'revibe-api';
import {
  Button,
  FormControl,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SpacedContainer,
  Textarea,
} from 'revibe-ui';

import { useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';

export const AddressSettings = () => {
  const { seller } = useSeller();
  const { register, control, handleSubmit } = useForm<Address>({
    defaultValues: seller.shipping_address || {
      additional_info: '',
      address: '',
      city: '',
      country: '',
      id: '',
      postal_code: '',
    },
  });
  const { tu } = useTranslation('settings');
  const { errorToast, successToast } = useToast();
  const { countries } = useCountries();

  const [updateAddress] = useMutation(
    `seller-${seller.id}-address`,
    updateSellerAddress,
    {
      onError: errorToast,
      onSuccess: () => successToast('address-updated'),
    }
  );

  const handleProfileUpdate = async (address: Address) => {
    await updateAddress({ sellerID: seller.id, address });
  };

  return (
    <form className="space-y-8" onSubmit={handleSubmit(handleProfileUpdate)}>
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {tu('address.heading')}
        </h3>
        <p className="mt-1 text-sm text-gray-500">{tu('address.subheading')}</p>
      </div>
      <section aria-labelledby="shipping-heading" className="mt-10">
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
          <FormControl label={tu('address.email')} className="sm:col-span-2">
            <Input disabled value={seller.email} onChange={() => undefined} />
          </FormControl>
          <FormControl label={tu('address.phone')} className="sm:col-span-1">
            <Input {...register('phone')} />
          </FormControl>
          <FormControl label={tu('address.address')} className="sm:col-span-3">
            <Input {...register('address')} />
          </FormControl>
          <FormControl label={tu('address.country')}>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger className="max-w-xl">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent className="max-w-xl">
                    {countries.map((country) => (
                      <SelectItem value={country.code}>
                        {country.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </FormControl>
          <FormControl label={tu('address.city')}>
            <Input {...register('city')} />
          </FormControl>
          <FormControl label={tu('address.postal_code')}>
            <Input {...register('postal_code')} />
          </FormControl>
          <FormControl
            label={tu('address.additional_info')}
            className="sm:col-span-3"
          >
            <Textarea {...register('additional_info')} />
          </FormControl>
        </div>
      </section>

      <div className="pt-5">
        <SpacedContainer type="horizontal" className="justify-end">
          <Button type="submit">{tu('save')}</Button>
        </SpacedContainer>
      </div>
    </form>
  );
};
