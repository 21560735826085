import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { createSlice } from '@reduxjs/toolkit';

type State = { id: null | string; name: null | string };

type SetIdAction = {
  payload: State;
  type: string;
};

const initialState: State = {
  id: null,
  name: null,
};

export const adminSessionSlice = createSlice({
  name: 'adminSession',
  initialState,
  reducers: {
    setAdminSession: (state, action: SetIdAction) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    clearAdminSession: (state) => {
      state.id = null;
      state.name = null;
    },
  },
});

const persistConfig = {
  key: 'adminSession',
  storage,
};

export const { setAdminSession, clearAdminSession } = adminSessionSlice.actions;
export const adminSessionReducer = persistReducer(
  persistConfig,
  adminSessionSlice.reducer
);
export const selectAdminSessionId = (state: { adminSession: State }) =>
  state.adminSession.id;
export const selectAdminSession = (state: { adminSession: State }) =>
  state.adminSession;
export const selectIsAdminSession = (state: { adminSession: State }) =>
  state.adminSession.id !== null;
