import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';
import { Button, InfoBanner, SpacedContainer } from 'revibe-ui';
import { useMutation } from 'revibe-api';
import { useSeller, useToast } from 'shared/hooks';

export const CSVExportPage = () => {
  const { sellerID } = useSeller();
  const { errorToast } = useToast();
  const [exportCSVRecords, { isLoading }] = useMutation(
    'import-csv-file',
    async () => {
      const response = await fetch(
        `${
          process.env.REACT_APP_REVIBE_API_URL as string
        }/business/catalog/csv/export`,
        {
          method: 'POST',
          body: JSON.stringify({
            sellerID,
          }),
          headers: { 'Content-Type': 'application/json' },
          redirect: 'follow',
          credentials: 'include',
        }
      );
      const blob = await response.blob();

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'catalog.csv';
      a.click();
      //window.location.href = response.url;
      return {
        data: null,
        error: null,
      };
    },
    {
      onSuccess: () => {},
      onError: errorToast,
    }
  );

  return (
    <LoggedInPage
      title="CSV Export"
      breadcrumbs={[
        {
          label: 'CSV Export',
        },
      ]}
    >
      <SpacedContainer>
        <InfoBanner>Export your Revibe catalog as a CSV file.</InfoBanner>
        <Button onClick={exportCSVRecords} isLoading={isLoading}>
          Export
        </Button>
      </SpacedContainer>
    </LoggedInPage>
  );
};
