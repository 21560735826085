import React, { useEffect, useState } from 'react';

import { getSellerOrder } from 'api/sellers.api';
import { Page404 } from 'auth/pages/Page404';
import { useCountries, useTranslation } from 'i18n/hooks';
import { useParams } from 'react-router-dom';
import { SellerOrder } from 'revibe-api';
import { InfoBanner, SpacedContainer, Spinner } from 'revibe-ui';
import { SELLER_ROUTES } from 'routes';

import { OrderItem } from 'modules/orders/components';
import { SELLER_ORDER_STATUS } from 'modules/orders/utils';

import { ModuleHeading } from 'shared/components/ModuleHeading';
import { useBoolean } from 'shared/hooks';
import { useToast } from 'shared/hooks';
import { useSeller } from 'shared/hooks/useSeller';
import { formatPrice } from 'shared/utils';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

export const OrderPage = () => {
  const { tu } = useTranslation('orders');
  const { sellerID } = useSeller();
  const [order, setOrder] = useState<SellerOrder | null>(null);
  const [isFetching, startFetching, stopFetching] = useBoolean();
  const { errorToast } = useToast();
  const { orderID } = useParams<{ orderID: string }>();
  const { getCountryByCode } = useCountries();

  const fetchOrder = async () => {
    if (!orderID) {
      return errorToast();
    }
    startFetching();
    const { data, error } = await getSellerOrder(sellerID, orderID);
    if (data) {
      setOrder(data);
    } else {
      errorToast(error);
    }
    stopFetching();
  };
  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <LoggedInPage title="Your orders">
        <SpacedContainer>
          <Spinner />
        </SpacedContainer>
      </LoggedInPage>
    );
  }

  if (!order) {
    return <Page404 />;
  }

  return (
    <LoggedInPage
      title={order.order_id}
      breadcrumbs={[
        {
          label:
            order.status === SELLER_ORDER_STATUS.PAID_OUT
              ? tu('paid-heading')
              : tu('active-heading'),
          to:
            order.status === SELLER_ORDER_STATUS.PAID_OUT
              ? SELLER_ROUTES.ORDERS_PAST
              : SELLER_ROUTES.ORDERS_CURRENT,
        },
        {
          label: order.order_id,
        },
      ]}
    >
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-7xl">
        <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <ModuleHeading>
              {tu('heading-single')} {order.order_id}
            </ModuleHeading>
          </div>
          <p className="text-sm text-gray-600">
            {tu('item.status.IN_REVIEW')}{' '}
            <time
              dateTime={order.creation_date}
              className="font-medium text-gray-900"
            >
              {order.creation_date}
            </time>
          </p>
        </div>

        {order.status === SELLER_ORDER_STATUS.IN_REVIEW && (
          <div className="mt-8">
            <InfoBanner>
              <SpacedContainer>
                <h2>{tu('review-heading')}</h2>
                <div>
                  <p>{tu('review-text1')}</p>
                  <p>{tu('review-text2')}</p>
                </div>
                <p>
                  {tu('review-text3')}{' '}
                  <a href="mailto:hello@revibe-upcycling.com">
                    hello@revibe-upcycling.com
                  </a>{' '}
                </p>
              </SpacedContainer>
            </InfoBanner>
          </div>
        )}

        <div className="mt-16">
          <div className="rounded-md bg-gray-100 px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8 lg:py-8">
            <dl className="grid grid-cols-2 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:col-span-7">
              <div>
                <dt className="font-medium text-gray-900">
                  {tu('customer-info')}
                </dt>
                <dd className="mt-3 text-gray-500">
                  <span className="block">
                    {order.shipping_address.customer_name}
                  </span>
                  <span className="block">
                    {order.shipping_address.city},{' '}
                    {getCountryByCode(order.shipping_address.country)}
                  </span>
                </dd>
              </div>
            </dl>

            <dl className="mt-8 divide-y divide-gray-200 rounded-md text-sm lg:col-span-5 lg:mt-0">
              <div className="flex items-center justify-between pb-4">
                <dt className="text-gray-600">{tu('status')}</dt>
                <dd className="font-medium text-gray-900">
                  {tu(`sub-order-status.${order.status}`)}
                </dd>
              </div>
              <div className="flex items-center justify-between pt-4">
                <dt className="font-medium text-gray-900">{tu('total')}</dt>
                <dd className="text-purple font-medium">
                  {formatPrice(order.subtotal)} &euro;
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Products */}
        <div className="mt-6">
          <h2 className="sr-only">Products purchased</h2>

          <div className="space-y-8">
            {order.items.map((item) => (
              <OrderItem
                item={item}
                key={item.id + item.name}
                onUpdate={fetchOrder}
              />
            ))}
          </div>
        </div>
      </div>
    </LoggedInPage>
  );
};
