import { useTranslation } from 'i18n/hooks';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useLocation } from 'react-router-dom';
import { Button, Checkbox, FormControl, Input, Label } from 'revibe-ui';
import { SELLER_ROUTES } from 'router';

import { Page } from 'ui/components/Layout/Page';

import { PasswordReset } from '../components/PasswordReset';
import { useAuthentication } from '../hooks/useAuthentication';

export const LoginPage = () => {
  const { tu } = useTranslation('auth');
  const location = useLocation();
  const { login, isLoggedIn } = useAuthentication();
  const { from }: any = location.state || {
    from: { pathname: '/' },
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{
    email: string;
    password: string;
    isPermanentLogin: boolean;
  }>({
    defaultValues: { isPermanentLogin: true },
  });
  const handleLogin = handleSubmit(
    async ({ email, password, isPermanentLogin }) => {
      await login(email, password, isPermanentLogin, from);
    }
  );

  if (isLoggedIn) {
    return <Redirect to={SELLER_ROUTES.HOME} />;
  }

  return (
    <Page title="Login">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-12 w-auto"
              src="https://res.cloudinary.com/revibe/image/upload/v1649685438/business%20app/logo_zsvqi5.svg"
              alt="logo revibe"
            />
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleLogin} className="space-y-6">
                <FormControl label={tu('email')}>
                  <Input
                    {...register('email', { required: true })}
                    placeholder="email@example.com"
                  />
                </FormControl>

                <FormControl label={tu('password')}>
                  <Input
                    {...register('password', { required: true })}
                    type="password"
                  />
                </FormControl>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <Controller
                      name="isPermanentLogin"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          id="isPermanentLogin"
                          value={undefined}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      )}
                    />
                    <Label htmlFor="isPermanentLogin">
                      {tu('keep-me-logged-in')}
                    </Label>
                  </div>

                  <PasswordReset />
                </div>

                <Button isLoading={isSubmitting} type="submit">
                  {tu('login')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden h-full w-full flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://res.cloudinary.com/revibe/image/upload/v1645700016/home/115809346_715661895671679_2306125644346935595_n_2_ysz7la.jpg"
          alt="login hero"
        />
      </div>
    </Page>
  );
};
