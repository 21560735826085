import React, { useEffect, useState } from 'react';

import { updateItem } from 'api/items.api';
import { useTranslation } from 'i18n/hooks';
import { Item } from 'revibe-api';
import { Button, FormControl, Input, Drawer, SpacedContainer } from 'revibe-ui';

import {
  getPercentageCutFromPrice,
  getPriceFromPercentageCut,
} from 'modules/catalog/utils/itemPrice';

import { useToast } from 'shared/hooks';

type Props = {
  item: Item;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
};

export const ItemDiscountModal = ({
  item,
  isOpen,
  onClose,
  onUpdate,
}: Props) => {
  const { tu } = useTranslation('catalog.discounts');
  const { errorToast, toast } = useToast();
  const [sellingPrice, setSellingPrice] = useState(
    item.selling_price || item.price
  );
  const [percentageCut, setPercentageCut] = useState(
    getPercentageCutFromPrice(item.price, sellingPrice)
  );
  const handleClose = () => {
    setSellingPrice(item.selling_price || item.price);
    setPercentageCut(
      item.selling_price
        ? getPercentageCutFromPrice(item.price, item.selling_price)
        : 0
    );
    onClose();
  };
  const changeItemSellingPrice = async (item: Item, price: number) => {
    if (price < 0.5 || price > item.price) {
      return errorToast('discount out of boundaries');
    }
    const { error } = await updateItem(item.id, {
      ...item,
      category: item.category.id,
      selling_price: price,
    });
    if (error) {
      return errorToast();
    } else {
      toast('item-updated-text');
      onUpdate();
      handleClose();
    }
  };
  useEffect(() => {
    setSellingPrice(item.selling_price || item.price);
    setPercentageCut(
      item.selling_price
        ? getPercentageCutFromPrice(item.price, item.selling_price)
        : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Drawer scrollable open={isOpen} className="p-4" onOpenChange={handleClose}>
      <SpacedContainer>
        <p className="text-lg font-semibold">{tu('heading')}</p>
        <SpacedContainer type="horizontal">
          <FormControl label={tu('discounted-percentage')}>
            <Input
              type="number"
              step={0.1}
              min={0}
              onChange={(e) => {
                setSellingPrice(
                  getPriceFromPercentageCut(
                    item.price,
                    Number(e.currentTarget.value) || 0
                  )
                );
                setPercentageCut(Number(e.currentTarget.value) || 0);
              }}
              value={percentageCut}
              placeholder={tu('discounted-price')}
            />
          </FormControl>
          <p>=</p>
          <FormControl label={tu('discounted-price')}>
            <Input
              type="number"
              step={0.1}
              min={0}
              onChange={(e) => {
                setSellingPrice(Number(e.currentTarget.value) || 0);
                setPercentageCut(
                  getPercentageCutFromPrice(
                    item.price,
                    Number(e.currentTarget.value) || 0
                  )
                );
              }}
              value={sellingPrice}
              placeholder={tu('discounted-price')}
            />
          </FormControl>
        </SpacedContainer>
        <p>{tu('common-discounts')}</p>
        <SpacedContainer type="horizontal">
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 5));
              setPercentageCut(5);
            }}
          >
            5%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 10));
              setPercentageCut(10);
            }}
          >
            10%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 15));
              setPercentageCut(15);
            }}
          >
            15%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 20));
              setPercentageCut(20);
            }}
          >
            20%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 25));
              setPercentageCut(25);
            }}
          >
            25%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 30));
              setPercentageCut(30);
            }}
          >
            30%
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              setSellingPrice(getPriceFromPercentageCut(item.price, 35));
              setPercentageCut(35);
            }}
          >
            35%
          </Button>
        </SpacedContainer>
        <Button
          variant="error"
          onClick={() => {
            setSellingPrice(getPriceFromPercentageCut(item.price, 0));
            setPercentageCut(0);
          }}
        >
          {tu('remove')}
        </Button>
        <Button
          onClick={() => item && changeItemSellingPrice(item, sellingPrice)}
          className="w-full"
        >
          {tu('save', 'shared')}
        </Button>
      </SpacedContainer>
    </Drawer>
  );
};
