import { Controller } from 'react-hook-form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
  Spinner,
} from 'revibe-ui';

import { useCatalogCategories } from '../hooks/useCatalogCategories';
import { useTranslation } from 'i18n/hooks';

interface Props {
  name: string;
  className?: string;
  control: any;
}

export const CategorySelect = ({ control, className, name }: Props) => {
  const {
    selectCategories: clothingCategories,
    isLoading: isLoadingClothingCategories,
  } = useCatalogCategories('clothing');
  const {
    selectCategories: accessoriesCategories,
    isLoading: isLoadingAccessoriesCategories,
  } = useCatalogCategories('accessories-more');
  const { tu } = useTranslation('catalog.categories');

  if (isLoadingClothingCategories || isLoadingAccessoriesCategories) {
    return <Spinner />;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Select value={String(field.value)} onValueChange={field.onChange}>
            <SelectTrigger className={className}>
              <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent className={className}>
              <SelectGroup>
                <SelectLabel>{tu('clothing')}</SelectLabel>
                {clothingCategories.map((category) => (
                  <SelectItem
                    key={category.value}
                    value={String(category.value)}
                  >
                    {category.label}
                  </SelectItem>
                ))}
              </SelectGroup>
              <SelectSeparator />
              <SelectGroup>
                <SelectLabel>{tu('accessories-more')}</SelectLabel>
                {accessoriesCategories.map((category) => (
                  <SelectItem
                    key={category.value}
                    value={String(category.value)}
                  >
                    {category.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        );
      }}
    />
  );
};
