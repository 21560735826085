import { Fragment } from 'react';

import { useAuthentication } from 'auth/hooks/useAuthentication';
import { cn } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';
import { useLocation } from 'react-router-dom';
import { Button } from 'revibe-ui';
import { AUTH_ROUTES, COMPANY_ROUTES, SELLER_ROUTES } from 'routes';

import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { DEMO_REQUEST_FORM } from 'modules/onboarding/utils/demo';

import { useHistory } from 'shared/hooks';

import { Logo } from 'ui/icons';

export const MarketingNavbar = () => {
  const { tu } = useTranslation('shared');
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn, logout } = useAuthentication();
  const navigation = [
    {
      name: tu('navbar.contacts'),
      href: DEMO_REQUEST_FORM,
      current: location.pathname === COMPANY_ROUTES.CONTACTS,
    },
  ];

  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-40 overflow-visible border-gray-200 bg-purple-100 backdrop-blur-md"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <Logo
                    className="block h-8 w-auto cursor-pointer"
                    onClick={() => history.push(COMPANY_ROUTES.SERVICES)}
                  />
                </div>
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={cn(
                      item.current
                        ? 'text-purple bg-purple-100 shadow'
                        : 'text-purple hover:bg-purple-100',
                      'rounded-md px-3 py-2 text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="flex items-center">
                <div className="mr-2 -ml-2 flex items-center lg:hidden">
                  <Disclosure.Button className="text-purple inline-flex items-center justify-center rounded-md p-2 hover:bg-purple-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-100">
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                {!isLoggedIn && (
                  <a
                    href={AUTH_ROUTES.LOGIN}
                    className={
                      'text-purple hidden rounded-md px-3 py-2 text-sm font-medium hover:bg-purple-100 lg:inline'
                    }
                  >
                    {tu('login', 'auth')}
                  </a>
                )}
                {isLoggedIn && (
                  <>
                    <a
                      href={SELLER_ROUTES.HOME}
                      className={
                        'text-purple hidden rounded-md px-3 py-2 text-sm font-medium hover:bg-purple-100 lg:inline'
                      }
                    >
                      {tu('access', 'auth')}
                    </a>
                    <a
                      onClick={logout}
                      href={AUTH_ROUTES.LOGIN}
                      className={
                        'text-purple hidden rounded-md px-3 py-2 text-sm font-medium hover:bg-purple-100 lg:inline'
                      }
                    >
                      {tu('logout', 'auth')}
                    </a>
                  </>
                )}
                <div className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  <Button onClick={() => history.pushBlank(DEMO_REQUEST_FORM)}>
                    {tu('demo')}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={cn(
                    item.current
                      ? 'text-purple bg-purple-100'
                      : 'text-purple hover:bg-purple-100',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <a
                href={AUTH_ROUTES.LOGIN}
                className={
                  'text-purple block rounded-md px-3 py-2 text-base font-medium hover:bg-purple-100'
                }
              >
                {tu('login', 'auth')}
              </a>
              <Button
                className="m-2"
                onClick={() => history.pushBlank(DEMO_REQUEST_FORM)}
              >
                {tu('demo')}
              </Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
