import { useTranslation } from 'i18n/hooks';
import { Button } from 'revibe-ui';

import { DEMO_REQUEST_FORM } from 'modules/onboarding/utils/demo';

import { useHistory } from 'shared/hooks';
import { SERVICES_ENTRIES } from 'shared/utils/servicesEntries';

import { Page } from 'ui/components/Layout/Page';

export const ServicesPage = () => {
  const { tu } = useTranslation('services');
  const history = useHistory();

  return (
    <Page title={tu('heading')}>
      <div className="overflow-hidden bg-purple-50">
        <div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">{tu('home.hero-heading')}</span>{' '}
            </h1>
            <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
              {tu('home.hero-subheading')}
            </p>
            <div className="mx-auto mt-5 max-w-md gap-x-4 sm:flex sm:justify-center md:mt-8">
              <Button onClick={() => history.pushBlank(DEMO_REQUEST_FORM)}>
                {tu('home.hero-cta1')}
              </Button>
            </div>
          </div>
        </div>
        <div className="relative mx-auto max-w-xl px-4 py-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:py-24">
          <svg
            className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-purple-100"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="relative">
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {tu('home.section1-heading')}
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                {tu('home.section1-subheading')}
              </p>

              <dl className="mt-10 space-y-10">
                {SERVICES_ENTRIES.map((item) => (
                  <div key={item.id} className="relative rounded-md">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-purple-100 text-white">
                        <item.icon
                          className="stroke-purple h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {tu(`list.${item.label}.heading`)}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {tu(`list.${item.label}.description`)}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-purple-100"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                />
              </svg>
              <img
                className="relative mx-auto w-[296px] rounded-lg shadow-lg md:w-[490px]"
                src="https://res.cloudinary.com/revibe/image/upload/v1653061201/Seller%20App/Screenshot_2022-05-20_at_17.38.03_i88jwc.jpg"
                alt=""
              />
            </div>
          </div>

          <svg
            className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </Page>
  );
};
