import { Suspense } from 'react';

import { LoggedInRoute } from 'auth/components/LoggedInRoute';
import { LoggedOutRoute } from 'auth/components/LoggedOutRoute';
import { AdminSessionLoginPage } from 'auth/pages/AdminSessionLoginPage';
import { LoginPage } from 'auth/pages/LoginPage';
import { Page404 } from 'auth/pages/Page404';
import { PasswordResetPage } from 'auth/pages/PasswordResetPage';
import { UnauthorizedPage } from 'auth/pages/UnauthorizedPage';
import { ServicesPage } from 'company/pages/ServicesPage';
import { Route, Switch } from 'react-router-dom';
import { Spinner } from 'revibe-ui';

import { CollectionPage } from 'modules/catalog/collections/pages/CollectionPage';
import { CollectionsPage } from 'modules/catalog/collections/pages/CollectionsPage';
import { NewCollectionPage } from 'modules/catalog/collections/pages/NewCollectionPage';
import { EditItemPage } from 'modules/catalog/pages/EditItemPage';
import { ItemsPage } from 'modules/catalog/pages/ItemsPage';
import { NewItemPage } from 'modules/catalog/pages/NewItemPage';
import { HomePage } from 'modules/home/pages/HomePage';
import { OnboardingPage } from 'modules/onboarding/pages/OnboardingPage';
import { OnboardingSuccessPage } from 'modules/onboarding/pages/OnboardingSuccessPage';
import { StripeConnectedAccountSetupRefreshPage } from 'modules/onboarding/pages/StripeConnectedAccountSetupRefreshPage';
import { CurrentOrdersPage } from 'modules/orders/pages/CurrentOrdersPage';
import { OrderPage } from 'modules/orders/pages/OrderPage';
import { PastOrdersPage } from 'modules/orders/pages/PastOrdersPage';
import { SellingSettingsPage } from 'modules/settings/pages/SellingSettingsPage';
import { SettingsPage } from 'modules/settings/pages/SettingsPage';

import { LoggedInPage } from 'ui/components/Layout/LoggedInPage';

import {
  AUTH_ROUTES,
  COMPANY_ROUTES,
  MARKETPLACE_ROUTES,
  SELLER_ROUTES,
} from './routes';
import { CSVImportPage } from 'modules/catalog/csv/pages/CSVImportPage';
import { CSVMappingPage } from 'modules/catalog/csv/pages/CSVMappingPage';
import { CSVProcessPage } from 'modules/catalog/csv/pages/CSVProcessPage';
import { CSVSuccessPage } from 'modules/catalog/csv/pages/CSVSuccessPage';
import { CSVExportPage } from 'modules/catalog/csv/pages/CSVExportPage';

export { MARKETPLACE_ROUTES, AUTH_ROUTES, SELLER_ROUTES };

export const RevibeRouter = () => {
  return (
    <Suspense
      fallback={
        <LoggedInPage title="Loading">
          <Spinner />
        </LoggedInPage>
      }
    >
      <Switch>
        {/* AUTH ROUTES */}
        <Route
          path={AUTH_ROUTES.ADMIN_SESSION}
          exact
          component={AdminSessionLoginPage}
        />
        <Route
          path={AUTH_ROUTES.RESET_PASSWORD}
          exact
          component={PasswordResetPage}
        />
        <Route path={COMPANY_ROUTES.SERVICES} exact component={ServicesPage} />
        <LoggedOutRoute path={AUTH_ROUTES.LOGIN} exact Component={LoginPage} />
        <LoggedOutRoute
          path={SELLER_ROUTES.ONBOARDING}
          exact
          shouldNotRedirect
          Component={OnboardingPage}
        />
        <LoggedOutRoute
          path={SELLER_ROUTES.ONBOARDING_SUCCESS}
          exact
          shouldNotRedirect
          Component={OnboardingSuccessPage}
        />
        <LoggedOutRoute
          path={SELLER_ROUTES.STRIPE_CONNECTED_ACCOUNT_SETUP_REFRESH}
          exact
          shouldNotRedirect
          Component={StripeConnectedAccountSetupRefreshPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.ITEMS_NEW}
          exact
          Component={NewItemPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.ORDERS_CURRENT}
          exact
          Component={CurrentOrdersPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.ORDERS_PAST}
          exact
          Component={PastOrdersPage}
        />
        <LoggedInRoute path={SELLER_ROUTES.ORDER} exact Component={OrderPage} />
        <LoggedInRoute
          path={SELLER_ROUTES.ITEM}
          exact
          Component={EditItemPage}
        />
        <LoggedInRoute path={SELLER_ROUTES.ITEMS} exact Component={ItemsPage} />
        <LoggedInRoute
          path={SELLER_ROUTES.CATALOG_CSV_IMPORT}
          exact
          Component={CSVImportPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.CATALOG_CSV_MAPPING}
          exact
          Component={CSVMappingPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.CATALOG_CSV_PROCESS}
          exact
          Component={CSVProcessPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.CATALOG_CSV_SUCCESS}
          exact
          Component={CSVSuccessPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.CATALOG_CSV_EXPORT}
          exact
          Component={CSVExportPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.COLLECTIONS}
          exact
          Component={CollectionsPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.COLLECTION_NEW}
          exact
          Component={NewCollectionPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.COLLECTION}
          exact
          Component={CollectionPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.SETTINGS}
          exact
          Component={SettingsPage}
        />
        <LoggedInRoute
          path={SELLER_ROUTES.SETTINGS_SELL}
          exact
          Component={SellingSettingsPage}
        />
        <LoggedInRoute path={SELLER_ROUTES.HOME} exact Component={HomePage} />
        {/* GENERIC ROUTES */}
        <Route
          path={AUTH_ROUTES.UNAUTHORIZED}
          exact
          component={UnauthorizedPage}
        />
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};
