import { Item, ItemFilters, ItemPhoto } from '../types/items.types';
import { PaginationResponse } from '../types/pagination.types';
import { apiCall } from '../utils/apiCall';
import { convertObjectToQueryParams } from '../utils/routes';

export const getItems = async (filters: ItemFilters, page = 1, limit = 0) => {
  let url = `items?page=${page}&limit=${limit}${convertObjectToQueryParams(
    filters
  )}`;

  return await apiCall({
    endpoint: url,
    method: 'GET',
    reshaper: (res: PaginationResponse<Item>) => res,
  });
};

export const getItem = async (itemID: string) =>
  await apiCall({
    endpoint: `items/${itemID}`,
    method: 'GET',
    reshaper: (res: Item) => res,
  });

export const getItemPhotos = async (itemID: string) =>
  await apiCall({
    endpoint: `items/${itemID}/photos`,
    method: 'GET',
    reshaper: (res: ItemPhoto[]) => res,
  });
