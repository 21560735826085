import { useState } from 'react';

import { Document, Page as PDFPage } from 'react-pdf/dist/esm/entry.webpack5';

import {
  ArrowDownTrayIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

export const OnboardingTermsAndCondition = () => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div className="relative w-full flex-col items-center justify-center">
      <Document
        className="mx-auto box-border max-w-[512px] overflow-scroll rounded-md border border-gray-200 lg:w-[512px]"
        file={process.env.PUBLIC_URL + '/terms.pdf'}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <PDFPage width={512} pageNumber={pageNumber} />
      </Document>
      <span className="absolute bottom-4 left-[50%] z-0 inline-flex  -translate-x-[50%] rounded-md">
        <button
          onClick={() => {
            pageNumber > 1 && setPageNumber(pageNumber - 1);
          }}
          type="button"
          className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          onClick={() => {
            numPages && pageNumber < numPages && setPageNumber(pageNumber + 1);
          }}
          type="button"
          className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <a href={process.env.PUBLIC_URL + '/terms.pdf'} download>
          <button
            type="button"
            className="relative ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            <span className="sr-only">Next</span>
            <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </a>
      </span>
    </div>
  );
};
